import { TextField } from "@mui/material";
import { forwardRef } from "react";

const MuiInput = forwardRef((props, ref) => {
  const {
    className
  } = props;

  return (
    <TextField
      {...props}
      ref={ref}
      sx={{
        '& .MuiOutlinedInput-root': {
          fontFamily: 'Roboto Flex',
          border: '1px solid #D9E0E8',
          borderRadius: 2,
          fontWeight: 500,
          fontSize: '0.875rem', /* 14px */
          lineHeight: '1.25rem', /* 20px */
          color: "#0A213A",
          letterSpacing: '0.025em',
          boxShadow: '0 1px 2px 0 rgb(0 0 0 / 0.05)',
          background: "#fff",
        },
        '& .MuiOutlinedInput-root.Mui-focused': {
          borderColor: '#d9e0e866',
        },
        '& .MuiInputLabel-root': {
          fontFamily: 'Roboto Flex',
          color: '#69798C',
          fontSize: '0.875rem', /* 14px */
          lineHeight: '1.25rem', /* 20px */
          transform: 'translate(1rem, 1rem)',
          fontWeight: 500,
        },
        '& .MuiInputLabel-shrink': {
          color: '#69798C',
          transform: 'translate(1rem, .25rem)',
          backgroundColor: "transparent",
          fontSize: '0.75rem', /* 12px */
          lineHeight: '1rem', /* 16px */
          pointerEvents: 'none',
        },
        '& .MuiOutlinedInput-input': {
          padding: '1rem'
        },
        '& .MuiOutlinedInput-notchedOutline': {
          display: "none",
        },
        '& .MuiInputLabel-root.Mui-focused': {
          color: '#69798C',
        }
      }}
      className={className}
    />
  )
})

export default MuiInput;