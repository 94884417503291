import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { PulseLoader } from "react-spinners";
// Components
import Typography from "../../../../../base-components/Typography";
import RadioButton from "../../../../../base-components/RadioButton";
// Services
import DynamicFieldService from "../../../../../services/DynamicFieldService";

const Covid = ({
  gettingPrice,
  stepConfig,
  selectedPackageId,
  tableNameId,
}) => {
  const {
    formState: { errors },
    setValue,
    watch,
    getValues,
  } = useFormContext();

  const [covidOptions, setCovidOptions] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [loader, setLoader] = useState(false);

  const clearData = () => {
    setSelectedOption(null);
    setValue("covid_options", null);
    setValue("covid_name", null);
  };
  /**
   * Radion button click event
   * @param key сонгогдсон covid option index
   */
  const handleOptionChange = (key) => {
    if (covidOptions[key] && selectedOption !== key) {
      setSelectedOption(key);
      setValue("covid_options", key);
      setValue("covid_name", covidOptions[key]);
    } else {
      clearData();
    }
    gettingPrice();
  };

  useEffect(() => {
    setSelectedOption(getValues("covid_options"));
  });

  useEffect(() => {
    // хуудас уншихаас өмнө covid тохиргоо байвал covid тохиргооны датаг авах
    if (stepConfig?.x_covid_options && selectedPackageId) {
      setLoader(true);
      DynamicFieldService.getSinoAsiaCovidOption(selectedPackageId, tableNameId)
        .then((response) => {
          if ((response.status = "Success" && response?.value)) {
            // CovidOptions set
            setCovidOptions(response.value);
          }
        })
        .catch((error) => {
          console.log(error, "packages error");
        })
        .finally(() => setLoader(false));
    }
    // сонгогодсон багц байхгүй бол covid option цэвэрллэнэ
    if (selectedPackageId === null) {
      setCovidOptions(null);
      clearData();
    }
  }, [selectedPackageId]);

  return (
    <div>
      {loader ? (
        <PulseLoader
          color="#BBC1C9"
          speedMultiplier={0.7}
          loading={loader}
          size={12}
        />
      ) : (
        covidOptions &&
        covidOptions &&
        watch("package") &&
        watch("package")?.id && (
          <div>
            <Typography variant="Title" size="md" className="mt-10">
              Нэмэлт хамгаалалт Ковид 19 багц
            </Typography>
            <Typography size="md" className="mt-1">
              Дараах үйлчилгээнүүдээс өөрт тохирохоо сонгоорой
            </Typography>
            <div className="mt-4 grid w-full grid-cols-2 gap-4">
              {Object.keys(covidOptions).map((key) => (
                <div
                  key={key}
                  className="flex max-w-[382px] cursor-pointer items-center justify-between space-x-4 rounded-xl border p-3.5 max-[500px]:col-span-2"
                  onClick={() => handleOptionChange(Number(key))}
                >
                  <RadioButton
                    label=""
                    id={`extra_service-${key}`}
                    checked={selectedOption === Number(key)}
                    onChange={(e) => handleOptionChange(Number(key))}
                  />
                  <Typography size="md">{covidOptions[key]}</Typography>
                </div>
              ))}
            </div>
          </div>
        )
      )}
    </div>
  );
};

export default Covid;
