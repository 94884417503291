import { useEffect, useRef } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import { useStore } from "../../stores";
import Notification from "../../base-components/Notification";

const Container = () => {
  const notifyRef = useRef(null);
  const token = useStore.useToken();
  const user = useStore.useUser();
  const loading = useStore.useLoading();
  const notification = useStore.useNotification();
  const prevPath = useStore.usePrevPath();
  const setPrevPath = useStore.useSetPrevPath();
  const location = useLocation();
  const navigate = useNavigate();
  const { product_id } = useParams();

  useEffect(() => {
    if (user.user_type && token.length) {
      if (user.user_type === 1) navigate("broker");
      else if (user.user_type === 3) navigate("dashboard");
    }
  }, []);

  useEffect(() => {
    if (!user.user_type) {
      if (token.length === 0)
        if (product_id) {
          navigate(`login/${product_id}`);
        } else if (
          !location.pathname.match("/sign-in") &&
          !location.pathname.match("/broker") &&
          !location.pathname.match("/recover-password")
        ) {
          navigate("login");
        }
      return;
    }
    // if (user.user_type === 3) {
    //   // RETAIL
    //   if (location.pathname.match("/login")) {
    //     if (token.length === 0) navigate(`login/${product_id}`);
    //     // else navigate(prevPath);
    //     return;
    //   }
    //   if (location.pathname.match("/broker")) navigate(prevPath);
    //   else navigate(location.pathname);
    // } else {
    //   // BROKER, TULUULUGCH
    //   if (location.pathname.match("/login")) {
    //     if (token.length === 0) navigate("login");
    //     else navigate(prevPath);
    //     return;
    //   }
    //   if (location.pathname.match("/broker")) navigate(location.pathname);
    //   else navigate(prevPath);
    // }
  }, [
    location.pathname,
    navigate,
    prevPath,
    product_id,
    token.length,
    user.user_type,
  ]);

  useEffect(() => {
    if (location.pathname.match("insurance-process")) return;
    setPrevPath(location.pathname);
  }, [location.pathname, setPrevPath]);

  useEffect(() => {
    if (notification) notifyRef.current.show();
  }, [notification]);

  return (
    <div className="h-full text-primary">
      <div
        className={twMerge([
          "fixed bottom-0 left-0 right-0 top-0 z-50 flex items-center justify-center bg-white opacity-50",
          !loading && "hidden ",
        ])}
      >
        <div className="to-stroke-black-12 h-8 w-8 animate-[spin_.8s_linear_0s_infinite] rounded-full border bg-gradient-to-r from-primary p-[1px]">
          <div className="h-full w-full rounded-full bg-white"></div>
        </div>
      </div>
      <Notification
        ref={notifyRef}
        message={notification?.message}
        type={notification?.type}
        title="Анхааруулга"
      />
      <Outlet />
    </div>
  );
};

export default Container;
