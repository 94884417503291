import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import Typography from "../../../../base-components/Typography";
import Input from "../../../../base-components/Input";
import RadioButton from "../../../../base-components/RadioButton";
import Button from "../../../../base-components/Button";
import Modal from "../../../../base-components/Modal";
import AlertModal from "../../../../base-components/AlertModal";
import InvoiceService from "../../../../services/InvoiceService";
import EbarimtService from "../../../../services/EbarimtService";
import { formatNumber, formatMobile } from "../../../../utils/helper";
import Payment from "../payment";
import { useStore } from "../../../../stores";

function StepTwo(props) {
  const { currentStep, setCurrentStep, invoiceInfo } = props;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
      mobile: "",
    },
  });

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [content, setContent] = useState(null);
  const [modalTitle, setModalTitle] = useState("");
  const [isShowAlert, setIsShowAlert] = useState(false);
  const [isIndividual, setIsIndividual] = useState("person");
  const [qpayMobile, setQpayMobile] = useState("");
  const [registrationNumber, setRegistrationNumber] = useState("");
  const [ebarimtCompanyName, setEbarimtCompanyName] = useState("");
  const [ebarimtCompanyError, setEbarimtCompanyError] = useState(false);
  const [qpayMobileError, setQpayMobileError] = useState("");
  const [registrationNumberError, setRegistrationNumberError] = useState("");
  const [personRegistrationNumber, setPersonRegistrationNumber] = useState("");
  const [personRegistrationNumberError, setPersonRegistrationNumberError] =
    useState("");
  const [inputMaxLength, setInputMaxLength] = useState(7);

  // И баримт төлөгчийн төлийг солиход input clear нийнэ
  useEffect(() => {
    setEbarimtCompanyName("");
    setEbarimtCompanyError(false);
    if (isIndividual !== "person") setQpayMobile("");
    if (isIndividual !== "company") setRegistrationNumber("");
    if (isIndividual !== "companyPerson") setPersonRegistrationNumber("");
  }, [registrationNumber, isIndividual, personRegistrationNumber, qpayMobile]);

  const getValue = () => {
    if (invoiceInfo?.object?.trailer) return "Чиргүүлтэй";
    else return "Чиргүүлгүй";
  };

  const isNumber = (value) => {
    return typeof value === "number";
  };

  // Ebarimt Бизнес эрхлэгчийн нэр авах service function
  const getCompanyName = async (companyRegister) => {
    if (!companyRegister || isIndividual === "person") return;

    try {
      const response = await EbarimtService.getTinInfo(companyRegister);
      if (response.status === 200) {
        setEbarimtCompanyName(response?.data?.name || "");
      } else {
        setEbarimtCompanyError(true);
        useStore.setState({
          notification: {
            message:
              response?.status === 500
                ? "Бизнес эрхлэгчийн регистрийн дугаар буруу байна"
                : "Алдаа гарлаа. Дахин оролдоно уу.",
            type: "warning",
          },
        });
      }
    } catch (error) {
      useStore.setState({
        notification: {
          message: "Бизнес эрхлэгчийн мэдээлэл татах явцад алдаа гарлаа.",
          type: "error",
        },
      });
    }
  };
  const onSubmit = (data) => {
    let hasError = false;
    if (isIndividual === "person") {
      if (qpayMobile.length === 0) {
        setQpayMobileError("Гар утасны дугаар заавал оруулах шаардлагатай.");
        hasError = true;
      } else if (qpayMobile.length < 8) {
        setQpayMobileError("Гар утасны дугаар 8 оронтой байх ёстой.");
        hasError = true;
      } else {
        setQpayMobileError("");
      }
    } else if (isIndividual === "company") {
      if (registrationNumberError) {
        hasError = true;
      } else if (ebarimtCompanyError) {
        setRegistrationNumberError(
          "Байгууллагын регистрийн дугаар буруу байна.",
        );
        hasError = true;
      } else if (registrationNumber.length === 0) {
        setRegistrationNumberError(
          "Байгууллагын регистрийн дугаар заавал оруулах шаардлагатай.",
        );
        hasError = true;
      } else if (registrationNumber.length < 7) {
        setRegistrationNumberError(
          "Байгууллагын регистрийн дугаар 7 оронтой байх шаардлагатай",
        );
        hasError = true;
      } else {
        setRegistrationNumberError("");
      }
    } else if (isIndividual === "companyPerson") {
      if (personRegistrationNumberError) {
        hasError = true;
      } else if (ebarimtCompanyError) {
        setPersonRegistrationNumberError(
          "Бизнес эрхлэгчийн регистрийн дугаар буруу байна.",
        );
        hasError = true;
      } else if (personRegistrationNumber.length === 0) {
        setPersonRegistrationNumberError(
          "Бизнес эрхлэгчийн регистрийн дугаар заавал оруулах шаардлагатай.",
        );
        hasError = true;
      } else if (personRegistrationNumber.length < 10) {
        setPersonRegistrationNumberError(
          "Бизнес эрхлэгчийн регистрийн дугаар 10 оронтой байх шаардлагатай",
        );
        hasError = true;
      } else {
        setPersonRegistrationNumberError("");
      }
    }
    if (!hasError) {
      setContent(data.content);
      setModalTitle(data.title);

      if (qpayMobile || registrationNumber || personRegistrationNumber) {
        const payload = {
          policy_id: invoiceInfo?.policy_id,
          mobile: qpayMobile,
          registrationNumber:
            isIndividual === "company"
              ? registrationNumber
              : personRegistrationNumber,
        };
        // хувь хүн бол зөвхөн дугаар авна Бизнес эрхлэгчийн бол регистер авна
        if (isIndividual === "person") payload.registrationNumber = null;
        else payload.mobile = null;

        InvoiceService.insertOwnerInfo(payload)
          .then((response) => {
            setIsOpenModal(true);
          })
          .catch((error) => {
            useStore.setState({
              notification: {
                message: "И баримтын мэдээлэл илгээхэд алдаа гарлаа.",
                type: "error",
              },
            });
          });
        // setCurrentStep(currentStep + 1)
      }
    }
  };

  return (
    <div className="w-[700px] p-7">
      <Typography variant="Title" size="md">
        Даатгуулагчийн мэдээлэл
      </Typography>
      <div className="mt-4 grid grid-cols-2 gap-4 max-[500px]:grid-cols-1">
        <Input
          id="insured-name"
          type="text"
          label="Даатгуулагч"
          defaultValue={invoiceInfo?.insured?.name}
        />
        <Input
          id="insured-registry"
          label="Регистерийн дугаар"
          defaultValue={invoiceInfo?.insured?.registry_number}
        />
        <Input id="insured-last-name" label="Овог" defaultValue="Даатгал" />
      </div>
      <Typography variant="Title" size="md" className="mt-6">
        Нэхэмжлэлийн мэдээлэл
      </Typography>
      <div className="mt-4 rounded-xl border border-stroke-dark-12 text-primary">
        <div className="grid grid-cols-3 rounded-t-xl border-b border-stroke-dark-12 bg-surface-gray text-base max-[500px]:grid-cols-1">
          <div className="border-stroke-dark-12 py-4 text-center max-[500px]:border-b min-[501px]:border-r">
            <span>
              {invoiceInfo?.object?.make}, {invoiceInfo?.object?.model}
            </span>
          </div>
          <div className="border-stroke-dark-12 py-4 text-center max-[500px]:border-b min-[501px]:border-r">
            <span>{invoiceInfo?.object?.plate_number}</span>
          </div>
          <div className="border-stroke-dark-12 py-4 text-center">
            <span>{getValue()}</span>
          </div>
        </div>
        <div className="flex justify-between border-b border-stroke-dark-12 p-4">
          <span>Үндсэн хураамж:</span>
          <span className="font-semibold">
            {formatNumber(invoiceInfo?.premium?.base_premium)}₮
          </span>
        </div>
        <div className="flex justify-between border-b border-stroke-dark-12 p-4">
          <span>Нэмэлт хамгаалалтын хураамж:</span>
          <span className="font-semibold">
            {formatNumber(invoiceInfo?.premium?.additional_premium)}₮
          </span>
        </div>
        <div className="flex justify-between border-t border-stroke-dark-12 p-4">
          <span className="font-semibold">Нийт дүн:</span>
          <span className="text-xl font-semibold">
            {formatNumber(invoiceInfo?.payment?.amount)}₮
          </span>
        </div>
      </div>
      <Typography variant="Label" size="md" className="mt-5">
        Ибаримт авах{" "}
        {isIndividual === "person" ? "хувь хүний" : "бизнес эрхлэгчийн"}{" "}
        мэдээлэл
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="just mt-2 flex flex-col justify-between md:flex-row">
          <RadioButton
            id="payment_person_radio_button"
            label={
              <Typography variant="Body" size="lg">
                Хувь хүн
              </Typography>
            }
            checked={"person" === isIndividual}
            value={"person"}
            onChange={(e) => setIsIndividual(e.target.value)}
          />
          <RadioButton
            id="payment_company_radio_button"
            label={
              <div className="flex flex-wrap justify-start">
                <Typography variant="Body" size="lg">
                  Байгууллага
                </Typography>
              </div>
            }
            checked={"company" === isIndividual}
            value={"company"}
            onChange={(e) => setIsIndividual(e.target.value)}
          />
          <RadioButton
            id="payment_company_person_radio_button"
            label={
              <div className="flex flex-wrap justify-start">
                <Typography variant="Body" size="lg">
                  Бизнес эрхлэгч
                </Typography>
              </div>
            }
            checked={"companyPerson" === isIndividual}
            value={"companyPerson"}
            onChange={(e) => setIsIndividual(e.target.value)}
          />
        </div>
        <div className="mt-2">
          {isIndividual === "person" && (
            <>
              <Input
                width="w-full"
                id="qpay_mobile"
                label="Гар утасны дугаар"
                maxLength={8}
                type="number"
                onChange={(e) => {
                  const value = e.target.value;
                  if (value.length > 8) setQpayMobile(value.slice(0, 8));
                  else setQpayMobile(value);
                  setQpayMobileError("");
                }}
              />
              {qpayMobileError && (
                <Typography variant="Body" size="sm" className="text-red-500">
                  {qpayMobileError}
                </Typography>
              )}
            </>
          )}
          {isIndividual === "company" && (
            <div>
              <div>
                <>
                  <Input
                    width="w-full"
                    type="number"
                    id="registrationNumber"
                    label="Байгууллагын регистрийн дугаар"
                    maxLength={7}
                    onChange={(e) => {
                      let value = e.target.value;
                      const isValidNumber = /^\d{1,8}$/.test(value);
                      if (isValidNumber && value.length <= 7) {
                        setRegistrationNumber(value);
                        setRegistrationNumberError("");
                        setInputMaxLength(7);
                        if (value.length === 7) {
                          getCompanyName(value);
                        }
                      } else if (isValidNumber && value.length < 7) {
                        setRegistrationNumber(value);
                        setRegistrationNumberError(
                          "Буруу формат. 7 оронтой тоо байх ёстой",
                        );
                      }
                    }}
                  />
                  {registrationNumberError && (
                    <div className="mt-1">
                      <Typography
                        variant="Body"
                        size="sm"
                        className="text-red-500"
                      >
                        {registrationNumberError}
                      </Typography>
                    </div>
                  )}
                </>
              </div>
              <div className="mt-4">
                <>
                  <Input
                    width="w-full"
                    type="text"
                    id="companyName"
                    label="Байгууллагын нэр"
                    disabledV2={true}
                    value={ebarimtCompanyName}
                  />
                </>
              </div>
            </div>
          )}
          {isIndividual === "companyPerson" && (
            <div>
              <div>
                <>
                  <Input
                    width="w-full"
                    type="text"
                    id="registrationNumberPerson"
                    label="Бизнес эрхлэгч регистрийн дугаар"
                    maxLength={10}
                    onChange={(e) => {
                      let value = e.target.value;
                      if (/^[А-Яа-я]{2}/.test(value)) {
                        value =
                          value.slice(0, 2).toUpperCase() + value.slice(2);
                      }
                      const isValidString = /^[А-Я]{2}\d{0,8}$/.test(value);

                      if (isValidString && value.length <= 10) {
                        setPersonRegistrationNumber(value);
                        setPersonRegistrationNumberError("");
                        setInputMaxLength(10);

                        if (value.length === 10) {
                          getCompanyName(value);
                        }
                      } else if (value.length === 0) {
                        setPersonRegistrationNumber(value);
                        setPersonRegistrationNumberError(
                          "Бизнес эрхлэгчийн регистрийн дугаар заавал оруулах шаардлагатай.",
                        );
                      } else if (
                        (/^[А-Яа-я]/.test(value) && !isValidString) ||
                        !isNumber(value)
                      ) {
                        setPersonRegistrationNumber(value);
                        setPersonRegistrationNumberError(
                          "Регистрийн дугаар РДXXXXXXXX байх шаардлагатай",
                        );
                      }
                    }}
                  />
                  {personRegistrationNumberError && (
                    <div className="mt-1">
                      <Typography
                        variant="Body"
                        size="sm"
                        className="text-red-500"
                      >
                        {personRegistrationNumberError}
                      </Typography>
                    </div>
                  )}
                </>
              </div>
              <div className="mt-4">
                <>
                  <Input
                    width="w-full"
                    type="text"
                    id="companyName"
                    label="Бизнес эрхлэгчийн нэр"
                    disabledV2={true}
                    value={ebarimtCompanyName}
                  />
                </>
              </div>
            </div>
          )}
        </div>
        <div className="flex justify-end">
          <Button className="mt-10" type="submit">
            Баталгаажуулах
          </Button>
        </div>
      </form>
      <Modal {...{ isOpenModal }} onClose={() => setIsOpenModal(false)}>
        <Payment {...{ invoiceInfo }} />
      </Modal>
    </div>
  );
}

export default StepTwo;
