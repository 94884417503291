import PropTypes from "prop-types";
import { twMerge } from "tailwind-merge";
import IconButton from "../IconButton";
import { ReactComponent as ChevronLeft } from "../../assets/icon/chevron-left.svg";
import { ReactComponent as ChevronRight } from "../../assets/icon/chevron-right.svg";

const rowsLimit = [10, 20, 30];

function Pagination({
  className,
  rowsPerPage = 10,
  current = 1,
  totalPage = 1,
  simple = false,
  onClickPrevPage = () => {},
  onClickNextPage = () => {},
  onClickRowsPerPage = () => {},
  children,
  ...props
}) {
  return (
    <nav className={className}>
      <ul className="mr-0 flex w-full items-center space-x-2">
        {/* Rows Per Page Selector */}
        {rowsPerPage && totalPage > 1 && (
          <div className="mr-10 flex items-center space-x-4">
            <span className="text-on-surface-black-64">Мөрөөр үзэх</span>
            <div className="flex space-x-0.5">
              {rowsLimit.map((value, index) => (
                <div
                  key={index}
                  onClick={() => onClickRowsPerPage(value)}
                  className={twMerge([
                    "cursor-pointer rounded-full border px-4 py-2 text-primary",
                    rowsPerPage === value &&
                      "border-none bg-primary text-white",
                  ])}
                >
                  {value}
                </div>
              ))}
            </div>
          </div>
        )}

        {/* Page Number Display */}
        {simple && (
          <span className="px-2 text-sm text-gray-700">
            {current ? current : 1}/{totalPage}
          </span>
        )}

        {/* Previous Page Button */}
        <Pagination.Link
          disabled={current === 1}
          onClick={onClickPrevPage}
          className="mr-0 border-stroke-dark-12"
        >
          <ChevronLeft />
        </Pagination.Link>

        {/* Children (Additional Navigation Elements) */}
        {children}

        {/* Next Page Button */}
        <Pagination.Link
          disabled={current >= totalPage}
          onClick={onClickNextPage}
          className="ml-0 border-stroke-dark-12"
        >
          <ChevronRight />
        </Pagination.Link>
      </ul>
    </nav>
  );
}

Pagination.Link = ({ className, active, disabled, children, ...props }) => {
  return (
    <li className="flex-1 sm:flex-initial">
      <IconButton
        {...props}
        as="a"
        variant="softPrimary"
        className={twMerge([
          "border border-transparent bg-white text-on-surface-black-64",
          active && "!box border-secondary font-medium",
          disabled && "cursor-not-allowed opacity-50",
          className,
        ])}
        disabled={disabled}
      >
        {children}
      </IconButton>
    </li>
  );
};

Pagination.propTypes = {
  className: PropTypes.string,
  rowsPerPage: PropTypes.number,
  current: PropTypes.number,
  totalPage: PropTypes.number,
  simple: PropTypes.bool,
  onClickPrevPage: PropTypes.func,
  onClickNextPage: PropTypes.func,
  onClickRowsPerPage: PropTypes.func,
  children: PropTypes.node,
};

Pagination.defaultProps = {
  rowsPerPage: 10,
  current: 1,
  totalPage: 1,
  simple: false,
  onClickPrevPage: () => {},
  onClickNextPage: () => {},
  onClickRowsPerPage: () => {},
};

Pagination.Link.propTypes = {
  className: PropTypes.string,
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  children: PropTypes.node,
};

Pagination.Link.defaultProps = {
  active: false,
  disabled: false,
};

export default Pagination;
