import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ReactComponent as Success } from "../../assets/icon/fi-sr-check-circle-filled.svg";
import { ReactComponent as Info } from "../../assets/icon/fi-rr-interrogation.svg";
import { ReactComponent as Warning } from "../../assets/icon/fi-rr-exclamation.svg";
import { ReactComponent as Error } from "../../assets/icon/fi-rr-cross-circle.svg";
import { ReactComponent as Cross } from "../../assets/icon/cross.svg";
import { twMerge } from "tailwind-merge";
import PropTypes from "prop-types";
import IconButton from "../IconButton";
import Typography from "../Typography";

const AlertModal = (props) => {
  const {
    isOpen,
    onClose = () => {},
    type,
    title,
    closeButton = false,
    message,
    footer,
    size,
  } = props;

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => onClose()}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                className={twMerge([
                  "relative w-full max-w-md transform overflow-hidden rounded-xl bg-white p-8 text-left align-middle shadow-xl transition-all max-[500px]:w-5/6",
                  size === "lg" && "grid min-h-[400px] max-w-lg",
                ])}
              >
                {closeButton && (
                  <IconButton
                    variant="outlinePrimary"
                    onClick={onClose}
                    className="absolute right-4 top-4 border-0"
                  >
                    <Cross fill="#0A213A" className="h-5 w-5" />
                  </IconButton>
                )}
                <div className="self-center p-5 text-center">
                  <div className="flex justify-center">
                    <div
                      className={twMerge([
                        "rounded-full p-4",
                        type === "success" && "bg-success-soft",
                        type === "warning" && "bg-warning-soft",
                        type === "info" && "bg-info-soft",
                        type === "error" && "bg-error-soft",
                      ])}
                    >
                      {type === "success" && (
                        <Success className="mx-auto h-6 w-6" />
                      )}
                      {type === "warning" && (
                        <Warning className="mx-auto h-6 w-6" />
                      )}
                      {type === "info" && <Info className="mx-auto h-6 w-6" />}
                      {type === "error" && (
                        <Error className="mx-auto h-6 w-6" />
                      )}
                    </div>
                  </div>
                  <Typography
                    variant="Headline"
                    size="sm"
                    className={twMerge(["mt-5", !message && "mb-12"])}
                  >
                    {title}
                  </Typography>
                  <div className="mt-2 text-on-surface-black-64">{message}</div>
                </div>
                {footer && (
                  <div className="align-center -mx-8 flex justify-center border-t border-stroke-dark-12 px-8 pt-4">
                    {footer}
                  </div>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

AlertModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  type: PropTypes.string,
  title: PropTypes.string,
  closeButton: PropTypes.bool,
  message: PropTypes.string,
  footer: PropTypes.element,
};

export default AlertModal;
