import { useEffect, useState } from 'react'
import { twMerge } from 'tailwind-merge'
import { Drawer, ThemeProvider, createTheme } from '@mui/material'
import dayjs from 'dayjs'
import IconButton from '../../../../base-components/IconButton'
import Typography from '../../../../base-components/Typography'
import Button from '../../../../base-components/Button'
import Steps from '../../../../base-components/Steps'
import CustomizedTooltip from '../../../../base-components/Tooltip'
import Accordion from '../../../../base-components/Accordion'
import { ReactComponent as Close } from '../../../../assets/icon/cross.svg'
import { ReactComponent as ChartColumn } from '../../../../assets/icon/chart-column.svg'
import { ReactComponent as WalletMove } from '../../../../assets/icon/wallet-move.svg'
import { ReactComponent as FileDownload } from '../../../../assets/icon/file-download.svg'
import { ReactComponent as ShieldCheck } from '../../../../assets/icon/shield-check-1.svg'
import { ReactComponent as Receipt } from '../../../../assets/icon/receipt-1.svg'
import { ReactComponent as Image } from '../../../../assets/icon/image-1.svg'
import { ReactComponent as Cash } from '../../../../assets/icon/cash-1.svg'
import { ReactComponent as Refresh } from '../../../../assets/icon/refresh-ccw.svg'
import { ReactComponent as ShieldCheckOutline } from '../../../../assets/icon/shield-check.svg'
import { ReactComponent as ShieldPlus } from '../../../../assets/icon/shield-plus.svg'
import { ReactComponent as ChevronLeft } from '../../../../assets/icon/chevrons-left.svg'
import { formatNumber, isEmptyObject } from '../../../../utils/helper'

import PolicyInfoService from '../../../../services/PolicyInfoService'

const theme = createTheme({
  breakpoints: {
    values: {
      mobile: 0,
      tablet: 640,
      laptop: 1024,
      desktop: 1280
    }
  }
})

const steps = [
  { title: 'Материал цуглуулах' },
  { title: 'Хянагдаж байна/Зөвшөөрөөгүй' },
  { title: 'Төлбөр төлөхөөр хүлээгдэж байна' },
  { title: 'Төлбөр төлөгдсөн' }
]

const ProductDetail = ({ open, onClose = () => {}, insuranceDtl }) => {
  const [isFullWidth, setIsFullWidth] = useState(false)
  const [currentStep, setCurrentStep] = useState(1)

  useEffect(() => {
    if (!insuranceDtl.claim_status) return
    let index = steps.findIndex(x => x.title === insuranceDtl.claim_status)
    setCurrentStep(index + 1)
  }, [])

  const gettingStatus = active => {
    if (active) return 'Идэвхтэй'
    else return 'Идэвхгүй'
  }

  const handleDownload = () => {
    if (insuranceDtl?.id)
      PolicyInfoService.getInsurancePolicyFileUrl(insuranceDtl.id, {
        nonLoading: true
      }).then(response => window.open(response, '_blank'))
  }

  return (
    <ThemeProvider theme={theme}>
      <Drawer
        open={open}
        anchor='right'
        sx={{
          '& .MuiBackdrop-root': {
            background: '#0a213a80'
          },
          '& .MuiDrawer-paper': {
            boxShadow: 'none',
            flexDirection: 'row',
            width: {
              desktop: isFullWidth ? 'calc(100% - 272px)' : 'max-content',
              tablet: '100%',
              mobile: '100%'
            }
          }
        }}
        onClose={() => {
          setIsFullWidth(false)
          onClose()
        }}
      >
        <div className='bg-primary/[0.5] h-[calc(100%+120px)] max-xl:hidden'>
          <div className='w-8'>
            <button
              onClick={() => setIsFullWidth(prev => !prev)}
              className='left-5 absolute top-[calc(100%/2-100px)] p-1 h-[208px] w-fit z-[1200] bg-white rounded-tl-full rounded-bl-full transition-all outline-none'
            >
              <ChevronLeft
                stroke='#0A213A'
                className={twMerge([isFullWidth && 'rotate-180'])}
              />
            </button>
          </div>
        </div>
        <div className='flex justify-center w-full'>
          <div className=''>
            <div className='flex items-center justify-between border-b max-[500px]:px-4 max-lg:px-10 px-14 py-6 gap-3 max-[500px]:gap-2'>
              <div className='flex items-center gap-4 w-80 mr-4 max-[800px]:w-72 max-[500px]:w-auto'>
                <div>
                  <IconButton
                    size='sm'
                    variant='outlinePrimary'
                    onClick={() => {
                      setIsFullWidth(false)
                      onClose()
                    }}
                  >
                    <Close fill='#69798C' />
                  </IconButton>
                </div>
                <CustomizedTooltip title={insuranceDtl.dynamic_product_name}>
                  <Typography
                    variant='Headline'
                    size='sm'
                    className='second-line-ellipsis'
                  >
                    {insuranceDtl.dynamic_product_name}
                  </Typography>
                </CustomizedTooltip>
              </div>
              <div className='flex items-center gap-3 max-[500px]:gap-2'>
                <Button
                  size='sm'
                  variant='softPrimary'
                  icon={<ChartColumn stroke='#0A213A' className='w-4 h-4' />}
                  textSize='md'
                  className='space-x-2 max-md:hidden'
                >
                  Төлбөрийн түүх
                </Button>
                <Button
                  size='sm'
                  variant='softPrimary'
                  icon={<WalletMove stroke='#0A213A' className='w-4 h-4' />}
                  textSize='md'
                  className='space-x-2 max-md:hidden'
                >
                  Төлбөрийн түүх
                </Button>
                <IconButton
                  size='sm'
                  variant='softPrimary'
                  className='hidden max-md:flex'
                >
                  <ChartColumn stroke='#0A213A' className='w-4 h-4' />
                </IconButton>
                <IconButton
                  size='sm'
                  variant='softPrimary'
                  className='hidden max-md:flex'
                >
                  <WalletMove stroke='#0A213A' className='w-4 h-4' />
                </IconButton>
                <IconButton size='sm' variant='softPrimary'>
                  <FileDownload
                    stroke='#0A213A'
                    className='w-4 h-4'
                    onClick={() => handleDownload()}
                  />
                </IconButton>
              </div>
            </div>
            <div className='max-lg:px-10 max-[500px]:px-4 px-14 py-6 h-[calc(100%+7px)] overflow-auto scrollbar-hide'>
              <div className='bg-surface-gray rounded-2xl'>
                <div className='border rounded-2xl bg-white max-[885px]:px-6 px-10 py-6 space-y-4'>
                  {insuranceDtl.package_name && (
                    <div>
                      <Typography variant='Label' size='md' type='softPrimary'>
                        Багц
                      </Typography>
                      <Typography variant='Headline' size='sm'>
                        {insuranceDtl.package_name}
                      </Typography>
                      <Typography variant='Label' size='md'>
                        Үндсэн хамгаалалт
                      </Typography>
                    </div>
                  )}
                  <div className='grid grid-cols-4 max-md:grid-cols-2 gap-4 max-[800px]:gap-2'>
                    <div className='space-y-2'>
                      <Typography variant='Label' size='md' type='softPrimary'>
                        Төлөв
                      </Typography>
                      <div className='bg-surface-gray rounded-full inline-flex w-fit items-stretch py-1 px-3 space-x-2'>
                        <ShieldCheck className='h-[18px] h-[18px]' />
                        <Typography size='md'>
                          {gettingStatus(insuranceDtl.active)}
                        </Typography>
                        <div className='border-l'></div>
                        <div
                          className={twMerge([
                            'rounded-full w-2.5 h-2.5 self-center',
                            insuranceDtl.active ? 'bg-success' : 'bg-error'
                            // product.status === "incomplete" && "bg-warning",
                          ])}
                        ></div>
                      </div>
                    </div>
                    <div className='space-y-2'>
                      <Typography variant='Label' size='md' type='softPrimary'>
                        Гэрээний дугаар
                      </Typography>
                      <CustomizedTooltip title={insuranceDtl.warranty_number}>
                        <div className='bg-surface-gray rounded-full inline-flex w-fit items-stretch py-1 px-3 space-x-2'>
                          <Receipt className='h-[18px] h-[18px]' />
                          <Typography
                            size='md'
                            className='truncate max-lg:max-w-[6rem]'
                          >
                            {insuranceDtl.warranty_number}
                          </Typography>
                        </div>
                      </CustomizedTooltip>
                    </div>
                    <div className='space-y-2'>
                      <Typography variant='Label' size='md' type='softPrimary'>
                        Бүтээгдэхүүн
                      </Typography>
                      <CustomizedTooltip
                        title={insuranceDtl?.dynamic_product_name}
                      >
                        <div className='bg-surface-gray rounded-full inline-flex w-fit items-stretch py-1 px-3 space-x-2'>
                          <Image className='h-[18px] h-[18px]' />
                          <Typography
                            size='md'
                            className='truncate max-w-[10rem] max-lg:max-w-[6rem]'
                          >
                            {insuranceDtl.dynamic_product_name}
                          </Typography>
                        </div>
                      </CustomizedTooltip>
                    </div>
                    <div className='space-y-2'>
                      <Typography variant='Label' size='md' type='softPrimary'>
                        Үнэлгээ
                      </Typography>
                      <CustomizedTooltip
                        title={`${formatNumber(
                          insuranceDtl?.market_price,
                          0
                        )} MNG`}
                      >
                        <div className='bg-surface-gray rounded-full inline-flex w-fit items-stretch py-1 px-3 space-x-2'>
                          <Cash className='h-[18px] h-[18px]' />
                          <Typography
                            size='md'
                            className='truncate max-lg:max-w-[6rem]'
                          >
                            {formatNumber(insuranceDtl?.market_price, 0)} {
                              insuranceDtl?.dynamoc_product_currency_symbol
                              ?
                              insuranceDtl.dynamoc_product_currency_symbol
                              :
                              "₮"
                            }
                          </Typography>
                        </div>
                      </CustomizedTooltip>
                    </div>
                  </div>
                  <div className='mt-6 space-y-2'>
                    <Typography variant='Label' size='md' type='softPrimary'>
                      Дуусах хугацаа:
                    </Typography>
                    <Typography variant='Label' size='md' type='softPrimary'>
                      {dayjs(insuranceDtl.expiry_datetime).format('YYYY-MM-DD')}{' '}
                      · {insuranceDtl.diff_days} өдөр үлдсэн
                    </Typography>
                    <CustomizedTooltip title={insuranceDtl.percent}>
                      <div className='rounded-full h-2 bg-surface-gray cursor-pointer w-full'>
                        <div
                          style={{ width: insuranceDtl.percent }}
                          className='rounded-full h-2 bg-primary cursor-pointer'
                        ></div>
                      </div>
                    </CustomizedTooltip>
                  </div>
                  <div className='pt-5 grid grid-cols-2 max-[500px]:grid-cols-1 gap-14 max-[885px]:gap-10'>
                    <div className=''>
                      <Typography variant='Title' size='md'>
                        Даатгуулагч
                      </Typography>
                      <div className='space-y-2 mt-2'>
                        <div className='border-t'></div>
                        <div className='flex justify-between'>
                          <Typography size='md' type='softPrimary'>
                            Нэр:
                          </Typography>
                          <Typography variant='Label'>
                            {insuranceDtl.partner &&
                              insuranceDtl.partner['Нэр']}
                          </Typography>
                        </div>
                        <div className='flex justify-between'>
                          <Typography size='md' type='softPrimary'>
                            Овог:
                          </Typography>
                          <Typography variant='Label'>
                            {insuranceDtl.partner &&
                              insuranceDtl.partner['Овог']}
                          </Typography>
                        </div>
                        <div className='flex justify-between'>
                          <Typography size='md' type='softPrimary'>
                            РД:
                          </Typography>
                          <Typography variant='Label'>
                            {insuranceDtl.partner && insuranceDtl.partner['РД']}
                          </Typography>
                        </div>
                        <div className='flex justify-between'>
                          <Typography size='md' type='softPrimary'>
                            Гар утас:
                          </Typography>
                          <Typography variant='Label'>
                            {insuranceDtl.partner &&
                              insuranceDtl.partner['Гар утас']}
                          </Typography>
                        </div>
                        <div className='flex justify-between'>
                          <Typography size='md' type='softPrimary'>
                            Имэйл хаяг:
                          </Typography>
                          <Typography variant='Label'>
                            {insuranceDtl.partner &&
                              insuranceDtl.partner['И-мэйл']}
                          </Typography>
                        </div>
                        <div className='flex justify-between'>
                          <Typography size='md' type='softPrimary'>
                            Хаяг:
                          </Typography>
                          <Typography variant='Label'>
                            {insuranceDtl.partner &&
                              insuranceDtl.partner['Байр']}
                          </Typography>
                        </div>
                      </div>
                    </div>
                    <div className=''>
                      <Typography variant='Title' size='md'>
                        Даатгуулсан зүйл
                      </Typography>
                      <div className='space-y-2 mt-2'>
                        <div className='border-t'></div>
                        {insuranceDtl.object &&
                          !isEmptyObject(insuranceDtl.object) &&
                          Object.keys(insuranceDtl.object)
                            .sort()
                            .map((field, index) => (
                              <div key={index} className='flex justify-between'>
                                <Typography size='md' type='softPrimary'>
                                  {field}
                                </Typography>
                                <Typography variant='Label'>
                                  {insuranceDtl.object[field]}
                                </Typography>
                              </div>
                            ))}
                        {/* <div className="flex justify-between">
                        <Typography size="md" type="softPrimary">Овог:</Typography>
                        <Typography variant="Label">Болд</Typography>
                      </div>
                      <div className="flex justify-between">
                        <Typography size="md" type="softPrimary">РД:</Typography>
                        <Typography variant="Label">УО92041736</Typography>
                      </div> */}
                        {/* <div className="border-t"></div>
                      <div>
                        <Typography size="md" type="softPrimary">Тайлбар:</Typography>
                        <Typography variant="Label">Хүний амь нас, эрүүл мэнд,  ачаа тээш,  бичиг баримт</Typography>
                      </div> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className='mt-4 px-10 max-[500px]:px-4 py-6 space-y-4'>
                  {insuranceDtl.claim_status && (
                    <div className='border rounded-xl p-4 space-y-4 bg-white'>
                      <div className='flex items-center py-3 space-x-4'>
                        <Refresh stroke='#69798C' className='w-5 h-5' />
                        <Typography variant='Title' type='softPrimary'>
                          Нөхөн төлбөрийн шийдвэрлэлтийн явц
                        </Typography>
                      </div>
                      <div className='max-[500px]:w-72 w-full flex max-xl:overflow-auto scrollbar-hide'>
                        <Steps
                          current={currentStep}
                          items={steps}
                          className='space-x-8'
                        />
                      </div>
                    </div>
                  )}
                  {insuranceDtl.package_name && (
                    <div className='space-y-3'>
                      <div className='flex items-center py-3 space-x-4'>
                        <ShieldCheckOutline
                          stroke='#69798C'
                          className='w-5 h-5'
                        />
                        <Typography variant='Title' type='softPrimary'>
                          Даатгалын үндсэн хамгаалалт
                        </Typography>
                      </div>
                      <Accordion>
                        <Accordion.Header>
                          <Typography variant='Label'>
                            {insuranceDtl.package_name}
                          </Typography>
                        </Accordion.Header>
                        {insuranceDtl.perils?.length > 0 && (
                          <Accordion.Details>
                            <Typography variant='Title' size='md'>
                              Даатгалын эрсдлүүд
                            </Typography>
                            <ul className='list-disc px-8'>
                              {insuranceDtl.perils.map((peril, index) => (
                                <li key={index} className='mt-4'>
                                  <Typography>{peril.name}</Typography>
                                </li>
                              ))}
                            </ul>
                          </Accordion.Details>
                        )}
                      </Accordion>
                    </div>
                  )}
                  {insuranceDtl.extra_services?.length > 0 && (
                    <div className='space-y-3'>
                      <div className='flex items-center py-3 space-x-4'>
                        <ShieldPlus stroke='#69798C' className='w-5 h-5' />
                        <Typography variant='Title' type='softPrimary'>
                          Нэмэлт хамгаалалт
                        </Typography>
                      </div>
                      {insuranceDtl.extra_services.map((value, index) => (
                        <Accordion key={index}>
                          <Accordion.Header>
                            <Typography variant='Label'>
                              {value?.name}
                            </Typography>
                          </Accordion.Header>
                        </Accordion>
                      ))}
                    </div>
                  )}
                  {/* <div className="space-y-3">
                  <div className="flex items-center py-3 space-x-4">
                    <ShieldPlus stroke="#69798C" className="w-5 h-5" />
                    <Typography variant="Title" type="softPrimary">Даатгалын нөхцөл</Typography>
                  </div>
                  <Accordion>
                    <Accordion.Header>
                      <Typography variant="Label">
                        Нөхөн олгох нөхцөл
                      </Typography>
                    </Accordion.Header>
                    <Accordion.Details>
                      hdjkgdfjk
                    </Accordion.Details>
                  </Accordion>
                  <Accordion>
                    <Accordion.Header>
                      <Typography variant="Label">
                        Нөхөн төлбөр олгохоос татгалзах тусгай нөхцөл
                      </Typography>
                    </Accordion.Header>
                    <Accordion.Details>
                      hdjkgdfjk
                    </Accordion.Details>
                  </Accordion>
                </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Drawer>
    </ThemeProvider>
  )
}

export default ProductDetail
