import React, { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PropTypes from "prop-types";

const CustomTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  '& .MuiTabs-indicatorSpan': {
    width: '100%',
    backgroundColor: '#0A213A',
    zIndex: 2
  },
  '& .MuiTabs-flexContainer': {
    width: "100%"
    // display: "grid",
    // gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
  },
  minHeight: 36
});

const CustomTab = styled((props) =>
  <Tab disableRipple {...props} />
)({
  textTransform: 'none',
  fontWeight: 500,
  fontSize: 14,
  color: '#69798C',
  '&.Mui-selected': {
    color: '#0A213A',
  },
  '&.Mui-focusVisible': {
    backgroundColor: 'rgba(100, 95, 228, 0.32)',
  },
  '&.MuiTab-root': {
  },
  padding: "8px 16px",
  minHeight: 36,
  letterSpacing: '0.25px',
}
);

/* MUI ashiglasan */

export default function CustomizedTabs({ value = 0, onChange = () => { }, ...props }) {
  const [selectValue, setSelectValue] = React.useState(value);

  useEffect(() => {
    setSelectValue(value);
  }, [value])

  const handleChange = (event, newValue) => {
    setSelectValue(newValue);
    onChange(newValue);
  };

  return (
    <div>
      <CustomTabs
        {...props}
        value={selectValue}
        onChange={handleChange}
      >
        {props.children}
      </CustomTabs>
    </div>
  );
}

CustomizedTabs.Tab = (props) => {
  return (
    <CustomTab {...props} />
  )
}

CustomizedTabs.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func,
}