import React, { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { useStore } from "../../../stores";

import Button from "../../../base-components/Button";
import AlertModal from "../../../base-components/AlertModal";
import IconButton from "../../../base-components/IconButton";

import {
  ReactComponent as ArrowLeft
} from "../../../assets/icon/arrow-left.svg";

import Otp from "./otp";
import Mobile from "./mobile";
import Password from "./password";

import RegisterService from "../../../services/RegisterService";


function SignIn() {
  const methods = useForm({
    defaultValues: {
      mobile: '',
      otp: '',
      password: '',
      re_password: '',
    }
  })
  const [mobile, setMobile] = useState('');
  const [isShowAlert, setIsShowAlert] = useState(false);
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const setToken = useStore.useSetToken();
  const navigate = useNavigate();

  const backToField = (e) => {
    e.preventDefault();
    if (step > 1)
      setStep(step - 1);
    else
      navigate('/login');
  }
  const onSubmit = (data) => {
    setLoading(true);
    RegisterService.createWebUser(data)
      .then((response) => {
        if (response.status === "success") {
          // setToken(response.token);
          setIsShowAlert(true);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error, "error");
      })
    // setIsShowAlert(true);
  }

  return (
    <FormProvider {...methods}>
      <div className="flex mb-8">
        <IconButton size="sm" variant="softPrimary" onClick={backToField}>
          <ArrowLeft className="w-5 h-5" />
        </IconButton>
      </div>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <div className="w-[333px] max-[500px]:w-full">
          {
            step === 1 &&
            <Mobile {...{ mobile, setMobile, step, setStep }} />
          }
          {
            step === 2 &&
            <Otp {...{ step, setStep }} />
          }
          {
            step === 3 &&
            <>
              <Password {...{ loading }} />
              <div className="flex justify-end">
                <Button type="submit" className="w-1/2">
                  Болсон
                </Button>
              </div>
            </>
          }
        </div>
      </form>
      <AlertModal
        isOpen={isShowAlert}
        type="success"
        title="Та амжилттай бүртгүүллээ"
        footer={
          <Button onClick={() => navigate("/insure")}>Ойлголоо</Button>
        }
      />
    </FormProvider >
  )
}

export default SignIn;


// showNextField ?
// <>
//   <div>
//     <Input
//       id="sign-in-name"
//       label="Нэр"
//       {...register('name', {
//         required: true,
//         maxLength: 50,
//         // pattern: /^[7-9]$/,
//         // onChange: (e) => setMobile(e.target.value),
//       })}
//       className="w-80"
//     />
//     {
//       errors?.name?.type === "required" &&
//       <p className="text-error text-xs italic pl-2 mt-1">Нэрээ оруулна уу!</p>
//     }
//     {
//       errors?.mobile?.type === "maxLength" &&
//       <p className="text-error text-xs italic pl-2 mt-1">Нэр авах утгын урт хэтэрсэн байна!</p>
//     }
//   </div>
//   <div>
//     <Input
//       id="sign-in-email"
//       label="И-мэйл"
//       {...register('email', {
//         required: true,
//         maxLength: 50,
//       })}
//       width="w-80"
//     />
//     {
//       errors?.email?.type === "required" &&
//       <p className="text-error text-xs italic pl-2 mt-1">И-мэйл хаягаа оруулна уу!</p>
//     }
//   </div>
//   <div>
//     <Input
//       id="sign-in-birthday"
//       label="Төрсөн он сар өдөр"
//       {...register('birthday', {
//         required: true,
//         maxLength: 50,
//       })}
//       width="w-80"
//     />
//     {
//       errors?.birthday?.type === "required" &&
//       <p className="text-error text-xs italic pl-2 mt-1">Төрсөн он сар өдрөө оруулна уу!</p>
//     }
//   </div>
// </> 
