import { useFormContext } from "react-hook-form";

import Typography from "../../../../base-components/Typography";
import Button from "../../../../base-components/Button";

import { formatNumber } from "../../../../utils/helper";

const Summary = ({ currency = "₮" }) => {
  const { watch } = useFormContext();
  return (
    <>
      <div className="mt-10 space-y-4">
        <Typography variant="Title" size="md">
          Даатгалын хураангуй
        </Typography>
        <div className="mt-4 rounded-2xl border border-stroke-dark-12 text-primary">
          <div className="flex justify-between border-b border-stroke-dark-12 p-4">
            <Typography>Үндсэн хураамж:</Typography>
            <Typography variant="Title" size="md">
              {formatNumber(watch("base_payment"))}
              {currency}
            </Typography>
          </div>
          {/* <div className="flex justify-between border-b border-stroke-dark-12 p-4">
            <Typography>
              Нэмэлт үйлчилгээ:
            </Typography>
            <Typography variant="Title" size="md">
              {formatNumber(methods.watch('extra_price'))}₮
            </Typography>
          </div> */}
          <div className="flex justify-between border-t border-stroke-dark-12 p-4">
            <Typography variant="Title" size="md">
              Нийт дүн:
            </Typography>
            <Typography variant="Title">
              {formatNumber(watch("amount"))}
              {currency}
            </Typography>
          </div>
        </div>
      </div>
      <div className="flex justify-end">
        <Button className="my-6" type="submit">
          Үргэлжлүүлэх
        </Button>
      </div>
    </>
  );
};

export default Summary;
