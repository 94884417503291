import React from "react";

const DTableSekeletonLoader = ({ columns, rows = 5 }) => {
  return (
    <>
      {[...Array(rows)].map((_, rowIndex) => (
        <tr key={rowIndex} className="bg-white">
          {columns.map((_, colIndex) => (
            <td key={colIndex} className="border-b border-gray-300 p-4">
              <div className="h-4 w-full animate-pulse rounded bg-gray-200"></div>
            </td>
          ))}
        </tr>
      ))}
    </>
  );
};

export default DTableSekeletonLoader;
