import { Fragment, forwardRef, useState } from "react";
import { twMerge } from "tailwind-merge";
import { Transition } from "@headlessui/react";
import { ClickAwayListener } from "@mui/material";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import { ReactComponent as CalendarIcon } from "../../assets/icon/calendar.svg";
import { isNullOrUndefined } from "../../utils/helper";
import Calendar from "../../base-components/Calendar";
import CalendarMonthYear from "../../base-components/Calendar/monthYear";

const Date = forwardRef((props, ref) => {
  const {
    disabled = false,
    id = "",
    label,
    date = dayjs(),
    className,
    placement = "left",
    onChangeDate = () => {},
    minDate,
    maxDate,
    isMonthLastDay = false,
  } = props;
  const [isShow, setIsShow] = useState(false);
  const [selectedDate, setSelectedDate] = useState(date);

  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (disabled) return;
    setIsShow(!isShow);
  };
  const handleChangeDate = (date) => {
    if (isMonthLastDay) date = dayjs(date).endOf("month");
    onChangeDate(date);
    setSelectedDate(date);
    setIsShow(false);
  };

  return (
    <ClickAwayListener onClickAway={() => setIsShow(false)}>
      <div className="relative w-full cursor-pointer">
        <label
          className={twMerge([
            "pointer-events-none absolute top-0 z-10 ml-1 translate-x-12 translate-y-4 text-sm text-on-surface-black-64 transition-all duration-300",
            !isNullOrUndefined(date) && "translate-y-1 text-xs",
          ])}
          htmlFor={id}
        >
          {label}
        </label>
        <button
          id={id}
          onClick={handleClick}
          className={twMerge([
            "w-full rounded-lg border border-stroke-dark-12 bg-white p-4 text-sm font-medium tracking-wide text-primary shadow-sm outline-none transition duration-200 ease-in-out",
            isShow && "border-on-surface-black-64",
            className,
          ])}
        >
          <div className="flex">
            <CalendarIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
            <span
              className={twMerge([
                "mx-4 block truncate",
                disabled && "text-on-surface-black-64",
              ])}
            >
              {date.format("YYYY/MM/DD") || selectedDate.format("YYYY/MM/DD")}
            </span>
          </div>
        </button>
        <Transition
          as={Fragment}
          show={isShow}
          enter="transition ease-out duration-200"
          enterFrom="opacity-0 translate-y-1"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-in duration-150"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 translate-y-1"
        >
          <div
            {...props}
            className={twMerge([
              "animation-all absolute z-50 mt-2 w-full w-max overflow-y-auto overflow-x-hidden rounded-lg",
              placement === "left" && "left-0",
              placement === "right" && "right-0",
            ])}
          >
            {props?.mode === "monthYear" ? (
              <CalendarMonthYear
                value={selectedDate}
                onChange={handleChangeDate}
                minDate={minDate}
                maxDate={maxDate}
                mode={props?.mode}
              />
            ) : (
              <Calendar
                value={selectedDate}
                onChange={handleChangeDate}
                minDate={minDate}
                maxDate={maxDate}
                mode={props?.mode}
              />
            )}
          </div>
        </Transition>
      </div>
    </ClickAwayListener>
  );
});

Date.propTypes = {
  disabled: PropTypes.bool,
  id: PropTypes.string,
  label: PropTypes.string,
  date: PropTypes.instanceOf(Date),
  className: PropTypes.string,
  placement: PropTypes.string,
  onChangeDate: PropTypes.func,
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
};

// Date.defaultProps = {
// date: null,
// minDate: null,
// maxDate: null,
// };

export default Date;
