import { twMerge } from "tailwind-merge";
import { ReactComponent as Check } from "../../assets/icon/check.svg";
import Typography from "../Typography";

function Steps(props) {
  const {
    current,
    setCurrent,
    items,
    orientation = "horizontal",
    className,
    onPrevStepClick = () => { }
  } = props;

  return (
    <div className={
      twMerge(["flex w-full box-border",
        orientation === 'vertical' ? "flex-col" : "space-x-14",
        className
      ])}
    >
      {
        items.map((step, index) =>
          <div key={index} className="flex-1 px-2 relative">
            {
              index !== 0 && orientation === 'horizontal' &&
              <div className='absolute top-3 left-[calc(-50%-44px)] right-[calc(50%+14px)]'>
                <span className={twMerge(['block border-dashed border-b',
                  current >= index + 1 ? 'border-primary' : 'border-stroke-dark-12',
                ])} />
              </div>
            }
            <span className={twMerge(["flex items-center",
              orientation === 'horizontal' && "flex-col",
            ])}
            >
              <span
                onClick={() => {
                  onPrevStepClick();
                  // if (index + 1 < current)
                  //   setCurrent(index + 1)
                }}
                className={
                  twMerge(["border-[1.5px] rounded-full cursor-pointer z-10",
                    current === index + 1 ?
                      'bg-primary text-white border-stroke-dark-12' :
                      current > index + 1 ?
                        'bg-gray-surface border-stroke-dark-20 p-1' :
                        'bg-white border-stroke-dark-20 text-on-surface-black-64',
                  ])}
              >
                {
                  current > index + 1 ?
                    <Check className="w-4 h-4" /> :
                    <span className="w-6 h-6 flex justify-center items-center">
                      {index + 1}
                    </span>
                }
              </span>
              <span className={twMerge([
                orientation === 'horizontal' && "mt-4 text-center",
                orientation === 'vertical' && "ml-4",
              ])}
              >
                <Typography
                  variant={current >= index + 1 ? 'Label' : "Title"}
                  size={current >= index + 1 ? 'lg' : "sm"}
                  className={twMerge([
                    current >= index + 1 ? 'text-primary font-semibold' : 'text-on-surface-black-64'
                  ])}>
                  {step.title}
                </Typography>
              </span>
            </span>
            <div className={twMerge(["text-sm text-on-surface-64 font-light leading-normal",
              orientation === "vertical" && items.length - 1 > index && "border-l-[1.5px] border-dashed border-stroke-dark-20 ml-[13px] pl-8 h-10",
            ])}>
              {step?.desctription}
            </div>
          </div>
        )
      }
    </div>
  )
}

export default Steps;