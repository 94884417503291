import Service from "./BaseService";

const getInsuranceProduct = (params, config) =>
  Service.get(`/dynamicInsurance/get-products?${params}`, {
    ...config,
    nonLoading: true,
  });

const getProduct = (productId, config) =>
  Service.get(`/dynamicInsurance/products/${productId}`, config);

const ProductService = {
  getInsuranceProduct,
  getProduct,
};

export default ProductService;
