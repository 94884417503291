import { forwardRef } from "react";
import { twMerge } from "tailwind-merge";
import PropTypes from "prop-types";

const IconButton = forwardRef((props, ref) => {
  const {
    className,
    variant = "softPrimary",
    size = "lg",
  } = props;

  const generalStyles = [
    'flex items-center justify-center rounded-full bg-white p-2 cursor-pointer outline-none', // border border-stroke-dark-20
  ];

  // Sizes
  const small = ["w-10 h-10"];
  const large = ["w-12 h-12"];

  // Main color
  const primary = [
    "bg-primary", // Default
    "focus:bg-primary-focus focus:text-on-surface-white-64", // On Focus
    "[&:hover:not(:disabled)]:bg-primary-hover [&:hover:not(:disabled)]:text-on-surface-white-64", // On hover and not disabled
    "disabled:bg-primary-disabled disabled:text-on-surface-black-24", // Disabled
  ];
  const secondary = [
    "bg-secondary", // Default
    "focus:bg-secondary-focus", // On Focus
    "[&:hover:not(:disabled)]:bg-secondary-hover", // On hover and not disabled
    "disabled:bg-secondary-disabled disabled:text-on-surface-black-24", // Disabled
  ];

  // Outline
  const outlinePrimary = [
    "border border-stroke-dark-12 bg-white text-primary", // Default
    "focus:bg-primary-soft-while-pressing focus:text-on-surface-black-64", // On Focus
    "[&:hover:not(:disabled)]:bg-primary-soft-while-pressing [&:hover:not(:disabled)]:text-on-surface-black-64", // On hover and not disabled
    "disabled:bg-primary-disabled disabled:text-on-surface-black-24", // Disabled
  ];
  const outlineSecondary = [
    "border border-stroke-secondary-20 bg-white text-on-surface-secondary", // Default
    "focus:bg-secondary-soft-focus focus:border-stroke-secondary-12", // On Focus
    "[&:hover:not(:disabled)]:bg-secondary-soft-while-pressing [&:hover:not(:disabled)]:border-stroke-secondary-12", // On hover and not disabled
    "disabled:bg-secondary-disabled disabled:text-on-surface-black-24 disabled:border-stroke-secondary-12", // Disabled
  ];

  // Soft color
  const softPrimary = [
    "bg-primary-soft text-primary", // Default
    "focus:bg-primary-soft-focus", // On Focus
    "[&:hover:not(:disabled)]:bg-primary-soft-hover", // On hover and not disabled
    "disabled:bg-primary-disabled disabled:text-on-surface-black-24", // Disabled
  ];

  const softSecondary = [
    "bg-secondary-soft text-on-surface-secondary", // Default
    "focus:bg-secondary-soft-focus", // On Focus
    "[&:hover:not(:disabled)]:bg-secondary-soft-hover", // On hover and not disabled
    "disabled:bg-secondary-disabled disabled:text-on-surface-black-24", // Disabled
  ];

  return (
    <button
      {...props}
      ref={ref}
      className={twMerge([
        generalStyles,
        size == "sm" && small,
        size == "lg" && large,
        variant === "primary" && primary,
        variant === "secondary" && secondary,
        variant === "outlinePrimary" && outlinePrimary,
        variant === "outlineSecondary" && outlineSecondary,
        variant === "softPrimary" && softPrimary,
        variant === "softSecondary" && softSecondary,
        className,
      ])}
    >
      {props.children}
    </button>
  )
})

IconButton.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.string,
  size: PropTypes.string,
}

export default IconButton