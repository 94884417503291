function Qpay(props) {
  const { invoiceInfo } = props;
  return (
    <div className="mt-8 text-center text-on-surface-black-64">
      <div>
        Та өөрийн ашигладаг интернет банкаар доорх QR кодыг уншуулан төлбөрөө төлөх боломжтой
      </div>
      <div className="flex justify-center my-4">
        <img src={`data:image/png;base64,${invoiceInfo?.payment?.qpay_qr_image}`} alt="qr" />
      </div>
      <div>
        Асууж, Лавлах зүйл байвал 7210-1010 дугаараас авна уу?
      </div>
    </div>
  )
}

export default Qpay;
