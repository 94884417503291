import Service from "./BaseService";

const getCities = (payload, config) => Service.get(`/dynamicInsurance/get-cities`, config);

const getSoums = (city_id, config) => Service.get(`/dynamicInsurance/get-soums/?city_id=${city_id}`, config);

const getFieldOfFilter = (info_name) => Service.get(`/dynamicInsurance/get-fields-of-filter-in-ioro?info_name=${info_name}`)

const getRelationInfoWithFilter = (payload, config) => Service.post(`/dynamicInsurance/get-insurance-object-relation-info-with-filter`, payload, config)

const getRelationInfo = (info_name, config) => Service.get(`/dynamicInsurance/get-insurance-object-relation-info?info_name=${info_name}`, config)

const getIrModelFields = (id, config) => Service.get(`/dynamicInsurance/get-ir-model-fields-selection?field_id=${id}`, config)

const InfoService = {
  getCities,
  getSoums,
  getFieldOfFilter,
  getRelationInfoWithFilter,
  getRelationInfo,
  getIrModelFields,
}

export default InfoService;