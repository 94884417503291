import Service from "./BaseService";

const login = (payload) => Service.post(`/auth/login`, payload);

const getOtpBySms = (mobile, config) =>
  Service.get(`/auth/get-otp-by-sms?mobile=${mobile}&recover=true`, {
    ...config,
    nonLoading: true,
  });

const checkOtp = (payload, config) =>
  Service.post(`/auth/check-otp`, payload, { ...config, nonLoading: true });

const resetPassword = (payload, config) =>
  Service.post(`/auth/reset-password`, payload, {
    ...config,
    nonLoading: true,
  });

const AuthService = {
  login,
  getOtpBySms,
  checkOtp,
  resetPassword,
};

export default AuthService;
