import { useCallback, useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { Controller, useFormContext } from "react-hook-form";
import { twMerge } from "tailwind-merge";
import queryString from "query-string";

import { useStore } from "../../../../stores";
import { formatNumber } from "../../../../utils/helper";
import { validation } from "../../../../utils/regex";

import Typography from "../../../../base-components/Typography";
import SwitchControl from "../../../../base-components/Switch";
import Select from "../../../../base-components/Select";
import Button from "../../../../base-components/Button";
import MuiInput from "../../../../base-components/MuiInput";
import Tabs from "../../../../base-components/Tabs";
import RadioButton from "../../../../base-components/RadioButton";
import Checkbox from "../../../../base-components/Checkbox";

import InfoService from "../../../../services/InfoService";
import PartnerInfoService from "../../../../services/PartnerInfoService";
import ObjectInfoService from "../../../../services/ObjectInfoService";
import MiisService from "../../../../services/MiisService";
import Notification from "../../../../base-components/Notification";
import { TextSkeleton } from "../../../../components/Skeleton/TextSkeleton";

const OwnerInfo = ({ currentStep, setCurrentStep, steps, stepConfig }) => {
  const {
    control,
    setValue,
    watch,
    getValues,
    trigger,
    formState: { isValid, errors },
    register,
    reset,
  } = useFormContext();
  const notifyRef = useRef(null);
  const [partnerFields, setPartnerFields] = useState([]); // Даатгуулагчийн мэдээлэл оруулах fields
  const user = useStore.useUser(); // Broker ажилтан мэдээлэл
  const prevPath = useStore.usePrevPath();
  const { id } = useParams(); // Dynamic бүтээгдэхүүн id
  const [hasForeignPassword, setHasForeignPassword] = useState(false); // Гадаад
  const notification = useStore.useNotification();
  const [stepChanged, setStepChanged] = useState(false);
  const updateUserInfo = useCallback(() => {
    if (prevPath !== "/insure") return;
    let values = getValues("insurance_partner");

    Object.keys(values).forEach((key) => {
      Object.keys(user).forEach((user_key) => {
        if (key === user_key) {
          if (user[key] === false) values[key] = "";
          else values[key] = user[user_key];
        }
      });
    });

    setValue("insurance_partner", values);
  }, [getValues, prevPath, setValue, user]);

  useEffect(() => {
    setHasForeignPassword(
      partnerFields.some((item) => item.name === "foreign_passport_number"),
    );
  }, [partnerFields]);

  const getInsurancePartner = useCallback(
    (value) => {
      let param = {
        is_company: value,
        dynamic_insurance_product_id: id,
      };
      PartnerInfoService.getInsurancePartner(queryString.stringify(param))
        .then((response) => {
          if (response.status === "Success") {
            setPartnerFields([...response.values]);
            response.values.forEach((field) => {
              let defaultValues = getValues();
              defaultValues.insurance_partner.is_company = value;
              if (field.ttype === "boolean")
                defaultValues.insurance_partner[field.name] = 0;
              else {
                if (field.name === "country_type")
                  defaultValues.insurance_partner[field.name] = "mongol";
                else defaultValues.insurance_partner[field.name] = "";
              }
              reset({ ...defaultValues });
            });
          }
        })
        .catch((error) => console.log(error))
        .finally(() => {
          updateUserInfo();
        });
    },
    [getValues, reset, updateUserInfo],
  );

  /**
   * РД ээс нас бодох function
   * @param register регистер
   * @returns нас
   */
  const calculateAge = (register) => {
    const month = parseInt(register.substring(4, 6));
    let year = parseInt(register.substring(2, 4));
    if (month > 20) {
      year += 2000;
    } else {
      year += 1900;
    }
    const currentYear = new Date().getFullYear();
    const age = currentYear - year;
    return age;
  };

  useEffect(() => {
    getInsurancePartner(0);
  }, [getInsurancePartner]);

  const handleChange = (checked) => {
    if (checked) setValue("insurance_partner.country_type", "foreign");
    else setValue("insurance_partner.country_type", "mongol");
  };
  const createInsurancePartner = () => {
    if (!isValid) return trigger("insurance_partner");
    let insurance_partner = getValues("insurance_partner");
    if (prevPath === "/insure") insurance_partner.mobile = user.mobile;
    Object.keys(insurance_partner).forEach((key) => {
      if (insurance_partner[key] !== "") {
        if (typeof insurance_partner[key] === "object") {
          insurance_partner[key] =
            insurance_partner[key].id || insurance_partner[key].value;
        }
      }
    });
    let payload = {
      insurance_partner: insurance_partner,
    };
    if (id.match("x_accident_medical")) {
      createInsurancePartnerMany();
    } else {
      PartnerInfoService.setInsurancePartner(payload)
        .then((response) => {
          if (response.status === "success") {
            setValue("insurance_partner.id", response.partner_id);
            if (id.match("x_accident_medical")) {
              // AYLALIIN DAATGAL UYD
              createInsuranceObject([response.res_partner_id]);
            } else {
              if (currentStep < steps.length - 1)
                if (stepConfig?.x_miis_policy_type_code !== false) {
                  // АЖД гэрээ бол миис гэрээ үүсгэнэ
                  createMiisPolicy();
                } else {
                  setCurrentStep(currentStep + 1);
                  stepChanged(true);
                }
            }
          }
          if (response.status === "error") {
            useStore.setState({
              notification: { message: response.message, type: "warning" },
            });
          }
        })
        .catch((error) => {
          useStore.setState({
            notification: {
              message: "Даатгуулагчийн мэдээлэл хадгалахад алдаа гарлаа.",
              type: "error",
            },
          });
        });
    }
  };

  // Miis гэрээ байгуулах function
  const createMiisPolicy = () => {
    let defaultValues = getValues();
    const payload = {
      objectId: defaultValues.object_ids[0]?.id,
      partnerId: defaultValues.insurance_partner.id,
      dynamicInsuranceProductId: id,
    };
    // хувь хүн бол зөвхөн дугаар авна байгууллага бол регистер авна
    MiisService.createMiisPolicy(payload)
      .then((response) => {
        if (response.status === "success") {
          setCurrentStep(currentStep + 1);
          setStepChanged(true);
          // gettingPrice(response?.miis_policy?.invoice_amount);
          if (response?.miis_policy?.invoice_amount) {
            setValue("extra_price", response?.miis_policy?.invoice_amount);
            setValue("base_payment", response?.miis_policy?.invoice_amount);
            setValue("amount", response?.miis_policy?.invoice_amount);
          }
          setValue("miis_policy_id", response?.miis_policy?.miis_policy_id);
        }
        if (response.status === "error") {
          useStore.setState({
            notification: {
              message: response?.message,
              type: "error",
            },
          });
        }
      })
      .catch((error) => {
        useStore.setState({
          notification: {
            message: "Миис гэрээ үүсгэхэд алдаа гарлаа.",
            type: "error",
          },
        });
      });
  };

  const createInsurancePartnerMany = () => {
    if (!isValid) return trigger("insurance_partner");
    let insurance_partner = getValues("insurance_partner");
    // Хэрэлэгчийн РД хүүхэд эсэхийг шалгах
    for (const key in insurance_partner) {
      if (
        insurance_partner.hasOwnProperty(key) &&
        key.includes("registry_number") &&
        key !== "registry_number"
      ) {
        if (calculateAge(insurance_partner[key]) > 18) {
          <Notification
            ref={notifyRef}
            message={"Нэмэлт даатгуулагч нь хүүхэд байх ёстой."}
            type={"warning"}
            title="Анхааруулга"
          />;
          return;
        }
      }
    }
    setValue(`insurance_partner_select`, getValues(`insurance_partner`));
    if (prevPath == "/insure") insurance_partner.mobile = user.mobile;
    Object.keys(insurance_partner).forEach((key) => {
      if (insurance_partner[key] === null) {
        delete insurance_partner[key];
      }
      if (insurance_partner[key] !== "") {
        if (typeof insurance_partner[key] === "object") {
          insurance_partner[key] =
            insurance_partner[key].id || insurance_partner[key].value;
        }
      }
    });
    let payload = {
      insurance_partner: insurance_partner,
      partner_count: 0,
      dynamic_insurance_product_name: id,
    };
    PartnerInfoService.setInsurancePartnerMany(payload)
      .then((response) => {
        if (response.status === "success") {
          setValue("insurance_partner.id", response.partner_id[0]);
          createInsuranceObject(response.res_partner_id);
          if (currentStep < steps.length - 1) setCurrentStep(currentStep + 1);
        }
        if (response.status === "error")
          useStore.setState({
            notification: { message: response.message, type: "warning" },
          });
      })
      .catch((error) => {
        <Notification
          ref={notifyRef}
          message={"Даатгуулагчийн мэдээлэл хадгалахад алдаа гарлаа."}
          type={"error"}
          title="Алдаа"
        />;
      });
  };

  const createInsuranceObject = (partner_ids) => {
    let body = {
      dynamic_insurance_product_id: id,
      objects: partner_ids,
    };
    ObjectInfoService.setInsuranceObjectsFromList(body).then((response) => {
      if (response.status === "success") {
        setValue("object_ids", response.object_ids);
        if (currentStep < steps.length - 1) setCurrentStep(currentStep + 1);
      }
    });
  };

  return (
    <div className="relative mt-10 rounded-2xl border bg-surface-gray p-14 max-[900px]:rounded-xl max-[841px]:px-6 max-[500px]:px-4">
      <Typography variant="Headline" size="sm">
        Даатгуулагчийн мэдээллээ оруулна уу?
      </Typography>
      <div className="mt-10 w-[700px] max-[900px]:w-full">
        <Controller
          control={control}
          name={`insurance_partner.is_company`}
          render={({ field: { value, onChange } }) => (
            <div className="relative my-8">
              <Tabs
                value={value}
                onChange={(value) => {
                  onChange(value);
                  getInsurancePartner(value);
                }}
              >
                <Tabs.Tab label="Хувь хүн" />
                <Tabs.Tab label="Байгууллага" />
              </Tabs>
              <div className="absolute bottom-0 w-full border-b border-stroke-dark-12"></div>
            </div>
          )}
        />

        {partnerFields.length === 0 ? (
          <TextSkeleton isLoading={true} textCount={7} />
        ) : (
          <div>
            {partnerFields?.find((x) => x.name === "country_type") && (
              <div className="flex w-fit items-center space-x-4 rounded-lg border bg-white p-3">
                <Typography variant="Label" size="lg" className="w-[240px]">
                  I’m a foreign citizen
                </Typography>
                <Controller
                  control={control}
                  name={`insurance_partner.country_type`}
                  render={({ field: { value, onChange } }) => (
                    <>
                      <SwitchControl
                        checked={value === "foreign"}
                        onChange={(e) => {
                          onChange(e);
                          handleChange(e);
                        }}
                      />
                    </>
                  )}
                />
              </div>
            )}
            <div className="mt-10 grid grid-cols-6 gap-4">
              {partnerFields
                ?.filter((x) => x.name !== "country_type")
                .map((field, index) => {
                  // Gadaad irgen bol rd, hot, duureg, horoonii medeelel avahgui
                  if (
                    watch("insurance_partner.country_type") === "foreign" &&
                    (field.name === "registry_number" ||
                      field.name === "lastname" ||
                      field.name === "email" ||
                      field.name === "state_id" ||
                      field.name === "district_id" ||
                      field.name === "khoroo_id")
                  ) {
                    return null;
                  }
                  return (
                    <div
                      key={index}
                      className="col-span-2 max-[841px]:col-span-3 max-[500px]:col-span-6"
                    >
                      {(field.ttype === "many2one" ||
                        field.ttype === "selection") && (
                        <SelectionField talbar={field} />
                      )}
                      {(field.ttype === "integer" ||
                        field.ttype === "char" ||
                        field.ttype === "text") && (
                        <InputField {...{ field }} />
                      )}
                    </div>
                  );
                })}
            </div>
          </div>
        )}

        {watch("insurance_partner.is_company") === 0 && !hasForeignPassword && (
          <>
            <div className="mt-10">
              <Typography variant="Title" size="md">
                Даатгуулах хөрөнгө зээлийн барьцаанд байгаа эсэх
              </Typography>
              <div className="mt-3 flex">
                <Controller
                  control={control}
                  name={`is_foreclosured`}
                  render={({ field: { value, onChange } }) => (
                    <RadioButton
                      id="yes"
                      label="Тийм"
                      name="foreclosured"
                      value="yes"
                      checked={value === "yes"}
                      onChange={(e) => onChange(e.target.value)}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name={`is_foreclosured`}
                  render={({ field: { value, onChange } }) => (
                    <RadioButton
                      id="no"
                      label="Үгүй"
                      name="foreclosured"
                      value="no"
                      checked={value === "no"}
                      onChange={(e) => onChange(e.target.value)}
                    />
                  )}
                />
              </div>
            </div>
            {watch("is_foreclosured") === "yes" && (
              <div className="-mx-14 mt-10 border-t px-14 py-8">
                <Typography variant="Headline" size="sm">
                  Барьцаалагчийн мэдээлэл
                </Typography>
                <div className="mt-4 grid grid-cols-2 gap-4">
                  <div>
                    <MuiInput
                      label="Байгууллагын нэр"
                      {...register("mortgagor_name", {
                        required: true,
                      })}
                    />
                    {errors?.mortgagor_name?.type === "required" && (
                      <p className="mt-1 pl-2 text-xs text-error">{`Байгууллагын нэрээ оруулна уу`}</p>
                    )}
                  </div>
                </div>
              </div>
            )}
          </>
        )}
        {(watch("insurance_partner.id")?.length === 0 ||
          !watch("insurance_partner.id") ||
          !stepChanged) && (
          <Button
            type="button"
            onClick={createInsurancePartner}
            className={twMerge([
              "mt-8",
              watch("is_foreclosured") === "no" &&
                watch("insurance_partner.is_company") === 0 &&
                !hasForeignPassword &&
                "absolute bottom-14 right-14 mt-0",
            ])}
          >
            Үргэлжлүүлэх
          </Button>
        )}
      </div>
    </div>
  );
};

const InputField = ({ field, ...props }) => {
  const {
    register,
    formState: { errors },
    setValue,
    getValues,
  } = useFormContext();

  return (
    <>
      <MuiInput
        {...props}
        id={field.name}
        label={field?.description}
        className="w-full"
        {...register(`insurance_partner.${field.name}`, {
          required: field.required,
          pattern: validation(field.name, getValues("insurance_partner")),
          onChange: (e) => {
            if (field.ttype === "integer")
              setValue(
                `insurance_partner.${field.name}`,
                formatNumber(e.target.value, 0),
              );
            if (
              field.name === "registry_number" &&
              /[а-яёөү]/.test(e.target.value.charAt(0 || 1))
            )
              setValue(
                `insurance_partner.registry_number`,
                e.target.value.toUpperCase(),
              );
            if (
              field.name === "lastname" &&
              /[а-яёөүa-z]/.test(e.target.value.charAt(0))
            )
              setValue(
                `insurance_partner.lastname`,
                e.target.value.toUpperCase(),
              );
            if (
              field.name === "name" &&
              /[а-яёөүa-z]/.test(e.target.value.charAt(0))
            )
              setValue(`insurance_partner.name`, e.target.value.toUpperCase());
          },
        })}
      />
      {errors?.insurance_partner &&
        errors?.insurance_partner[field?.name]?.type === "required" && (
          <p className="mt-1 pl-2 text-xs text-error">{`${field.description} оруулна уу`}</p>
        )}
      {errors?.insurance_partner &&
        errors?.insurance_partner[field?.name]?.type === "pattern" && (
          <p className="mt-1 pl-2 text-xs text-error">{`${field.description} буруу байна. Шалгана уу!`}</p>
        )}
    </>
  );
};

const SelectionField = ({ talbar }) => {
  const {
    control,
    formState: { errors },
    getValues,
  } = useFormContext();
  const params = {
    info_name: talbar.relation,
    filter: {},
    limit: 10,
    page: 1,
    search_value: "",
  };
  const [relations, setRelations] = useState([]);

  useEffect(() => {
    if (talbar.relation === "") return;
    InfoService.getFieldOfFilter(talbar.relation)
      .then((response) => {
        if (response.status === "Success") {
          setRelations([...response.values]);
        }
      })
      .catch((err) => console.log(err));
  }, [talbar.relation]);

  return (
    <Controller
      control={control}
      name={`insurance_partner.${talbar.name}`}
      render={({ field: { value, onChange } }) => (
        <>
          <Select
            id={`selection_${talbar.name}`}
            label={talbar.description}
            value={value}
            onChange={(value) => {
              onChange(value);
            }}
            icon={talbar.ttype === "many2many" ? <Checkbox /> : undefined}
            relations={talbar.relation === params.info_name && relations}
            relationName="insurance_partner"
            getValues={getValues}
            service={
              talbar.relation === ""
                ? InfoService.getIrModelFields
                : InfoService.getRelationInfoWithFilter
            }
            params={talbar.relation === "" ? talbar.id : params}
          />
          {errors?.insurance_partner &&
            errors?.insurance_partner[talbar?.name]?.type === "required" && (
              <p className="mt-1 pl-2 text-xs text-error">{`${talbar.description} сонгоно уу`}</p>
            )}
        </>
      )}
      rules={{ required: talbar.required }}
    />
  );
};

export default OwnerInfo;
