import Service from "./BaseService";

const getMzField = (id) =>
  Service.get(
    `/dynamicInsurance/get-mz-fields?dynamic_insurance_product_id=${id}`,
  );

const getPackages = (param, config) =>
  Service.get(`/dynamicInsurance/get-packages?${param}`, {
    ...config,
    nonLoading: true,
  });

const getPerils = (param, config) =>
  Service.get(`dynamicInsurance/get-perils?${param}`, {
    ...config,
    nonLoading: true,
  });

const getExtraService = (param, config) =>
  Service.get(`dynamicInsurance/get-extra-services?${param}`, {
    ...config,
    nonLoading: true,
  });

const getDurations = (id) =>
  Service.get(
    `/dynamicInsurance/get-durations?dynamic_insurance_product_id=${id}`,
  );

const getCountryTranslate = () =>
  Service.get(`/dynamicInsurance/get-country-translate`);

const getSinoAsiaCovidOption = (id, tableNameId) =>
  Service.get(
    `/dynamicInsurance/get-sino-asia-covid-options?package_id=${id}&table_name_id=${tableNameId}`,
  );

const DynamicFieldService = {
  getMzField,
  getPackages,
  getPerils,
  getExtraService,
  getDurations,
  getCountryTranslate,
  getSinoAsiaCovidOption,
};

export default DynamicFieldService;
