import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useStore } from "../../../stores";
import Card from "../../../components/Card";
import Typography from "../../../base-components/Typography";
import { ReactComponent as FileText } from "../../../assets/icon/file-text.svg";
import { ReactComponent as PlusCircle } from "../../../assets/icon/plus-circle.svg";
import { ReactComponent as Plus } from "../../../assets/icon/plus.svg";
import { ReactComponent as Cross } from "../../../assets/icon/cross.svg";
import Steps from "../../../base-components/Steps";
import Button from "../../../base-components/Button";
import IconButton from "../../../base-components/IconButton";
import AlertModal from "../../../base-components/AlertModal";
import IndemnityService from "../../../services/IndemnityService";

const steps = [
  { title: "Материал цуглуулах", },
  { title: "Хянагдаж байна/Зөвшөөрөөгүй", },
  { title: "Төлбөр төлөхөөр хүлээгдэж байна", },
  { title: "Төлбөр төлөгдсөн", },
]

const SendMaterial = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [images, setImages] = useState(['']);
  const [files, setFiles] = useState([{ file: null, url: '' }])
  const [isShowModal, setIsShowModal] = useState(false);

  const navigate = useNavigate();
  const { state } = useLocation();

  const imageMimeType = /image\/(png|jpg|jpeg|)/i;

  useEffect(() => {
    let index = steps.findIndex((x) => x.title === state?.claim_status)
    setCurrentStep(index + 1);
  }, [])

  const uploadFile = (e, index) => {
    const file = e.target.files[0];
    files[index].file = file
    // if (!file.type.match(imageMimeType)) {
    //   alert("Зургийн өргөтгөлтэй файл оруулна уу./png, jpg, jpeg/");
    //   return;
    // }
    let fileReader, isCancel = false;
    if (file) {
      fileReader = new FileReader();

      fileReader.onload = (e) => {
        const { result } = e.target;
        if (result && !isCancel) {
          files[index].url = result
          setFiles([...files])
        }
      }
      fileReader.readAsDataURL(file);
    }
  }
  const addFile = () => {
    let list = files;
    list.push({ file: null, url: '' })
    setFiles([...list])
  }
  const saveFile = () => {
    const formData = new FormData();

    formData.append('policy_id', state.policy_id);
    files.filter((x) => x.file).forEach(data => {
      formData.append('files', data.file);
    });

    const config = {
      headers: {
        'Content-Type': `multipart/form-data`,
      }
    }
    IndemnityService.uploadFile(formData, config)
      .then((response) => {
        if (response.status === "Success") {
          setIsShowModal(true);
        }
        if (response.status === "error") {
          useStore.setState({ notification: { message: response.message, type: 'warning' } })
          return;
        }
      })
  }

  return (
    <>
      <div className="relative bg-surface-gray p-8 flex justify-between items-center border-bottom border-stroke-dark-12 max-[780px]:grid">
        <div className="mx-6 max-[780px]:flex items-center justify-between max-[780px]:mb-8">
          <img
            src="/logos/horizontal-primary.png"
            alt="logo"
            onClick={() => {
              currentStep !== 3 &&
                navigate("/dashboard")
            }}
            className="w-40"
          />
        </div>
      </div>
      <div className="flex justify-center mt-10">
        <div className="max-[500px]:w-full p-6">
          <Card size="md" className="w-fit border-secondary space-y-4 p-4 max-[500px]:w-auto overflow-auto">
            <div className="flex items-center space-x-3 py-2 max-[500px]:w-max">
              <FileText stroke="#69798C" />
              <Typography variant="Title" className="text-on-surface-black-64">
                Нөхөн төлбөрийн шийдвэрлэлтийн явц
              </Typography>
            </div>
            <Steps
              current={currentStep}
              items={steps}
              className="space-x-8"
            />
          </Card>
          {
            state?.claim_status === "Материал цуглуулах" &&
            <>
              <div className="mt-14">
                <Typography variant="Title" className="w-96">
                  Та ажилтны өгсөн зааврын дагуу материалуудыг оруулна уу.
                </Typography>
              </div>
              {
                files.map((data, index) =>
                  <div key={index} className="mt-10">
                    <label htmlFor={`nuhun-tulbur-${index}`}>
                      <div id="img-wrapper" className="border border-dashed border-primary rounded-xl p-4 h-64 relative w-full flex justify-center items-center cursor-pointer bg-white">
                        {
                          data?.url.length > 0 ?
                            <>
                              <div className="absolute inset-0 flex items-center justify-center">
                                {
                                  data.file.type.match(imageMimeType) ?
                                    <img src={data.url} alt="image upload" className={`w-max max-h-[14.8rem]`} />
                                    :
                                    <div className="text-center">
                                      <div className="flex justify-center">
                                        {
                                          data.file.type.match(/^.*\.(pdf)$/i) ?
                                            <img src="/files/pdf.png" alt="pdf file" className={`w-max h-32`} /> :
                                            data.file.type.match(/^.*\.(xlsx)$/i) &&
                                            <img src="/files/excel.png" alt="excel file" className={`w-max h-32`} />
                                        }
                                      </div>
                                      <Typography className="mt-4 w-max max-w-lg">
                                        {data.file.name}
                                      </Typography>
                                    </div>
                                }
                              </div>
                              <IconButton
                                onClick={(e) => {
                                  e.preventDefault();
                                  files[index] = { file: null, url: "" }
                                  setImages([...images])
                                }}
                                className="absolute right-2 top-2"
                              >
                                <Cross fill="#0A213A" />
                              </IconButton>
                            </>
                            :
                            <div className="px-6 w-full">
                              <div className="text-secondary flex justify-center">
                                <PlusCircle fill="#BBC1C9" className="w-10 h-10" />
                              </div>
                              <div className="text-center space-y-1 -mb-8 p-4">
                                <Typography variant="Title" size="md">
                                  Материалуудаа оруулна уу?
                                </Typography>
                                <Typography size="md" className="text-on-surface-black-64">
                                  JPG, PNG, PDF, MP4 - файлын дээд хэмжээ (15 mb)
                                </Typography>
                              </div>
                            </div>
                        }
                      </div>
                    </label>
                    <input
                      id={`nuhun-tulbur-${index}`}
                      type="file"
                      accept="image/*, .pdf, .mp4, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
                              application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint"
                      className="hidden"
                      onChange={(e) => {
                        // onChange(e);
                        uploadFile(e, index)
                      }}
                    />
                  </div>
                )
              }
              <Button
                variant="softPrimary"
                icon={<Plus stroke="#0A213A" className="w-[18px] h-[18px]" />}
                onClick={addFile}
                className="mt-6 w-full"
              >
                Файл нэмэх
              </Button>
              <div className="flex justify-end">
                <Button
                  disabled={!files.find((x) => x.file)}
                  className="mt-10 text-end"
                  onClick={saveFile}
                >
                  Илгээх
                </Button>
              </div>
            </>
          }
        </div>
      </div>
      <AlertModal
        isOpen={isShowModal}
        size="lg"
        type="success"
        title="Таны материалууд амжилттай илгээгдлээ"
        closeButton={true}
        onClose={() => {
          setIsShowModal(false);
          navigate('/dashboard');
        }}
      />
    </>
  )
}

export default SendMaterial;
