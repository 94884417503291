import React, { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";

import { useStore } from "../../stores";

import Button from "../../base-components/Button";
import AlertModal from "../../base-components/AlertModal";
import IconButton from "../../base-components/IconButton";

import { ReactComponent as ArrowLeft } from "../../assets/icon/arrow-left.svg";

import Otp from "./otp";
import Mobile from "./mobile";
import NewPassword from "./new-password";

import Typography from "../../base-components/Typography";
import AuthService from "../../services/AuthService";

function RecoverPassword() {
  const methods = useForm({
    defaultValues: {
      mobile: "",
      otp: "",
      new_pass: "",
      new_pass2: "",
    },
  });
  const [mobile, setMobile] = useState("");
  const [isShowAlert, setIsShowAlert] = useState(false);
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const setToken = useStore.useSetToken();
  const navigate = useNavigate();
  const { state } = useLocation();

  const onSubmit = (data) => {
    setLoading(true);
    AuthService.resetPassword(data)
      .then((response) => {
        if (response.status === "success") {
          setMessage(response.message);
          setToken(response.token);
          setIsShowAlert(true);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error, "error");
      });
    setIsShowAlert(true);
  };

  return (
    <FormProvider {...methods}>
      <div className="mb-8 flex">
        <IconButton
          size="sm"
          variant="softPrimary"
          onClick={() => navigate("/login")}
        >
          <ArrowLeft className="h-5 w-5" />
        </IconButton>
      </div>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <div className="w-[333px] max-[500px]:w-full">
          {step !== 3 && (
            <Typography variant="Headline" size="md" className="mb-2">
              Нууц үг сэргээх
            </Typography>
          )}
          {step === 1 && <Mobile {...{ mobile, setMobile, step, setStep }} />}
          {step === 2 && <Otp {...{ step, setStep }} />}
          {step === 3 && (
            <>
              <NewPassword {...{ loading }} />
              <div className="flex justify-end">
                <Button type="submit" className="w-1/2">
                  Болсон
                </Button>
              </div>
            </>
          )}
        </div>
      </form>
      <AlertModal
        isOpen={isShowAlert}
        type="success"
        title="Амжилттай!"
        message={message}
        footer={
          <Button onClick={() => navigate(state.prevPath)}>Ойлголоо</Button>
        }
      />
    </FormProvider>
  );
}

export default RecoverPassword;
