import Service from "./BaseService";

// Дош тайлан жагсаалт авах request
const getDoshList = (payload, config) =>
  Service.post(`/dynamicInsurance/get-dosh-list`, payload, config);

const getDoshDetails = (payload, config) =>
  Service.post(`/dynamicInsurance/get-dosh-details`, payload, config);

const DoshService = {
  getDoshList,
  getDoshDetails,
};

export default DoshService;
