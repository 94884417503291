import { useState } from "react";
import { twMerge } from "tailwind-merge";
import dayjs from "dayjs";
import Typography from "../Typography";
import { ReactComponent as ChevronLeft } from "../../assets/icon/chevron-left.svg";
import { ReactComponent as ChevronRight } from "../../assets/icon/chevron-right.svg";
import { months } from "./util";

const CalendarMonthYear = ({
  value = dayjs(),
  minDate,
  maxDate,
  onChange,
  ...props
}) => {
  const currentDate = dayjs();
  const [selectedYear, setSelectedYear] = useState(dayjs().year());

  const prevNextClick = (e, direction) => {
    e.preventDefault();
    if (direction === "prev") setSelectedYear(selectedYear - 1);
    else setSelectedYear(selectedYear + 1);
  };

  const onClickMonth = (index) => {
    const selectedMonth = dayjs(new Date(selectedYear, index));
    if (
      (minDate && selectedMonth.isBefore(minDate, "month")) ||
      (maxDate && selectedMonth.isAfter(maxDate, "month"))
    ) {
      return;
    }
    onChange(selectedMonth);
  };

  return (
    <div className="min-w-[298px] rounded-2xl border bg-white p-2">
      <header className="flex items-center justify-between py-2">
        <button
          className="cursor-pointer rounded-full p-2 outline-none hover:bg-primary-soft"
          onClick={(e) => prevNextClick(e, "prev")}
        >
          <ChevronLeft className="h-6 w-6" />
        </button>

        <Typography
          id="currentDate"
          variant="Title"
          className="flex items-center space-x-1"
        >
          {selectedYear}
        </Typography>

        <button
          className="cursor-pointer rounded-full p-2 outline-none hover:bg-primary-soft"
          onClick={(e) => prevNextClick(e, "next")}
        >
          <ChevronRight className="h-6 w-6" />
        </button>
      </header>

      {/* Month Picker */}
      <div className="grid grid-cols-3 gap-2 text-primary">
        {months.map((month, index) => (
          <div
            key={index}
            className={twMerge([
              "grid cursor-pointer place-content-center rounded-lg p-2",
              value.month() === index && value.year() === selectedYear
                ? "bg-primary text-white"
                : index === currentDate.month() &&
                  selectedYear === currentDate.year()
                ? "text-[#a2abb4]"
                : "hover:bg-primary-soft",
            ])}
            onClick={() => onClickMonth(index)}
          >
            {month}
          </div>
        ))}
      </div>
    </div>
  );
};

export default CalendarMonthYear;
