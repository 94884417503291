import { Outlet } from "react-router-dom";

const Wrapper = () => {

  return (
    <>
      <Outlet />
    </>
  )
}

export default Wrapper;