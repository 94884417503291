export const validation = (name, insurance_partner) => {
  let regex;
  if (insurance_partner?.country_type === 'foreign')
    switch (name) {
      case 'lastname':
      case 'name':
        regex = new RegExp(/^[A-Z]+$/i)
        break;
      case 'company_registry_number':
        regex = new RegExp(/^[0-9]{7}$/)
        break;
      default:
        regex = undefined
        break;
    }
  else
    switch (name) {
      case 'lastname':
      case 'name':
        if (insurance_partner?.is_company === 0)
          regex = new RegExp(/^[А-ЯЁӨҮ-]+$/i)
        break;
      case 'registry_number':
        regex = new RegExp(/^([А-ЯЁӨҮ]{2})([0-9]{8})$/)
        break;
      case 'mobile':
        regex = new RegExp(/^[7-9]{1}[0-9]{7}$/)
        break;
      case 'email':
        regex = new RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)
        break;
      case 'company_registry_number':
        regex = new RegExp(/^[0-9]{7}$/)
        break;
      default:
        regex = undefined
        break;
    }
  return regex;
}