import * as React from 'react';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import PropTypes from "prop-types";

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "#0A213A",
    // opacity: ".08"
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#0A213A",
    opacity: ".08"
  },
}));

export default function CustomizedTooltip({ title = "", placement, ...props }) {
  return (
    <div>
      <CustomTooltip
        {...props}
        title={title}
        placement={placement}
      >
        {props.children}
      </CustomTooltip>
    </div>
  );
}

CustomizedTooltip.propTypes = {
  title: PropTypes.string,
  placement: PropTypes.string,
  children: PropTypes.element,
}