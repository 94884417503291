import { useState } from "react";
import { twMerge } from "tailwind-merge";
import dayjs from "dayjs";
import Typography from "../Typography";
import { ReactComponent as ChevronLeft } from "../../assets/icon/chevron-left.svg";
import { ReactComponent as ChevronRight } from "../../assets/icon/chevron-right.svg";
import { ReactComponent as ChevronDownIcon } from "../../assets/icon/chevron-down-1.svg";
import { generateDate, getYears, months } from "./util";

const Calendar = ({ value = dayjs(), minDate, maxDate, ...props }) => {
  const week = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
  const currentDate = dayjs();
  const [selectDate, setSelectDate] = useState(value || currentDate);
  const [unit, setUnit] = useState('day');
  const [selectedYear, setSelectedYear] = useState(dayjs().year());
  const [filterDate, setFilterDate] = useState([2020, 2040]);

  const prevNextClick = (e, id) => {
    e.preventDefault();
    if (unit === "year") {
      let tempDate = [];
      filterDate.forEach((date) => {
        if (id === "prev")
          date -= 20;
        else
          date += 20;
        tempDate.push(date);
      })
      setFilterDate([...tempDate]);
    }
    else if (unit === "month") {
      if (id === "prev")
        setSelectedYear(selectedYear - 1);
      else
        setSelectedYear(selectedYear + 1);
    }
    else {
      if (id === "prev")
        setSelectDate(selectDate.month(selectDate.month() - 1));
      else
        setSelectDate(selectDate.month(selectDate.month() + 1));
    }
  }
  const onChangeDate = (date) => {
    if (minDate?.toDate().getTime() > date.add(1, 'day').toDate().getTime() || maxDate?.toDate().getTime() < date.toDate().getTime()) return;
    setSelectDate(date);
    props.onChange(date)
  }
  const onClickYear = (date) => {
    setSelectedYear(date.year);
    setUnit("month");
  }
  const onClickMonth = (index) => {
    let d = dayjs(new Date(selectedYear, index));
    setSelectDate(d);
    setUnit('day');
  }

  return (
    <div className="bg-white rounded-2xl p-2 border min-w-[298px]">
      <header className="flex items-center justify-between py-2">
        <button
          className="cursor-pointer rounded-full p-2 hover:bg-primary-soft outline-none"
          onClick={(e) => prevNextClick(e, "prev")}
        >
          <ChevronLeft className="w-6 h-6" />
        </button>

        {
          unit === "year" &&
          <div className="flex items-center space-x-1" onClick={() => setUnit('month')}> {/* Жил */}
            <Typography id="currentDate" variant="Title">
              {filterDate[0]} - {filterDate[1]}
            </Typography>
            <ChevronDownIcon className='rotate-180' />
          </div>
        }
        {
          unit === "month" &&
          <div className="flex items-center space-x-1" onClick={() => setUnit('year')}> {/* Сар */}
            <Typography id="currentDate" variant="Title">
              {selectedYear}
            </Typography>
          </div>
        }
        {
          unit === "day" &&
          <div className="flex items-center space-x-1" onClick={() => setUnit('year')}> {/* Өдөр */}
            <Typography id="currentDate" variant="Title">
              {months[selectDate.month()]} {selectDate.year()}
            </Typography>
            <ChevronDownIcon />
          </div>
        }
        <button
          className="cursor-pointer rounded-full p-2 hover:bg-primary-soft outline-none"
          onClick={(e) => prevNextClick(e, "next")}
        >
          <ChevronRight className="w-6 h-6" />
        </button>
      </header>
      {
        unit === "year" &&
        <div className="grid grid-cols-4 gap-1 h-52 overflow-auto text-sm text-primary">
          {
            getYears(filterDate).map((date, index) =>
              <div
                key={index}
                className={twMerge([
                  "text-center p-2 rounded-full hover:bg-primary-disabled",
                  date.year === selectedYear && "bg-primary text-white hover:bg-primary",
                  date.currentYear && "text-[#a2abb4]",
                ])}
                onClick={() => onClickYear(date)}
              >
                {date.year}
              </div>
            )
          }
        </div>
      }
      {
        unit === "month" &&
        <div className="text-primary grid grid-cols-3 gap-1">
          {
            months.map((month, index) =>
              <div
                key={index}
                className={
                  twMerge(["grid place-content-center p-2",
                    index === dayjs().month() && "text-[#a2abb4]"
                  ])}
                onClick={() => onClickMonth(index)}
              >
                {month}
              </div>
            )
          }
        </div>
      }
      {
        unit === "day" &&
        <div className="text-primary">
          <div className="w-full grid grid-cols-7 font-medium text-xs text-on-surface-black-64">
            {
              week.map((day, index) =>
                <div
                  key={index}
                  className={twMerge([
                    "grid place-content-center",
                  ])}>
                  <h1 className={twMerge([
                    "h-10 w-10 grid place-content-center rounded-full hover:bg-primary-disabled",
                    selectDate.toDate().toDateString() === dayjs().toDate().toDateString() && index === selectDate.day() && "bg-primary text-white hover:bg-primary"
                  ])}>
                    {day.substring(0, 2)}
                  </h1>
                </div>
              )
            }
          </div>
          <div className="grid grid-cols-7 w-full">
            {
              generateDate(selectDate.month(), selectDate.year()).map(({ date, currentMonth, today }, index) =>
                <div
                  key={index}
                  className={twMerge([
                    "cursor-pointer grid place-content-center",
                  ])}>
                  <h1
                    aria-disabled={
                      (minDate?.toDate().getTime() > date.add(1, 'day').toDate().getTime()
                        || maxDate?.toDate().getTime() < date.toDate().getTime()) ? true : false
                    }
                    onClick={() => onChangeDate(date)}
                    className={twMerge([
                      "h-10 w-10 grid place-content-center rounded-full text-sm hover:bg-primary-soft",
                      currentMonth ? "hover:bg-primary-soft" : "text-primary-soft-focus",
                      today ? "text-[#a2abb4]" : "",
                      (!today && currentMonth &&
                        selectDate.toDate().toDateString() === date.toDate().toDateString()) &&
                      "bg-primary-disabled text-primary aria-disabled:bg-white",
                      "aria-disabled:text-primary-disabled aria-disabled:hover:bg-white aria-disabled:cursor-not-allowed"
                    ])}
                  >
                    {date.date()}
                  </h1>
                </div>
              )
            }
          </div>
        </div>
      }
    </div>
  )
}

export default Calendar;