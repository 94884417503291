function Qpay({qr_image}) {
  return (
    <div className="mt-8 text-center text-on-surface-black-64">
      <div>
        Та өөрийн ашигладаг интернет банкаар доорх QR кодыг уншуулан төлбөрөө
        төлөх боломжтой
      </div>
      <div className="my-4 flex justify-center">
        <img src={`data:image/png;base64,${qr_image}`} alt="qr" />
      </div>
      <div>Асууж, Лавлах зүйл байвал 7210-1010 дугаараас авна уу?</div>
    </div>
  );
}

export default Qpay;
