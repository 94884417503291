import Button from "../../../../../base-components/Button";
import { ReactComponent as Edit } from "../../../../../assets/icon/edit.svg";

const Ebarimt = () => {

  const copyText = (id) => {
    let value = document.getElementById(id).childNodes[0].textContent
    navigator.clipboard.writeText(value);
  }

  return (
    <div className="p-4">
      <div className="text-center mt-6">Та доорх QR кодыг уншуулан Е-баримтад бүртгүүлэх боломжтой.</div>
      <div className="flex py-8">
        <div className="w-1/2">
          <img src="/ebarimt.png" alt="ebarimt" />
        </div>
        <div className="w-1/2 py-6">
          <div className="flex justify-between pb-5">
            <div className="mr-4">
              <div className="text-xs text-[#636C73] leading-[18px]">
                ДДТД
              </div>
              <div id="ddtd" className="text-[#212529] text-xs break-all">000006460232039230204468129001416</div>
            </div>
            <Button className="bg-primary-soft !text-primary" onClick={() => copyText('ddtd')}>Хуулах</Button>
          </div>
          <div className="flex justify-between pb-5">
            <div className="mr-4">
              <div className="text-xs text-[#636C73] leading-[18px]">
                Сугалааны дугаар
              </div>
              <div id="sugalaanii-dugaar" className="text-[#212529] text-sm break-all">JG24851705</div>
            </div>
            <Button className="bg-primary-soft !text-primary" onClick={() => copyText('sugalaanii-dugaar')}>Хуулах</Button>
          </div>
          <div className="flex justify-between">
            <div className="mr-4">
              <div className="text-xs text-[#636C73] leading-[18px]">
                Бүртгүүлэх дүн
              </div>
              <div id="burtguulekh-dun" className="text-[#212529] text-sm break-all">33’000₮</div>
            </div>
            <Button className="bg-primary-soft !text-primary" onClick={() => copyText('burtguulekh-dun')}>Хуулах</Button>
          </div>
        </div>
      </div>
      <div className="flex justify-between items-center">
        <div className="">
          <div className="text-xs text-[#636C73]">Огноо</div>
          <div>2023/03/04</div>
        </div>
        <Button
          icon={<Edit />}
          className="bg-white text-black border border-stroke-dark-12 rounded-xl"
        >
          Имэйлээр авах
        </Button>
      </div>
      <div className="text-[#636C73] text-center mt-8">Асууж, Лавлах зүйл байвал 72101010 дугаараас авна уу?</div>
    </div>
  )
}

export default Ebarimt;
