import Service from "./BaseService";

const getVehicleInfo = (id, params, config) =>
  Service.get(
    `/dynamicInsurance/get-vehicle-info-from-xyp?dynamic_product_name=${id}&${params}`,
    { ...config, nonLoading: true },
  );

const XypService = {
  getVehicleInfo,
};

export default XypService;
