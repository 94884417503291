import { Switch } from "@headlessui/react";
import { twMerge } from "tailwind-merge";

const SwitchControl = (props) => {
  const { checked = false } = props;

  return (
    <div className="w-[34px]">
      <Switch
        {...props}
        checked={checked}
        // onChange={setEnabled}
        className={twMerge([
          checked
            ? "border-primary bg-primary"
            : "border-on-surface-black-24 bg-on-surface-black-24",
          "relative inline-flex h-5 w-[34px] items-center rounded-full border",
          "border-surface-gray disabled:bg-surface-gray",
        ])}
      >
        <span className="sr-only">Enable notifications</span>
        <span
          className={twMerge([
            checked ? "translate-x-[15px]" : "translate-x-[1px]",
            "inline-block h-4 w-4 transform rounded-full bg-white transition",
            "disabled:bg-on-surface-white-64",
          ])}
        />
      </Switch>
    </div>
  );
};

export default SwitchControl;
