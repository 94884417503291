import Service from "./BaseService";

const getPrice = (payload, config = {}) =>
  Service.postProduct(`/odoo/get-price-in-dynamic-product`, payload, config);

const PriceService = {
  getPrice,
};

export default PriceService;
