import { useEffect, useState } from "react";
import Button from "../../../../base-components/Button";
import Modal from "../../../../base-components/Modal";
import Payment from "../payment";

function StepThree(props) {
  const [isOpenModal, setIsOpenModal] = useState(false);

  useEffect(() => {
    setIsOpenModal(true);
  }, [])

  return (
    <div className="p-7 w-2/6">
      <Modal
        {...{ isOpenModal }}
        onClose={() => setIsOpenModal(false)}
      >
        <Payment {...props} />
      </Modal>
    </div>
  )
}

export default StepThree;