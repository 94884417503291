import { useStateMachine } from "little-state-machine";
import { ReactComponent as ChevronDown } from "../../../../../assets/icon/chevron-down-1.svg";
import { ReactComponent as Copy } from "../../../../../assets/icon/copy.svg";
import Button from "../../../../../base-components/Button";
import updateAction from "../../../../updateAction";
import { formatNumber } from "../../../../../utils/helper";

function BankAccount(props) {
  const { invoiceInfo } = props;
  const { state } = useStateMachine(updateAction);

  const copyText = (id) => {
    let value = document.getElementById(id).childNodes[0].textContent;
    navigator.clipboard.writeText(value);
  };

  return (
    <div className="text-primary">
      <div className="relative mt-4 flex cursor-pointer items-center justify-between rounded-lg border border-stroke-dark-12 p-2 px-3">
        <div className="flex items-center">
          <img src="/bankLogo/khan.png" alt="khanbank" />
          <div className="ml-4">
            <label className="absolute top-0 text-xs text-on-surface-black-64">
              Банк сонгох
            </label>
            <div className="-mb-2.5 text-sm font-semibold">Хаан банк</div>
          </div>
        </div>
        <ChevronDown className="h-5 w-5 text-primary" aria-hidden="true" />
      </div>
      <div className="mt-4 flex items-center justify-between">
        <div>
          <label className="text-xs text-on-surface-black-64">
            Дансны дугаар
          </label>
          <div id="bank-account" className="text-sm font-semibold">
            5107053467
          </div>
        </div>
        <div className="mt-4 flex justify-end">
          <Button
            variant="softPrimary"
            onClick={() => copyText("bank-account")}
            icon={<Copy className="mr-3 h-4 w-4" />}
          >
            Хуулах
          </Button>
        </div>
      </div>
      <div className="flex items-center justify-between">
        <div>
          <label className="text-xs text-on-surface-black-64">
            Хүлээн авагч
          </label>
          <div id="khuleen-avagch" className="text-sm font-semibold">
            Agula
          </div>
        </div>
        <div className="mt-4 flex justify-end">
          <Button
            variant="softPrimary"
            onClick={() => copyText("khuleen-avagch")}
            icon={<Copy className="mr-3 h-4 w-4" />}
          >
            Хуулах
          </Button>
        </div>
      </div>
      <div className="flex items-center justify-between">
        <div>
          <label className="text-xs text-on-surface-black-64">
            Гүйлгээний утга
          </label>
          <div id="guilgeenii-utga" className="text-sm font-semibold">
            {state.sequence}
          </div>
        </div>
        <div className="mt-4 flex justify-end">
          <Button
            variant="softPrimary"
            onClick={() => copyText("guilgeenii-utga")}
            icon={<Copy className="mr-3 h-4 w-4" />}
          >
            Хуулах
          </Button>
        </div>
      </div>
      <div className="flex items-center justify-between">
        <div>
          <label className="text-xs text-on-surface-black-64">
            Нийт үнийн дүн
          </label>
          <div id="niit-dun" className="text-sm font-semibold">
            {formatNumber(props?.amount)}₮
          </div>
        </div>
        <div className="mt-4 flex justify-end">
          <Button
            variant="softPrimary"
            onClick={() => copyText("niit-dun")}
            icon={<Copy className="mr-3 h-4 w-4" />}
          >
            Хуулах
          </Button>
        </div>
      </div>
      <div className="mt-6 font-bold">Санамж</div>
      <div className="mt-4 text-sm text-on-surface-black-64">
        Таны захиалга төлбөр төлөгдсөний дараа баталгаажих болно. Та аль ч
        банкны салбар болон интернет банкны аппликэйшн ашиглан төлбөрөө төлөх
        боломжтой.
        <br />
        <br />
        Та гүйлгээний утга буруу оруулсан тохиолдолд таны даатгал идэвхжихгүйг
        анхаарна уу.
        <br />
        <br />
        Захиалгын дэлгэрэнгүй мэдээлэл таны цахим хаяг болон утсанд давхар
        илгээгдсэн болно. Лавлах утас: 7210-1010
      </div>
    </div>
  );
}

export default BankAccount;
