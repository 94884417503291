import React, { useEffect, useRef, useState } from "react";
import { useFormContext } from "react-hook-form";
import { PulseLoader } from "react-spinners";
import { twMerge } from "tailwind-merge";

import Input from "../../../../base-components/Input";
import Typography from "../../../../base-components/Typography";
import Button from "../../../../base-components/Button";

import { ReactComponent as Replay } from "../../../../assets/icon/replay.svg";

import RegisterService from "../../../../services/RegisterService";

let currentOtpIndex = 0;
const Otp = ({ step, setStep }) => {
  const { formState: { errors }, getValues, setError, setValue } = useFormContext();
  const [otp, setOtp] = useState(new Array(4).fill(""));
  const [activeOtpIndex, setActiveOtpIndex] = useState(0);
  const [minute, setMinute] = useState(0);
  const [second, setSecond] = useState(0);
  const [loading, setLoading] = useState(false);
  const otpRef = useRef(null);
  const timerRef = useRef(null);
  const clear = () => {
    window.clearInterval(timerRef.current)
  }

  useEffect(() => {
    setActiveOtpIndex(0);
    timerRef.current = window.setInterval(() => {
      setSecond((time) => time + 1);
    }, 1000)
    return () => clear();
  }, [])

  useEffect(() => {
    if (second > 59) {
      if (minute > 59) {
        setMinute(0);
        clear();
      }
      else {
        setMinute((min) => min + 1);
        setSecond(0);
      }
    }
  }, [second])

  useEffect(() => {
    otpRef.current?.focus();
  }, [activeOtpIndex])

  const verifyCode = (param) => {
    setValue("otp", param);
    setLoading(true);
    let payload = {
      mobile: getValues('mobile'),
      otp: param,
    }
    RegisterService.checkOtp(payload)
      .then((response) => {
        if (response.status === "success") {
          setStep(step + 1);
        }
        if (response.status === "error")
          setError('otp', { type: "custom", message: response.message });
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error, "error");
      })
  }
  const handleOnChange = ({ target }) => {
    const { value } = target;
    const newOtp = [...otp];
    newOtp[currentOtpIndex] = value.substring(value.length - 1);

    if (!value)
      setActiveOtpIndex(currentOtpIndex - 1)
    else
      setActiveOtpIndex(currentOtpIndex + 1);

    setOtp(newOtp);
    let param = newOtp.toString().replace(/,/g, "")
    if (newOtp.filter((x) => x !== '').length === 4) {
      //   // setIsLoading(true);
      // setStep(step + 1);
      verifyCode(param);
    }
  }
  const handleOnKeyDown = ({ key }, index) => {
    currentOtpIndex = index;
    if (key === "Backspace")
      setActiveOtpIndex(currentOtpIndex - 1)
  }
  return (
    <>
      <Typography type="softPrimary" className="text-center">
        Таны дугаар луу OTP код илгээлээ.
      </Typography>
      <div className="flex items-center justify-center space-x-3 mt-12 min-[501px]:w-80">
        {
          otp.map((_, index) =>
            <React.Fragment key={index}>
              <Input
                ref={index === activeOtpIndex ? otpRef : null}
                type={index > 3 ? "text" : "number"}
                className="w-14 h-14 rounded py-0 text-center text-xl"
                value={otp[index]}
                onChange={(e) => handleOnChange(e, index)}
                onKeyDown={(e) => handleOnKeyDown(e, index)}
              />
            </React.Fragment>
          )
        }
      </div>
      {
        errors?.otp?.type === "custom" &&
        <p className="text-error text-xs italic pl-2 mt-1">{errors?.otp?.message}</p>
      }
      <div className={twMerge(["justify-center mt-4 hidden", loading && "flex"])}>
        <PulseLoader
          color="#BBC1C9"
          speedMultiplier={0.7}
          loading={loading}
          size={12}
        />
      </div>
      <Typography className="text-center mt-12">
        {minute < 10 ? '0' + minute : minute}:{second < 10 ? '0' + second : second}
      </Typography>
      <div className="flex justify-center mt-10">
        <Button
          variant="softPrimary"
          className="space-x-2"
          icon={<Replay className="w-[18px] h-[18px]" />}
        >
          Ахин илгээх
        </Button>
      </div>
    </>
  )
}

export default Otp;
