import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { Controller, useFormContext } from "react-hook-form";
import { twMerge } from "tailwind-merge";
import { PulseLoader } from "react-spinners";
import dayjs from "dayjs";
// import queryString from "query-string";

import ObjectInfoService from "../../../../services/ObjectInfoService";
import InfoService from "../../../../services/InfoService";
import XypService from "../../../../services/XypService";
// import ProductService from "../../../../services/ProductService";

import DatePicker from "../../../../components/DatePicker";
import Typography from "../../../../base-components/Typography";
import SwitchControl from "../../../../base-components/Switch";
import Select from "../../../../base-components/Select";
import Button from "../../../../base-components/Button";
import Checkbox from "../../../../base-components/Checkbox";
import MuiInput from "../../../../base-components/MuiInput";

import { ReactComponent as Alert } from "../../../../assets/icon/alert-circle.svg";
import {
  formatNumber,
  formatPlateNumber,
  isEmptyObject,
} from "../../../../utils/helper";
import { FormSkeleton } from "../../../../components/Skeleton/FormSkeleton";
import PlateAndVin from "./sections/Plate&Vin";

const ObjectInfo = (props) => {
  const {
    currentStep,
    setCurrentStep,
    title,
    steps,
    productHasService = false,
  } = props;
  const {
    register,
    formState: { errors, isValid },
    setValue,
    getValues,
    watch,
    clearErrors,
    setError,
    trigger,
  } = useFormContext();
  const [checked, setChecked] = useState(false);
  const [objectFields, setObjectFields] = useState([]);
  const [isEnterValue, setIsEnterValue] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingSkeleton, setLoadingSkeleton] = useState(false);
  const { id } = useParams();
  // const [productHasService, setProductHasService] = useState(false);
  const { state } = useLocation();

  useEffect(() => {
    setLoadingSkeleton(true);
    ObjectInfoService.getObjectFields(id)
      .then((response) => {
        if (response.status === "Success") {
          setObjectFields([...response.values]);
          response.values.forEach((field) => {
            if (field.ttype === "boolean") {
              setValue(`objects.0.${field.name}`, 0);
              if (field.name === "trailer")
                setValue(`vehicle_info.trailer`, false);
            }
            if (field.ttype === "date")
              setValue(`objects.0.${field.name}`, dayjs());
            else {
              setValue(`objects.0.${field.name}`, "");
              setValue(`view_objects.0.${field.name}`, "");
            }
          });
        }
        setLoadingSkeleton(false);
      })
      .catch((error) => {
        console.log(error, "error");
        setLoadingSkeleton(false);
      });
  }, [id, setValue]);

  const searchVehicleInfo = (param) => {
    clearErrors();
    setIsEnterValue(false);
    setLoading(true);
    XypService.getVehicleInfo(id, param)
      .then((response) => {
        if (response.status === "Success") {
          if (isEmptyObject(response.values)) {
            setError("xyp", {
              type: "custom",
              message: "Тээврийн хэрэгслийн мэдээлэл олдсонгүй",
            });
            return;
          }
          Object.keys(response.values)?.forEach((field) => {
            if (field === "imported_date")
              setValue(`objects.0.${field}`, dayjs(response.values[field]));
            else {
              setValue(`objects.0.${field}`, response.values[field]);
              setValue(`view_objects.0.${field}`, response.values[field]);
            }
          });
        }
        if (response.status === "error") {
          setError("xyp", {
            type: "custom",
            message: response.message,
          });
        }
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setIsEnterValue(true);
        setLoading(false);
      });
  };
  const onSubmit = (e) => {
    if (!isValid) return trigger();
    let objects = getValues("objects");
    let payload = {
      dynamic_insurance_product_id: id,
      objects: objects,
    };
    ObjectInfoService.setInsuranceObjects(payload)
      .then((response) => {
        if (response.status === "success") {
          setValue("object_ids", response.object_ids);
          if (currentStep < steps.length - 1) setCurrentStep(currentStep + 1);
        }
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  return (
    <div>
      {((state !== null && state.has_service === true) ||
        productHasService === true) && (
        <div className="flex justify-center pt-10">
          <div>
            <Typography variant="Headline" size="sm">
              Тээврийн хэрэгслийн мэдээлэл
            </Typography>
            <PlateAndVin {...{ searchVehicleInfo, loading }} />
          </div>
        </div>
      )}
      {loading && (
        <div className="mt-8 flex justify-center">
          <PulseLoader
            color="#BBC1C9"
            speedMultiplier={0.7}
            loading={loading}
            size={12}
          />
        </div>
      )}
      {((((state !== null && state.has_service) || productHasService) &&
        isEnterValue) ||
        !((state !== null && state.has_service) || productHasService)) && (
        <>
          <div className="mt-10 rounded-xl border bg-surface-gray p-10 max-[841px]:px-6 max-[500px]:px-4">
            <Typography variant="Headline" size="sm">
              {title}
            </Typography>
            {loadingSkeleton ? (
              <FormSkeleton isLoading={loadingSkeleton} textCount={4} />
            ) : (
              <div className="flex justify-center">
                <div className="mt-8 grid grid-cols-6 gap-4">
                  {objectFields.map((field, index) => (
                    <div
                      key={field.id}
                      className={twMerge([
                        index <= 2 &&
                          "col-span-2 max-[841px]:col-span-3 max-[500px]:col-span-6",
                        index > 2 && "col-span-3 max-[500px]:col-span-6",
                      ])}
                    >
                      {field.ttype === "date" && <DateField talbar={field} />}
                      {field.ttype === "boolean" && (
                        <BooleanField {...{ field }} />
                      )}
                      {(field.ttype === "many2one" ||
                        field.ttype === "selection") && (
                        <SelectionField talbar={field} />
                      )}
                      {(field.ttype === "integer" ||
                        field.ttype ===
                          "char" /* && field.name !== "plate_number" */ ||
                        field.ttype === "text") && (
                        <InputField
                          {...{ field, register, errors, setValue }}
                        />
                      )}
                    </div>
                  ))}
                  {(id.match("x_vehicle") || id.match("x_compulsory")) && (
                    <div className="col-start-1 col-end-4 max-[557px]:col-span-6">
                      <div className="!mt-6 inline-flex space-x-4 rounded-lg border bg-white p-3">
                        <Alert stroke="#E3A13A" className="h-5 w-5" />
                        <Typography
                          variant="Label"
                          size="lg"
                          className="w-[300px] max-[557px]:w-full"
                        >
                          Хэрэв таны машин нэмэлт чиргүүлтэй бол тусдаа даатгах
                          ёстой шүү
                        </Typography>
                      </div>
                    </div>
                  )}
                  {!watch("object_ids") && (
                    <div className="col-span-6">
                      <div className="flex justify-between space-y-4 max-[663px]:block">
                        <Checkbox
                          label="Миний мэдээлэл үнэн зөв байна."
                          checked={checked}
                          onChange={(e) => setChecked(e.target.checked)}
                        />
                        <Button
                          disabled={!checked}
                          className={twMerge([!checked && "!bg-white"])}
                          onClick={onSubmit}
                          type="button"
                        >
                          Үргэлжлүүлэх
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
          <div className="mt-10 border-b-2"></div>
        </>
      )}
    </div>
  );
};

const DateField = ({ talbar }) => {
  const { control } = useFormContext();
  return (
    <div>
      <Controller
        control={control}
        name={`objects.0.${talbar.name}`}
        render={({ field: { onChange, onBlur, value, ref } }) => (
          <DatePicker
            ref={ref}
            id={talbar.name}
            label={talbar.description}
            date={value}
            onChangeDate={onChange}
            onBlur={onBlur}
          />
        )}
        rules={{ required: talbar.required }}
      />
    </div>
  );
};

const BooleanField = ({ field }) => {
  const { setValue } = useFormContext();
  const [checked, setChecked] = useState(false);

  const handleChange = (checked) => {
    setChecked(checked);
    if (field.name === "trailer") setValue(`vehicle_info.trailer`, checked);
    let value = checked ? 1 : 0;
    setValue(`objects.0.${field.name}`, value);
  };

  return (
    <div className="flex items-center space-x-4 rounded-lg border bg-white p-3">
      <Typography variant="Label" size="lg" className="w-[260px]">
        {field?.description}
      </Typography>
      <SwitchControl
        id={field.name}
        checked={checked}
        onChange={handleChange}
      />
    </div>
  );
};

const InputField = ({ field, ...props }) => {
  const {
    register,
    formState: { errors },
    setValue,
  } = useFormContext();

  return (
    <>
      <MuiInput
        {...props}
        id={field.name}
        label={field?.description}
        className="w-full"
        placeholder={field.name === "plate_number" ? "1111ААА" : undefined}
        maxLength={field.name === "plate_number" ? 7 : undefined}
        {...register(`objects.0.${field.name}`, {
          required: field.required,
          maxLength: field.name === "plate_number" ? 7 : undefined,
          onChange: (e) => {
            if (field.name === "plate_number") {
              setValue(`vehicle_info.plate_number`, e.target.value);
              setValue(
                `objects.0.${field.name}`,
                formatPlateNumber(e.target.value),
              );
            }
            if (field.ttype === "integer")
              setValue(
                `objects.0.${field.name}`,
                formatNumber(e.target.value, 0),
              );
          },
        })}
      />
      {errors?.objects?.[0][field?.name]?.type === "required" && (
        <p className="mt-1 pl-2 text-xs text-error">{`${field.description} оруулна уу`}</p>
      )}
      {errors?.objects?.[0][field?.name]?.type === "maxLength" && (
        <p className="mt-1 pl-2 text-xs text-error">{`Оруулах утгын урт хэтэрсэн байна!`}</p>
      )}
    </>
  );
};

const SelectionField = ({ talbar }) => {
  const {
    control,
    formState: { errors },
    setValue,
    getValues,
  } = useFormContext();
  const params = {
    info_name: talbar.relation,
    filter: {},
    limit: 10,
    page: 1,
    search_value: "",
  };
  const [relations, setRelations] = useState([]);

  useEffect(() => {
    if (talbar.relation === "") return;
    InfoService.getFieldOfFilter(talbar.relation)
      .then((response) => {
        if (response.status === "Success") {
          setRelations([...response.values]);
        }
      })
      .catch((err) => console.log(err));
  }, [talbar.relation]);

  const onSelectValue = (option) => {
    if (talbar.ttype === "selection")
      setValue(`objects.0.${talbar.name}`, option.value);
    else setValue(`objects.0.${talbar.name}`, option.id);
    if (talbar.name === "make_id") setValue(`vehicle_info.make`, option.name);
    if (talbar.name === "model_id") setValue(`vehicle_info.model`, option.name);
  };

  return (
    <Controller
      control={control}
      name={`view_objects.0.${talbar.name}`}
      render={({ field: { value, onChange, onBlur } }) => (
        <>
          <Select
            id={`selection_${talbar.name}`}
            label={talbar.description}
            value={value}
            onChange={(value) => {
              onChange(value);
              onSelectValue(value);
            }}
            icon={talbar.ttype === "many2many" ? <Checkbox /> : undefined}
            relations={talbar.relation === params.info_name && relations}
            relationName="view_objects.0"
            getValues={getValues}
            service={
              talbar.relation === ""
                ? InfoService.getIrModelFields
                : InfoService.getRelationInfoWithFilter
            }
            params={talbar.relation === "" ? talbar.id : params}
          />
          {errors?.objects?.[0][talbar?.name]?.type === "required" && (
            <p className="mt-1 pl-2 text-xs text-error">{`${talbar.description} сонгоно уу`}</p>
          )}
        </>
      )}
      rules={{ required: talbar.required }}
    />
  );
};

export default ObjectInfo;
