import { useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import dayjs, { Dayjs } from "dayjs";
import { useParams } from "react-router-dom";

import Date from "../../../../../components/DatePicker";
import Typography from "../../../../../base-components/Typography";
import RadioButton from "../../../../../base-components/RadioButton";
import DynamicFieldService from "../../../../../services/DynamicFieldService";

export const Duration = ({ gettingPrice, stepConfig }) => {
  const {
    control,
    setValue,
    getValues,
    formState: { errors },
    watch,
  } = useFormContext();
  const [durations, setDurations] = useState([]);
  const [customDuration, setCustomDuration] = useState(false);
  const { id } = useParams();
  let duration_index = 0;

  useEffect(() => {
    if (!id) return;
    getDurations();
  }, [id]);

  useEffect(() => {
    if (durations.length === 0) return;
    const duration = durations.find((x) => x.selected);
    const date = watch("inception_datetime");
    gettingExpiry_date(duration, date);
  }, [durations, watch("inception_datetime")]);

  const getDurations = () => {
    DynamicFieldService.getDurations(id)
      .then((response) => {
        if (response.status === "Success") {
          duration_index = getValues("duration_index")
            ? getValues("duration_index")
            : 0;
          response.values[duration_index].selected = true;
          setDurations([...response.values]);
          if (response.custom_duration)
            setCustomDuration(response.custom_duration);
          setValue("duration_id", response.values[duration_index].id);
        }
      })
      .catch((error) => {
        console.error(error, "packages error");
      });
  };

  const gettingExpiry_date = (duration, inception_date) => {
    if (duration && inception_date) {
      let expiry_date = dayjs(inception_date)
        .add(duration.year, "year")
        .add(duration.month, "month")
        .add(duration.day, "day");
      getExpiryCustom_date(expiry_date);
      gettingPrice();
    }
  };

  const selectInsuranceDuration = (checked, index) => {
    const i = durations.findIndex((x) => x.selected);
    if (i !== -1) durations[i].selected = false;
    durations[index].selected = checked;
    setDurations([...durations]);
    setValue("duration_id", durations[index].id);
    setValue("duration_index", index);
  };

  const getExpiryCustom_date = (date) => {
    const startDate = watch("inception_datetime");
    if (stepConfig?.x_custom_duration) {
      let diffDay = 0;
      if (startDate) {
        diffDay = date.diff(startDate.startOf("day"), "day") + 1;
      }
      setValue("duration_day", diffDay);
      setValue("duration_index", "");
      setValue("duration_id", "");
      gettingPrice();
    } else {
      setValue("expiry_datetime", date);
    }
  };

  return (
    <>
      {durations.length > 0 && !stepConfig?.x_custom_duration && (
        <div className="mt-10 space-y-4">
          <Typography variant="Title" size="md">
            Гэрээний үргэлжлэх хугацаа
          </Typography>
          <div className="grid grid-cols-6 gap-4">
            {durations.map((duration, index) => (
              <div
                key={duration.id}
                className="col-span-2 rounded-2xl border p-4 max-[841px]:col-span-3 max-[500px]:col-span-6"
              >
                <RadioButton
                  id={duration.id}
                  label={duration.name}
                  name="duration"
                  checked={duration.selected || false}
                  onChange={(e) =>
                    selectInsuranceDuration(e.target.checked, index)
                  }
                />
              </div>
            ))}
          </div>
        </div>
      )}
      <div className="mt-10">
        <Typography variant="Title" size="md">
          Гэрээ эхлэх огноо
        </Typography>
        <div className="mt-4 grid gap-4 min-[480px]:grid-cols-2">
          <Controller
            control={control}
            name="inception_datetime"
            render={({ field: { value, onChange } }) => (
              <>
                <Date
                  id="inception_datetime"
                  label="Эхлэх огноо"
                  date={value}
                  onChangeDate={(date) => {
                    onChange(date);
                    const duration = durations.find((x) => x.selected);
                    gettingExpiry_date(duration, date);
                  }}
                  minDate={dayjs()}
                  className="col-span-1"
                />
                {errors?.inception_datetime?.type === "required" && (
                  <p className="mt-1 pl-2 text-xs text-error">
                    Огноогоо сонгоно уу
                  </p>
                )}
              </>
            )}
            rules={{ required: true }}
          />
          <Controller
            control={control}
            name="expiry_datetime"
            render={({ field: { value, onChange } }) => (
              <>
                <Date
                  id="expiry_datetime"
                  label="Дуусах огноо"
                  date={value}
                  onChangeDate={(date) => {
                    onChange(date);
                    if (stepConfig?.x_custom_duration) {
                      getExpiryCustom_date(date);
                    }
                  }}
                  disabled={!stepConfig?.x_custom_duration}
                  className="col-span-1"
                  minDate={dayjs().add(1, "day")}
                />
                {errors?.expiry_datetime?.type === "required" && (
                  <p className="mt-1 pl-2 text-xs text-error">
                    Огноогоо сонгоно уу
                  </p>
                )}
              </>
            )}
            rules={{ required: true }}
          />
        </div>
      </div>
    </>
  );
};
