import { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { ReactComponent as SearchIcon } from "../../assets/icon/search.svg";
import { ReactComponent as Bell } from "../../assets/icon/bell.svg";
import { ReactComponent as User } from "../../assets/icon/user.svg";
import { ReactComponent as Question } from "../../assets/icon/question.svg";
import { ReactComponent as LogOut } from "../../assets/icon/log-out.svg";
import { ReactComponent as Gear } from "../../assets/icon/gear.svg";
import { ReactComponent as ChevronRight } from "../../assets/icon/chevron-right-1.svg";
import { Icon } from "../utils/menuIcons";
import { twMerge } from "tailwind-merge";
import { useStore } from "../../stores";
import IconButton from "../../base-components/IconButton";
import Dropdown from "../../components/Dropdown";
import Typography from "../../base-components/Typography";
import CustomizedTooltip from "../../base-components/Tooltip";

const menus = [
  {
    id: "plus",
    title: "Даатгал нэмэх",
    path: "/insure",
  },
  {
    id: "home",
    title: "Нүүр хуудас",
    path: "/dashboard",
  },
  {
    id: "invite-friend",
    title: "Найзаа урих",
    path: "/invite-friend",
  },
];

function Layout() {
  const navigate = useNavigate();
  const user = useStore.useUser();
  const clearUser = useStore.useClearUser();
  const token = useStore.useToken();
  const setToken = useStore.useSetToken();
  const selectedMenu = useStore.useSelectedMenu();
  const setSelectedMenu = useStore.useSetSelectedMenu();
  const [isShowing, setIsShowing] = useState(false);

  useEffect(() => {
    setSelectedMenu(window.location.pathname);
  }, []);

  useEffect(() => {
    if (token === "") navigate("/login");
  }, [token]);

  const selectMenu = (menu) => {
    setSelectedMenu(menu.path);
    if (menu.path === "/invite-friend") navigate("coming-soon");
    else navigate(menu.path);
  };

  return (
    <div className="flex h-max min-h-full ">
      <div className="relative bg-primary px-8 py-7 max-lg:px-4  max-[500px]:hidden">
        <div className="px-4 py-2.5">
          <img
            src="/logos/horizontal-white.png"
            alt="horizontal-white-logo"
            className="block w-32 max-lg:hidden"
          />
          <img
            src="/logos/vertical-white.png"
            alt="vertical-white-logo"
            className="hidden w-14 max-lg:block"
          />
        </div>
        <div className="mt-16">
          {menus.map((menu, index) => (
            <div
              key={index}
              className={twMerge([
                "group mt-4 flex w-52 cursor-pointer items-center space-x-4 rounded-lg px-4 py-2.5 transition-all hover:bg-surface-gray/[0.12]",
                "max-lg:w-24 max-lg:flex-col max-lg:space-x-0 max-lg:space-y-2",
              ])}
              onClick={() => selectMenu(menu)}
            >
              {Icon({ id: menu.id, isSelected: menu.path === selectedMenu })}
              <span
                className={twMerge([
                  "transition-color text-on-surface-black-64",
                  "max-lg:text-center",
                  menu.path === selectedMenu
                    ? "text-secondary"
                    : " group-hover:text-on-surface-black-24",
                ])}
              >
                {menu.title}
              </span>
            </div>
          ))}
        </div>
        <div className="absolute bottom-16">
          <div
            className={twMerge([
              "mt-4 flex w-52 cursor-pointer items-center space-x-4 rounded-lg px-4 py-2.5 transition-all hover:bg-surface-gray/[0.12]",
              "max-lg:w-24 max-lg:flex-col max-lg:space-x-0 max-lg:space-y-2",
            ])}
          >
            <Question stroke="#69798C" className="h-5 w-5" />
            <span className={"transition-color ml-4 text-on-surface-black-64"}>
              Тусламж
            </span>
          </div>
          <div
            className={twMerge([
              "mt-4 flex w-52 cursor-pointer items-center space-x-4 rounded-lg px-4 py-2.5 transition-all hover:bg-surface-gray/[0.12]",
              "max-lg:w-24 max-lg:flex-col max-lg:space-x-0 max-lg:space-y-2",
            ])}
            onClick={() => {
              navigate("/login");
              setToken("");
              clearUser();
            }}
          >
            <LogOut stroke="#B7281E" className="h-5 w-5" />
            <span className={"transition-color ml-4 text-error"}>Гарах</span>
          </div>
        </div>
      </div>
      <div className="w-full">
        <div className="flex justify-between p-4 px-16 max-[500px]:px-4">
          <div className="relative flex items-center">
            <SearchIcon stroke="#D9E0E8" className="absolute ml-2 h-5 w-5" />
            <input
              className="rounded-full border border-stroke-dark-12 bg-surface-gray px-4 py-2 pl-8 text-sm text-primary outline-none"
              placeholder="Хайх"
            />
          </div>
          <div className="flex items-center space-x-3">
            <IconButton variant="outlinePrimary" size="sm">
              <Bell stroke="primary" className="h-5 w-5" />
            </IconButton>
            <button
              className="flex items-center space-x-2"
              onClick={() => setIsShowing(!isShowing)}
            >
              <IconButton variant="outlinePrimary" size="sm">
                <User stroke="primary" className="h-5 w-5" />
              </IconButton>
              <span className="max-[500px]:hidden">{user.user_name}</span>
            </button>
            <Dropdown show={isShowing} className="mt-6">
              <Dropdown.Header>
                <div className="flex items-center space-x-2">
                  <IconButton variant="outlinePrimary" size="sm">
                    <User stroke="primary" className="h-5 w-5" />
                  </IconButton>
                  <span>{user.user_name}</span>
                </div>
                <ChevronRight />
              </Dropdown.Header>
              <Dropdown.Panel>
                <div className="flex items-center px-4 py-3.5 hover:cursor-pointer">
                  <Question className="h-4 w-4" />
                  <Typography size="md" className="ml-4">
                    Тусламж
                  </Typography>
                </div>
                <div className="flex items-center px-4 py-3.5 hover:cursor-pointer">
                  <Gear className="h-4 w-4" />
                  <Typography size="md" className="ml-4">
                    Тохиргоо
                  </Typography>
                </div>
                <div
                  className="flex items-center px-4 py-3.5 hover:cursor-pointer"
                  onClick={() => {
                    navigate("/login");
                    setToken("");
                    clearUser();
                  }}
                >
                  <LogOut stroke="#B7281E" className="h-4 w-4" />
                  <Typography size="md" className="ml-4 text-error">
                    Гарах
                  </Typography>
                </div>
              </Dropdown.Panel>
            </Dropdown>
          </div>
        </div>
        <Outlet />
        <div className="fixed bottom-0  left-0 right-0 hidden justify-evenly bg-primary max-[500px]:flex">
          {menus.map((menu, index) => (
            <CustomizedTooltip
              key={index}
              title={menu.title}
              placement="bottom-end"
            >
              <div
                className={twMerge([
                  "flex cursor-pointer flex-col items-center space-y-2 truncate rounded-lg p-4 transition-all hover:bg-white/[0.04] max-[353px]:my-2",
                  "w-min",
                  menu.path === selectedMenu && "bg-white/[0.08]",
                ])}
                onClick={() => selectMenu(menu)}
              >
                {Icon({ id: menu.id, isSelected: menu.path === selectedMenu })}
                <Typography
                  variant="Label"
                  size="md"
                  className={twMerge([
                    "transition-color max-[353px]:hidden",
                    menu.path === selectedMenu
                      ? "text-secondary"
                      : "text-on-surface-black-64",
                  ])}
                >
                  {menu.title}
                </Typography>
              </div>
            </CustomizedTooltip>
          ))}
        </div>
      </div>
      {/* <div className="bg-surface-gray p-8">
        <img src="/logos/horizontal-primary.png" alt="logo" />
      </div> */}
    </div>
  );
}

export default Layout;
