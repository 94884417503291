import { useFormContext } from "react-hook-form";
import React, { useEffect, useState } from "react";

import Bagts from "./bagts";
import { Duration } from "./duration";
import MapInfo from "./map";

import Button from "../../../../base-components/Button";
import DynamicFieldService from "../../../../services/DynamicFieldService";

const PolicyInfo = ({
  currentStep,
  setCurrentStep,
  steps,
  gettingPrice,
  currency,
  setCurrency,
  selectedCountry,
  setselectedCountry,
  stepConfig,
}) => {
  const {
    formState: { isValid },
    trigger,
  } = useFormContext();

  const onNextStep = () => {
    trigger("market_price", { shouldFocus: true });
    if (isValid) setCurrentStep(currentStep + 1);
  };

  const [countryList, setCountryList] = useState([]);

  useEffect(() => {
    if (stepConfig.x_map) getCountryTranslate();
  }, []);

  /**
   * Орчуулсан улс орны нэр ISO кодын жагсаалт авах
   */
  const getCountryTranslate = () => {
    DynamicFieldService.getCountryTranslate()
      .then((response) => {
        if ((response.status = "Success")) {
          setCountryList([...response.values]);
        }
      })
      .catch((error) => {
        console.log(error, "countryList error");
      });
  };

  return (
    <div className="pt-10">
      {stepConfig.x_map && (
        <MapInfo
          translatedCountryList={countryList}
          setTranslatedCountryList={setCountryList}
          selectedCountry={selectedCountry}
          setSelectedCountry={setselectedCountry}
        />
      )}
      <Bagts
        {...{
          gettingPrice,
          currency,
          setCurrency,
          stepConfig,
          selectedCountry,
        }}
      />
      <Duration {...{ gettingPrice, stepConfig }} />
      {currentStep < steps.length - 1 && (
        <div className="flex justify-end">
          <Button type="button" className="my-6" onClick={onNextStep}>
            Үргэлжлүүлэх
          </Button>
        </div>
      )}
    </div>
  );
};

export default PolicyInfo;
