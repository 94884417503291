import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'
import dayjs from 'dayjs'

import { useStore } from '../../../stores'
import { roundUp } from '../../../utils/helper'

import Button from '../../../base-components/Button'
import Typography from '../../../base-components/Typography'
import IconButton from '../../../base-components/IconButton'
import Modal from '../../../base-components/Modal'
import Pagination from '../../../base-components/Pagination'
import CustomizedTooltip from '../../../base-components/Tooltip'

import { ReactComponent as Plus } from '../../../assets/icon/plus.svg'
import { ReactComponent as CirclePlus } from '../../../assets/icon/plus-circle.svg'
import { ReactComponent as Refresh } from '../../../assets/icon/refresh-cw.svg'
import { ReactComponent as Filter } from '../../../assets/icon/filter.svg'
import { ReactComponent as Chevron } from '../../../assets/icon/chevron-right-1.svg'
import { ReactComponent as PlusCircle } from '../../../assets/icon/plus-circle.svg'
import { ReactComponent as FileMove } from '../../../assets/icon/file-move.svg'

import ProductDetail from './product-detail'

import PolicyInfoService from '../../../services/PolicyInfoService'

const Dashboard = () => {
  const navigate = useNavigate()
  const setSelectedMenu = useStore.useSetSelectedMenu()
  const [showDetail, setShowDetail] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(1)
  const [policies, setPolicies] = useState([])
  const [insuranceDtl, setInsuranceDtl] = useState({})

  useEffect(() => {
    getInsurance()
  }, [page])

  const getInsurance = () => {
    let params = {
      limit: 8,
      page: page,
      filter: {},
      sort_field: 'inception_datetime',
      sort_type: 'desc'
    }
    PolicyInfoService.getInsurancePolicies(params)
      .then(response => {
        if (response.status === 'Success') {
          let list = []
          response.values.forEach(data => {
            let all_days = dayjs(data?.expiry_datetime).diff(
              dayjs(data?.inception_datetime),
              'day'
            )
            let left_days = dayjs(data.expiry_datetime).diff(dayjs(), 'day')
            let percent = (left_days * 100) / all_days
            data.percent = roundUp(percent, 2) + '%'
            data.left_days = left_days
            list.push(data)
          })

          setPolicies([...list])
          setTotalPage(response.total_pages)
        }
      })
      .catch(error => {
        console.log(error, 'policies error')
      })
  }
  const createToInsure = () => {
    setSelectedMenu('/insure')
    navigate('/insure')
  }
  const gettingStatus = active => {
    if (active) return 'Идэвхтэй'
    else return 'Идэвхгүй'
    // if (status == "incomplete")
    //   return "Дутуу бөглөсөн";
  }
  const handleClick = policy => {
    PolicyInfoService.getInsurancePolicyDtl(policy.id)
      .then(response => {
        setInsuranceDtl({
          ...response.value,
          id: policy.id,
          percent: policy.percent,
          package_name: policy.package_id[1],
          claim_status: policy.claim_status
        })
        setShowDetail(true)
      })
      .catch(error => {
        console.log(error, 'error')
      })
    /* if (policy.active) {
    }
    else
      setOpenModal(true); */
  }

  return (
    <>
      {policies.length > 0 ? (
        <div>
          <div className='flex justify-between py-4 px-16 max-[841px]:px-10 max-[500px]:px-6 border-b'>
            <div className='text-primary flex items-center'>
              <Typography variant='Headline' size='md'>
                Даатгалууд
              </Typography>
              <h2 className='text-2xl font-bold'></h2>
            </div>
            <div className='flex items-center space-x-4'>
              <Button
                variant='secondary'
                className='max-[500px]:hidden'
                onClick={() => createToInsure()}
              >
                Даатгуулах
              </Button>
              <div className='border-r border-stroke-dark-12 h-full'></div>
              <IconButton variant='outlinePrimary'>
                <Filter className='w-5 h-5 text-primary' />
              </IconButton>
            </div>
          </div>
          <div className='relative py-4 px-12 max-[841px]:px-6 max-[500px]:px-2'>
            <div className='flex flex-wrap max-h-[688px] overflow-auto py-2 px-4'>
              {policies.map((data, index) => (
                <div
                  key={index}
                  className='p-2 2xl:w-1/4 xl:w-1/3 max-md:w-full w-1/2'
                >
                  <div
                    onClick={() => handleClick(data)}
                    className={twMerge([
                      'relative group border rounded-2xl w-full h-72 p-6 flex flex-col justify-between cursor-pointer shadow-card',
                      'hover:border-secondary hover:border-2', // HOVER
                      showDetail &&
                        insuranceDtl.id === data.id &&
                        'border-secondary border-2'
                    ])}
                  >
                    <div>
                      <div className='flex items-center space-x-3'>
                        <div>
                          <div
                            className={twMerge([
                              'mb-4 w-14 h-14 rounded-full flex justify-center items-center border group-hover:border-secondary',
                              showDetail &&
                                insuranceDtl.id === data.id &&
                                'border-secondary'
                            ])}
                          >
                            <img
                              src={'/images/product.png'}
                              alt='image'
                              className='rounded-full'
                            />
                          </div>
                        </div>
                        <div>
                          <Typography variant='Title'>{data.name}</Typography>
                          <Typography
                            size='md'
                            className='text-on-surface-black-64'
                          >
                            {data.dynamic_product_name}
                          </Typography>
                        </div>
                      </div>
                      <div className='grid mt-4 space-y-2'>
                        <div className='bg-surface-gray rounded-full inline-flex w-fit items-stretch py-1 px-3 space-x-2'>
                          <Typography size='md'>
                            {gettingStatus(data.active)}
                          </Typography>
                          <div className='border-l'></div>
                          <div
                            className={twMerge([
                              'rounded-full w-2.5 h-2.5 self-center',
                              data.active ? 'bg-success' : 'bg-error'
                              // product.status === "incomplete" && "bg-warning",
                            ])}
                          ></div>
                        </div>
                        {data.claim_status &&
                          data.claim_status !== 'Материал цуглуулах' && (
                            <div className='bg-surface-gray rounded-full inline-flex w-fit items-stretch py-1 px-3 space-x-2'>
                              <Typography size='md'>
                                Нөхөн төлбөр илгээгдсэн
                              </Typography>
                              <div className='border-l'></div>
                              <div className='rounded-full bg-info w-2.5 h-2.5 self-center'></div>
                            </div>
                          )}
                      </div>
                    </div>
                    {data.claim_status &&
                    data.claim_status === 'Материал цуглуулах' ? (
                      <Button
                        icon={
                          <FileMove fill='#fff' className='w-[18px] h-[18px]' />
                        }
                        onClick={e => {
                          e.preventDefault()
                          e.stopPropagation()
                          navigate(`/send-material`, {
                            state: {
                              policy_id: data.id,
                              claim_status: data.claim_status
                            }
                          })
                        }}
                        className='space-x-2'
                      >
                        Материал илгээх
                      </Button>
                    ) : (
                      data.expiry_datetime !== '' && (
                        <div className='mt-6 space-y-2'>
                          <Typography
                            variant='Label'
                            size='md'
                            className='text-on-surface-black-64'
                          >
                            Дуусах хугацаа:
                          </Typography>
                          <Typography
                            variant='Label'
                            size='md'
                            className='text-on-surface-black-64'
                          >
                            {dayjs(data.expiry_datetime).format('YYYY-MM-DD')} ·{' '}
                            {data.left_days} өдөр үлдсэн
                          </Typography>
                          <div className='w-40'>
                            <CustomizedTooltip title={data.percent}>
                              <div className='rounded-full h-2 bg-surface-gray cursor-pointer w-full'>
                                <div
                                  style={{ width: data.percent }}
                                  className='rounded-full h-2 bg-primary'
                                ></div>
                              </div>
                            </CustomizedTooltip>
                          </div>
                        </div>
                      )
                    )}
                    {/* {
                          !data.active &&
                          <div>
                            <Button
                              icon={<CirclePlus stroke="#fff" className="w-[18px] h-[18px]" />}
                            >
                              Сунгах
                            </Button>
                          </div>
                        } */}
                    {/* {
                          product.status === "incomplete" &&
                          <div>
                            <Button>
                              <Refresh stroke="#fff" className="w-[18px] h-[18px]" />
                              <span className="px-2">Үргэлжлүүлэх</span>
                            </Button>
                          </div>
                        } */}
                    <div className='absolute right-2 top-[calc(100%/2-10px)] hidden group-hover:block'>
                      <div className='bg-primary rounded-full'>
                        <Chevron fill='#fff' />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className='flex items-center justify-end text-xs mt-4'>
              <Pagination
                simple
                current={page}
                totalPage={totalPage}
                onClickNextPage={() => setPage(page + 1)}
                onClickPrevPage={() => setPage(page - 1)}
              ></Pagination>
            </div>
            <IconButton
              variant='secondary'
              onClick={() => createToInsure()}
              className='fixed bottom-24 right-6 hidden max-[500px]:flex'
            >
              <PlusCircle fill='#fff' />
            </IconButton>
          </div>
        </div>
      ) : (
        <div className='flex justify-center items-center h-[calc(100%-70px)]'>
          <div className='text-center -mt-6'>
            <img src='/images/bro.png' alt='dashboard-image' />
            <h2 className='text-2xl text-primary mt-12'>
              Агулад тавтай морил!
            </h2>
            <div className='text-on-surface-black-64 mt-4'>
              Та өөрт тохирох даатгалаа үүсгээрэй
            </div>
            <div className='flex justify-center mt-8'>
              <Button
                onClick={() => createToInsure()}
                icon={<Plus stroke='white' />}
              >
                Даатгал үүсгэх
              </Button>
            </div>
          </div>
        </div>
      )}
      <ProductDetail
        open={showDetail}
        onClose={() => setShowDetail(false)}
        insuranceDtl={insuranceDtl}
      />
      <Modal
        isOpenModal={openModal}
        onClose={() => setOpenModal(false)}
        size='md'
      >
        <div className='mt-8 px-8 pt-4 pb-2'>
          <Typography variant='Headline' size='sm' className='text-center'>
            Даатгал сунгах
          </Typography>
          <Typography
            size='md'
            type='softPrimary'
            className='text-center mt-4 pb-4'
          >
            Lorem ipsum dolor sit amet consectetur. Massa pellentesque aliquet
            phasellus faucibus sit tempor. Ridiculus ac maecenas eleifend eu
            nunc. Volutpat morbi sapien id proin massa nibh et viverra orci.
            Donec pharetra feugiat ut sed.
          </Typography>
        </div>
        <div className='grid grid-cols-2 gap-4 pt-4 px-10 border-t -ml-6 -mr-6'>
          <Button variant='softPrimary'>Нөхцөл өөрчлөх</Button>
          <Button>Хуучин нөхцлөөр</Button>
        </div>
      </Modal>
    </>
  )
}

export default Dashboard
