import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { twMerge } from "tailwind-merge";

import { useStore } from "../../stores";

import Button from "../../base-components/Button";
import IconButton from "../../base-components/IconButton";
import Steps from "../../base-components/Steps";

import { ReactComponent as Cross } from "../../assets/icon/cross.svg";
import GroupConfigService from "../../services/GroupConfigService";
import Groups from "./groups";
import ProductService from "../../services/ProductService";

const InsuranceProcess = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [steps, setSteps] = useState([]);
  const [currency, setCurrency] = useState("₮");
  const [productHasService, setProductHasService] = useState(false);
  const [stepConfig, setStepConfig] = useState();
  // const setLoading = useStore.useSetLoading();

  const prevPath = useStore.usePrevPath();

  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    // buteegdehuunii medeelel tatah
    ProductService.getProduct(id)
      .then((response) => {
        if (response?.product?.has_service === true) {
          setProductHasService(true);
        } else {
          setProductHasService(false);
        }
        if (response?.product?.currency?.symbol) {
          setCurrency(response.product.currency.symbol);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [id]);

  useEffect(() => {
    GroupConfigService.getGroupConfigLines(id)
      .then((response) => {
        if (response.status === "Success") {
          const stepsArray = [];
          let sequence = 1;
          let countElse = 0;
          setStepConfig(response?.step_config);
          response.values.forEach((data) => {
            if (id.match("x_accident_medical")) {
              if (data.name !== "Объект") {
                stepsArray.push({
                  title: data.display_name,
                  sequence: sequence,
                  name: data.name,
                });
                sequence++;
              } else {
                countElse++;
              }
            } else {
              stepsArray.push({
                title: data.display_name,
                sequence: sequence,
                name: data.name || "",
              });
              sequence++;
            }
          });
          if (response.values.length > 0) {
            stepsArray.push({
              title: "Баталгаажуулах",
              sequence: response.values.length + 1 - countElse,
              name: "",
            });
          }
          setSteps([...stepsArray]);
        }
      })
      .catch((err) => console.log(err));
  }, [id]);

  return (
    <div
      className={twMerge([
        prevPath === "/insure" && "h-max min-h-full w-full lg:flex",
      ])}
    >
      <div
        className={twMerge([
          "border-bottom relative flex items-center justify-between border-stroke-dark-12 bg-surface-gray p-8 max-[780px]:grid",
          prevPath === "/insure" &&
            " h-revert border-0 p-10 lg:block lg:h-full lg:w-[23%] xl:w-1/5",
        ])}
      >
        <div
          className={twMerge([
            "mx-6 items-center justify-between max-[780px]:mb-8 max-[780px]:flex",
            prevPath === "/insure" && "lg:block",
          ])}
        >
          <img
            src="/logos/horizontal-primary.png"
            alt="logo"
            onClick={() => {
              currentStep < steps.length && navigate(prevPath);
            }}
            className="w-40"
          />
          <IconButton
            size="sm"
            variant="outlinePrimary"
            onClick={() => navigate(prevPath)}
            className="hidden max-[780px]:flex"
          >
            <Cross fill="#0A213A" />
          </IconButton>
        </div>
        {prevPath === "/insure" && (
          <Steps
            current={currentStep}
            setCurrent={setCurrentStep}
            items={steps}
            orientation="vertical"
            className="-ml-2 mt-16 hidden lg:block"
          />
        )}
        <div
          className={twMerge([
            "scrollbar-hide block max-lg:overflow-auto",
            prevPath === "/insure" && "max-lg:mx-4 lg:hidden",
          ])}
        >
          <Steps
            current={currentStep}
            setCurrent={setCurrentStep}
            items={steps}
          />
        </div>
        {steps.length === currentStep ? (
          <span className="w-20"></span>
        ) : (
          <Button
            size="sm"
            variant="outlinePrimary"
            className={twMerge([
              "text-error max-[780px]:hidden",
              prevPath === "/insure" &&
                "bottom-10 left-10 right-10 space-x-2 lg:absolute",
            ])}
            onClick={() => navigate(prevPath)}
            icon={
              currentStep > 1 && currentStep < steps.length ? (
                <Cross />
              ) : undefined
            }
          >
            {currentStep === 1 && (
              <span className="text-on-surface-black-64">Буцах</span>
            )}
            {currentStep > 1 && currentStep < steps.length && (
              <span>Цуцлах</span>
            )}
          </Button>
        )}
      </div>
      <Groups
        {...{
          currentStep,
          setCurrentStep,
          steps,
          productHasService,
          currency,
          setCurrency,
          stepConfig,
        }}
      />
    </div>
  );
};

export default InsuranceProcess;
