import { Outlet } from "react-router-dom";

const Auth = () => {
  return (
    <div className="bg-login absolute flex h-full w-full items-center justify-center bg-third-soft bg-cover bg-center text-primary">
      <div className="max-[500px]:my-14 max-[500px]:w-full max-[500px]:p-6">
        <div className="mb-16 flex justify-center">
          <img src="/logos/vertical-primary.png" alt="logo" />
        </div>
        <div className="mt-4 rounded-2xl border border-stroke-dark-12 bg-white p-8 max-[500px]:px-6">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Auth;
