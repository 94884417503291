import { useEffect, useState, useCallback, useMemo } from "react";
import dayjs from "dayjs";
import debounce from "lodash/debounce";
import DataTable from "../../../base-components/DataTable";
import Pagination from "../../../base-components/Pagination";
import IconButton from "../../../base-components/IconButton";
import DatePicker from "../../../components/DatePicker";
import Typography from "../../../base-components/Typography";
import { ReactComponent as Filter } from "../../../assets/icon/filter.svg";
import { ReactComponent as FileDownload } from "../../../assets/icon/file-download.svg";
import { formatNumber, formatDateSplitTime } from "../../../utils/helper";
import PolicyInfoService from "../../../services/PolicyInfoService";

const Sales = () => {
  const currentDate = dayjs();
  const [sales, setSales] = useState([]);
  const [inceptionDate, setInceptionDate] = useState(currentDate);
  const [expiryDate, setExpiryDate] = useState(currentDate);
  const [loading, setLoading] = useState(false);

  const [pagination, setPagination] = useState({
    page: 1,
    rowsPerPage: 10,
    totalPage: 1,
  });

  const { page, rowsPerPage, totalPage } = pagination;

  const debouncedGetSales = useCallback(
    debounce((rowsNumber) => getSales(rowsNumber), 300),
    [inceptionDate, expiryDate, rowsPerPage, page],
  );

  useEffect(() => {
    debouncedGetSales();
  }, [inceptionDate, expiryDate, rowsPerPage, page, debouncedGetSales]);

  const getSales = (rowsNumber) => {
    setLoading(true);
    const params = {
      limit: rowsNumber || rowsPerPage,
      page,
      filter: {
        begin_datetime: inceptionDate.format("YYYY-MM-DD 00:00:00"),
        end_datetime: expiryDate.format("YYYY-MM-DD HH:mm:ss"),
      },
      sort_field: "inception_datetime",
      sort_type: "asc",
    };

    PolicyInfoService.getInsurancePolicies(params)
      .then((response) => {
        if (response.status === "Success") {
          setSales(response.values);
          setPagination((prev) => ({
            ...prev,
            totalPage: response.total_pages,
          }));
        } else {
          console.error(response.message || "Failed to fetch sales data");
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching sales data", error);
        setLoading(false);
      });
  };

  const handleDownload = useCallback((id) => {
    if (id) {
      PolicyInfoService.getInsurancePolicyFileUrl(id, { nonLoading: true })
        .then((response) => {
          window.open(response, "_blank");
        })
        .catch((error) => {
          console.error("Download error", error);
        });
    }
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "№",
        accessor: (_, index) => (page - 1) * 10 + index + 1,
        id: "index",
        Cell: ({ value }) => <div className="pl-3">{value}</div>,
      },
      {
        Header: "Гэрээний дугаар",
        accessor: "warranty_number",
      },
      {
        Header: "Байгуулсан огноо",
        accessor: "warranty_date",
        Cell: ({ value }) => formatDateSplitTime(value),
      },
      {
        Header: "Бүтээгдэхүүн",
        accessor: "dynamic_product_name",
      },
      {
        Header: "Багц",
        accessor: "package_id",
        Cell: ({ value }) => value?.[1] || "N/A",
      },
      {
        Header: "Даатгуулагч",
        accessor: "partner_name",
      },
      {
        Header: "Үнэлгээ",
        accessor: "market_price",
        Cell: ({ value }) => `${formatNumber(value, 0)} ₮`,
      },
      {
        Header: "Хураамж",
        accessor: "insurance_fee",
        Cell: ({ value }) => `${formatNumber(value, 0)} ₮`,
      },
      {
        Header: "Төлөгдсөн огноо",
        accessor: "paid_date",
        Cell: ({ row }) =>
          row.original.paid_date ? (
            formatDateSplitTime(row.original.paid_date)
          ) : (
            <span
              className={`${
                row.original.payment_state === "paid"
                  ? "text-green-500"
                  : "text-[#B7281E]"
              }`}
            >
              {row.original.payment_state === "paid"
                ? "Төлөгдсөн"
                : "Төлөгдөөгүй"}
            </span>
          ),
      },
      {
        Header: "Эхлэх огноо",
        accessor: "inception_datetime",
        Cell: ({ value }) => formatDateSplitTime(value),
      },
      {
        Header: "Дуусах огноо",
        accessor: "expiry_datetime",
        Cell: ({ value }) => formatDateSplitTime(value),
      },
      {
        Header: "Татах",
        accessor: "id",
        Cell: ({ value }) => (
          <IconButton
            size="sm"
            variant="softPrimary"
            onClick={() => handleDownload(value)}
          >
            <FileDownload stroke="#0A213A" className="h-4 w-4" />
          </IconButton>
        ),
      },
    ],
    [page, handleDownload],
  );

  return (
    <div className="bg-white">
      <div class="flex justify-between border-b border-gray-300 px-16 py-4">
        <Typography variant="Headline" size="md">
          Борлуулалт
        </Typography>
        <div class="flex items-center gap-3">
          <DatePicker
            date={inceptionDate}
            onChangeDate={setInceptionDate}
            className="rounded-xl text-base"
            placement="right"
          />
          <span class="w-[4px] border-l border-r border-black"></span>
          <DatePicker
            date={expiryDate}
            onChangeDate={setExpiryDate}
            className="rounded-xl text-base"
            placement="right"
          />
          <div class="border-l border-gray-300 pl-3">
            <IconButton
              variant="outlinePrimary"
              size="lg"
              onClick={debouncedGetSales}
            >
              <Filter className="h-5 w-5 text-primary" />
            </IconButton>
          </div>
        </div>
      </div>
      <div className="px-8 py-4 text-primary max-[841px]:px-8 max-[500px]:px-4">
        <div className="overflow-x-auto px-2 py-4">
          <DataTable
            columns={columns}
            data={sales}
            loading={loading}
            rowsPerPage={rowsPerPage}
            totalPage={totalPage}
            currentPage={page}
            onPageChange={(newPage) =>
              setPagination((prev) => ({
                ...prev,
                page: Math.min(Math.max(newPage, 1), totalPage),
              }))
            }
            onRowsPerPageChange={(rowsNumber) => {
              setPagination((prev) => ({
                ...prev,
                rowsPerPage: rowsNumber,
                page: 1,
              }));
              debouncedGetSales(rowsNumber);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Sales;
