import axios from "axios";

const headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
  "Cache-Control": "no-cache", // browser cache disable
  Pragma: "no-cache", // browser cache disable
  Expires: "0", // browser cache disable
};

export const brokerInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers,
});

export const productInstance = axios.create({
  baseURL: process.env.REACT_APP_PRODUCT_URL,
  headers,
});
