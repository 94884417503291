import { brokerInstance, productInstance } from "./HttpClient";
import { useStore } from "../stores";

brokerInstance.interceptors.request.use((config) => {
  const paramConfig = config;
  const token = useStore.getState().token;
  paramConfig.headers.Authorization = `Bearer ${token}`;
  return paramConfig;
});

productInstance.interceptors.request.use((config) => {
  const paramConfig = config;
  const token = useStore.getState().token;
  paramConfig.headers.Authorization = `Bearer ${token}`;
  return paramConfig;
});

function interceptError(error) {
  if (error.response?.status === 410) {
    useStore.setState({
      notification: { message: error.response?.data?.detail, type: "warning" },
    });
    return;
  }

  if (error.response?.status === 401) {
    useStore.setState({
      notification: { message: error.response?.data?.detail, type: "warning" },
    });

    // if unauthorized then clear token
    useStore.setState({
      token: "",
    });

    return;
  }

  // if (error.response?.status >= 401 && error.response?.data?.detail) {
  //   useStore.setState({ notification: { message: JSON.stringify(error.response?.data?.detail), type: 'warning' } });
  //   return;
  // }

  // useStore.setState({ notification: { message: error.code + " => " + error.message + " => " + error.stack, type: 'error' } })
}

const get = (url, config) => {
  if (!config?.nonLoading) useStore.setState({ loading: true });
  return brokerInstance
    .get(url, config)
    .then((response) => Promise.resolve(response.data))
    .catch((err) => {
      interceptError(err);
      return Promise.reject(err);
    })
    .finally(() => {
      if (!config?.nonLoading) useStore.setState({ loading: false });
    });
};

const post = (url, payload, config) => {
  if (!config?.nonLoading) useStore.setState({ loading: true });
  return brokerInstance
    .post(url, payload, config)
    .then((response) => Promise.resolve(response.data))
    .catch((err) => {
      interceptError(err);
      return Promise.reject(err);
    })
    .finally(() => {
      if (!config?.nonLoading) useStore.setState({ loading: false });
    });
};

const getProduct = (url, config) => {
  if (!config?.nonLoading) useStore.setState({ loading: true });
  return productInstance
    .get(url, config)
    .then((response) => Promise.resolve(response.data))
    .catch((err) => {
      interceptError(err);
      return Promise.reject(err);
    })
    .finally(() => {
      if (!config?.nonLoading) useStore.setState({ loading: false });
    });
};

const postProduct = (url, payload, config) => {
  if (!config?.nonLoading) useStore.setState({ loading: true });
  return productInstance
    .post(url, payload, config)
    .then((response) => Promise.resolve(response.data))
    .catch((err) => {
      interceptError(err);
      return Promise.reject(err);
    })
    .finally(() => {
      if (!config?.nonLoading) useStore.setState({ loading: false });
    });
};

const BaseService = {
  get,
  post,
  getProduct,
  postProduct,
};

export default BaseService;
