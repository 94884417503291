import Service from "./BaseService";

const createInvoice = (ajd) =>
  Service.get(`/dynamicInsurance/merch-ajd-invoice?inv=${ajd}`);

const insertOwnerInfo = (payload) =>
  Service.post(`/dynamicInsurance/merch-ajd-invoice-owner`, payload);

const checkInvoice = (policy_id, config) =>
  Service.get(`/dynamicInsurance/check-invoice?policy_id=${policy_id}`, {
    ...config,
    nonLoading: true,
  });

const InvoiceService = {
  createInvoice,
  insertOwnerInfo,
  checkInvoice,
};

export default InvoiceService;
