import Service from "./BaseService";

const getObjectFields = (id, config) => Service.get(`/dynamicInsurance/get-object-fields?dynamic_insurance_product_id=${id}`, { ...config, nonLoading: true });

const setInsuranceObjects = (payload) => Service.post(`/dynamicInsurance/set-insurance-objects/`, payload)

/**
 * Даатгалын бүтээгдэхүүнд хамаарах объектүүдийг хадгалах Api
 * @param {Object} payload obj data
 * @returns бүртгэгдсэн объектүүдийн мэдээлэл
 */
const setInsuranceObjectsFromList = (payload) => Service.post(`/dynamicInsurance/set-insurance-objects-from-list/`, payload)


const ObjectInfoService = {
  getObjectFields,
  setInsuranceObjects,
  setInsuranceObjectsFromList,
}

export default ObjectInfoService;
