import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Controller, useFormContext } from "react-hook-form";
import queryString from "query-string";
import { debounce } from "lodash";
import { PulseLoader } from "react-spinners";

import { formatNumber } from "../../../../../utils/helper";

import Covid from "../covid";
import Checkbox from "../../../../../base-components/Checkbox";
import Input from "../../../../../base-components/Input";
import Select from "../../../../../base-components/Select";
import Typography from "../../../../../base-components/Typography";
import { ReactComponent as Check } from "../../../../../assets/icon/check-circle-2.svg";

import DynamicFieldService from "../../../../../services/DynamicFieldService";
import { ReactComponent as Check1 } from "../../../../../assets/icon/check-circle-1.svg";

const Bagts = ({
  gettingPrice,
  currency,
  setCurrency,
  stepConfig,
  selectedCountry,
}) => {
  const {
    register,
    control,
    formState: { errors },
    setValue,
    watch,
    getValues,
  } = useFormContext();
  const [packages, setPackages] = useState([]);
  const [selectedPackageId, setSelectedPackageId] = useState(null);
  const [loader, setLoader] = useState(false);
  const [perils, setPerils] = useState([]);
  const { id } = useParams();
  const [staticInsuredValue, setStaticInsuredValue] = useState(false);

  useEffect(() => {
    if (getValues("package") && getValues("package")?.id) {
      setSelectedPackageId(getValues("package")?.id);
    }
  });

  useEffect(() => {
    if (!id) return;
    if (id === "x_compulsory_liability,15")
      setValue("market_price", "10,000,000");
    stepConfig.x_map ? getPackages(selectedCountry) : getPackages();
  }, [id]);

  // Сонгосон улсад өөчлөлт орвол хамааралтай багцыг дахин хайна
  useEffect(() => {
    if (stepConfig.x_map && selectedCountry.length > 0) {
      setValue(`package`, null);
      getPackages(selectedCountry);
      setValue("first_country_code", selectedCountry[0]);
      let additional_country_ids = selectedCountry.slice(1);
      setValue("additional_country_code", additional_country_ids);
    }
  }, [selectedCountry]);

  useEffect(() => {
    if (!watch("package")?.id) return;
    let value = {
      dynamic_insurance_product_id: id,
      package_id: watch("package")?.id,
    };
    const param = queryString.stringify(value);
    Promise.all([
      DynamicFieldService.getPerils(param),
      DynamicFieldService.getExtraService(param),
    ])
      .then((response) => {
        if ((response[0].status = "Success")) {
          // Perils
          setPerils([...response[0].values]);
          let peril_ids = [];
          response[0].values.forEach((peril) => {
            peril_ids.push(peril.id);
          });
          setValue(`peril_ids`, peril_ids);
        }
        if ((response[1].status = "Success"))
          // Extra service
          setValue(
            "extra_service",
            JSON.stringify(getValues("extra_service")) !== "[]"
              ? getValues("extra_service")
              : response[1]?.values,
          );
      })
      .catch((error) => {
        console.log("perils error", error);
      });
  }, [watch("package")]);

  const getPackages = (selectedCountry) => {
    setLoader(true);
    let value = {
      dynamic_insurance_product_id: id,
      // Сонгогдсон улсын жагсаалт байвал params-д нэмнэ
      selectedCountry:
        selectedCountry && selectedCountry.length > 0 ? selectedCountry : "",
    };
    const param = queryString.stringify(value, { arrayFormat: "comma" });
    // Дахин багын мэдээлэ авахад сонгогд багцын мэдээллийг цэвэрлэнэ
    setSelectedPackageId(null);
    DynamicFieldService.getPackages(param)
      .then((response) => {
        if ((response.status = "Success")) {
          // Packages
          setPackages([...response.values]);
          // hervee 1 package bval avtomataar songoj ugnu
          if (response.values.length === 1) {
            setOnePackage(response.values[0]);
          }
        }
      })
      .catch((error) => {
        console.log(error, "packages error");
      })
      .finally(() => setLoader(false));
  };

  const checkExtraService = (e, index) => {
    let extraService = getValues("extra_service");
    extraService[index].isSelected = e.target.checked;
    setValue("extra_service", extraService);

    // nemelt uilchilgee hadgalah
    const service_ids = [];
    extraService
      .filter((x) => x.isSelected)
      ?.forEach((service) => {
        service_ids.push(service.id);
      });
    setValue(`service_ids`, service_ids);
    gettingPrice();
  };

  const checkPackage = (data) => {
    if (data?.insured_value > 0 && !found) {
      // set market_price
      setValue(`market_price`, formatNumber(data?.insured_value, 0));
    }
    // onChange(data);
    setValue(`package`, data);
    setSelectedPackageId(data?.id);
    if (data?.symbol) setCurrency(data.symbol);
    gettingPrice();
  };

  const setOnePackage = (pack) => {
    setValue(
      `market_price`,
      getValues("market_price")
        ? getValues("market_price")
        : formatNumber(pack?.insured_value, 0),
    );
    setSelectedPackageId(pack?.id);
    setValue(`package`, pack);
    gettingPrice();
    if (pack?.symbol) setCurrency(pack.symbol);
  };

  const debouncedSetValue = debounce((e) => {
    gettingPrice();
  }, 500);

  const found = packages.find((obj) => {
    return obj.insured_value === 0;
  });

  return (
    <>
      <Typography variant="Headline" size="sm">
        Даатгалын мэдээлэл
      </Typography>
      <div className="grid gap-4">
        {found && (
          <>
            <Typography variant="Title" size="md" className="mt-10">
              Даатгуулах зүйлийн үнэлгээ
            </Typography>
            <div>
              <Input
                id="market_price_input"
                disabled={id === "x_compulsory_liability,15"}
                width="w-full"
                placeholder="Даатгуулах зүйлийн үнэлгээг бичнэ үү"
                {...register("market_price", {
                  required: true,
                  onChange: (e) => {
                    setValue(`market_price`, formatNumber(e.target.value, 0));
                    debouncedSetValue(e);
                  },
                })}
                className="disabled:opacity-80"
              />
              {errors?.market_price?.type === "required" && (
                <p className="mt-1 pl-2 text-xs text-error">
                  Та даатгалын зүйлийн үнэлгээг оруулна уу.
                </p>
              )}
            </div>
          </>
        )}
      </div>

      <div className="mt-10 space-y-4">
        {stepConfig.x_map && selectedCountry.length < 1 ? (
          <div></div>
        ) : (
          <div>
            <Typography variant="Title" size="md">
              Багц сонгох
            </Typography>
            {loader ? (
              <PulseLoader
                color="#BBC1C9"
                speedMultiplier={0.7}
                loading={loader}
                size={12}
              />
            ) : (
              <div className="flex max-w-[820px] flex-row flex-wrap items-stretch justify-center">
                {packages?.map((data, index) => (
                  <div
                    key={index}
                    className={`${
                      packages.length === 1
                        ? "w-full"
                        : packages.length % 2 === 0
                        ? "basis-1/2"
                        : "basis-1/3"
                    }
                p-1.5
              `}
                  >
                    <div
                      className={
                        `
                group
                col-span-2 flex h-full flex-col rounded-xl border p-4 transition-all hover:border-[#E2A95C]
                hover:shadow-[0px_0px_20px_-1px_#fbd38d] max-[841px]:col-span-3
                max-[500px]:col-span-6 ` +
                        (watch("package")?.id === data.id
                          ? "border-[#E2A95C] shadow-[0px_0px_20px_-1px_#fbd38d]"
                          : "")
                      }
                    >
                      {watch("package")?.id === data.id ? (
                        <h1
                          className={
                            "mb-1 text-base text-[#E2A95C] group-hover:text-[#E2A95C] " +
                            (found ? "border-b pb-2" : null)
                          }
                        >
                          {data.name}
                        </h1>
                      ) : (
                        <h1
                          className={
                            "mb-1 text-base group-hover:text-[#E2A95C] " +
                            (found ? "border-b pb-2" : null)
                          }
                        >
                          {data.name}
                        </h1>
                      )}

                      {!found && (
                        <div className="justify-left mb-3 flex items-center border-b pb-3 text-xl">
                          <span className="text-2xl">
                            {formatNumber(data.insured_value, 0)}{" "}
                            {data?.symbol ? data.symbol : currency}
                          </span>
                        </div>
                      )}
                      <ul className="mt-2 flex-1 space-y-4">
                        <li className="text-sm font-light tracking-[0.25px] text-[#69798C]">
                          Хамгаалах эрсдэлүүд
                        </li>

                        {data.perils?.map((peril, ii) => (
                          <li
                            className="flex text-sm font-light tracking-[0.25px]"
                            key={ii}
                          >
                            <Check className="float-l mt-1 shrink-0" />
                            <div className="flex flex-grow pl-2">
                              {peril.name}
                            </div>
                          </li>
                        ))}
                      </ul>
                      {packages.length !== 1 && (
                        <>
                          {watch("package")?.id === data.id ? (
                            <a
                              onClick={(e) => checkPackage(data)}
                              class="mt-4 rounded bg-[#f9f4ef] px-4 py-3 text-center text-xs text-[#936233] hover:border-transparent hover:bg-[#f9f4ef] hover:text-[#936233]"
                            >
                              <span className="flex justify-center">
                                {watch("package").id === data.id && (
                                  <Check1
                                    fill={"#ff0000"}
                                    className="h-4 w-4 pr-1"
                                  />
                                )}
                                Сонгосон
                              </span>
                            </a>
                          ) : (
                            <a
                              onClick={(e) => checkPackage(data)}
                              className="mt-4 rounded border bg-transparent px-4 py-3 text-center	 align-bottom text-xs hover:cursor-pointer hover:border-transparent hover:bg-[#f9f4ef] hover:text-[#936233] "
                            >
                              <span className="flex justify-center">
                                Сонгох
                              </span>
                            </a>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                ))}
                {errors?.package?.type === "required" && (
                  <p className="mt-1 pl-2 text-xs text-error">
                    Багцаа сонгоно уу
                  </p>
                )}
              </div>
            )}
          </div>
        )}
      </div>
      {/* Covid component */}
      <Covid
        gettingPrice={gettingPrice}
        stepConfig={stepConfig}
        selectedPackageId={selectedPackageId}
        tableNameId={id}
      />
      {watch("extra_service").length > 0 && (
        <div className="mt-10">
          <Typography variant="Title" size="md">
            Нэмэлт үйлчилгээ
          </Typography>
          <Typography size="md" className="mt-1">
            Дараах үйлчилгээнүүдээс өөрт тохирохоо сонгоорой
          </Typography>
          <div className="mt-4 grid w-full grid-cols-2 gap-4">
            {watch("extra_service")?.map((data, index) => (
              <div
                key={index}
                className="flex max-w-[382px] items-center justify-between space-x-4 rounded-xl border p-3.5 max-[500px]:col-span-2"
              >
                <Typography size="md">{data?.name}</Typography>
                <Checkbox
                  label=""
                  id={`extra_service-${index}`}
                  checked={!!data.isSelected} // Ensure the initial value is not undefined
                  onChange={(e) => checkExtraService(e, index)}
                />
              </div>
            ))}
          </div>
          {/* {
            errors?.service_ids?.type === "custom" &&
            <p className="text-error text-xs pl-2 mt-1">{errors.service_ids?.message}</p>
          } */}
        </div>
      )}
    </>
  );
};

export default Bagts;
