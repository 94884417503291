import React from "react";

interface ProductCardSkeletonProps {
  count?: number;
  isloading: boolean;
}

export const ProductCardSkeleton: React.FC<ProductCardSkeletonProps> = ({ count = 1, isloading = false }) => {
  if (!isloading) return null;

  const skeletons = Array.from({ length: count }, (_, index) => (
    <div key={index} className="w-1/4 p-2 max-xl:w-1/3 max-md:w-1/2 max-sm:w-80 min-[1920px]:w-1/5">
      <div className="group w-full cursor-pointer rounded-xl border p-6 shadow-card transition-all bg-gray-75 animate-pulse">
        <div>
          {/* Image skeleton */}
          <div className="mb-4 flex h-14 w-14 items-center justify-center rounded-full border bg-gray-100 animate-pulse">
          </div>
          {/* Title skeleton */}
          <div className="min-h-[50px] bg-gray-100 rounded-full mb-4 animate-pulse"></div>
          {/* Text skeleton */}
          <div className="h-6 bg-gray-100 rounded-full mb-2 animate-pulse"></div>
        </div>
      </div>
    </div>
  ));

  return <>{skeletons}</>;
};
