import { useEffect, useState } from "react";
import { useFormContext, useForm } from "react-hook-form";
import Typography from "../../../../base-components/Typography";
import Button from "../../../../base-components/Button";
import Modal from "../../../../base-components/Modal";
import RadioButton from "../../../../base-components/RadioButton";
import Input from "../../../../base-components/Input";

import { ReactComponent as UserIcon } from "../../../../assets/icon/user-1.svg";
import { ReactComponent as UserCircleIcon } from "../../../../assets/icon/user-circle-1.svg";
import { ReactComponent as Phone } from "../../../../assets/icon/phone-1.svg";
import { ReactComponent as Email } from "../../../../assets/icon/email-1.svg";
import { ReactComponent as Pin } from "../../../../assets/icon/pin-1.svg";

import { formatMobile, formatNumber } from "../../../../utils/helper";

import Payment from "../payment";
import PdfPolicy from "./pdf_policy";

import PaymentService from "../../../../services/PaymentService";
import PolicyInfoService from "../../../../services/PolicyInfoService";
import InvoiceService from "../../../../services/InvoiceService";

const ConfirmInfo = ({ currency }) => {
  const {
    register,
    control,
    formState: { errors },
    setValue,
    watch,
    getValues,
  } = useFormContext();
  const [isOpen, setIsOpen] = useState(false);
  const [invoice, setInvoice] = useState({});
  const [fileUrl, setFileUrl] = useState("");
  const [pdfLoader, setPdfLoader] = useState(false);

  useEffect(() => {
    setPdfLoader(true);
    let config = {
      nonLoading: true,
    };
    PolicyInfoService.getInsurancePolicyFileUrl(getValues("policy_id"), config)
      .then((response) => {
        setFileUrl(response);
        setPdfLoader(false);
      })
      .catch((error) => {
        setPdfLoader(false);
        console.log("file url error", error);
      });
  }, [getValues]);

  const getValue = () => {
    if (getValues("vehicle_info.trailer")) return "Чиргүүлтэй";
    else return "Чиргүүлгүй";
  };

  const getQpayInvoice = () => {
    PaymentService.getQpayInvoice(getValues("policy_id"))
      .then((response) => {
        setInvoice(response);
        setIsOpen(true);
      })
      .catch((error) => {
        console.log(error, "qpay aldaa");
      });
  };

  return (
    <div className="w-[800px] max-[900px]:w-full">
      <Typography variant="Headline" size="sm">
        Баталгаажуулах
      </Typography>
      <Typography variant="Title" size="md" className="mb-4 mt-10">
        Таны хувийн мэдээлэл
      </Typography>
      <div className="mb-10 grid grid-cols-2 rounded-2xl border max-[500px]:grid-cols-1">
        <div className="flex items-center space-x-4 border-b p-4 min-[501px]:border-r">
          <div className="rounded-full bg-surface-gray p-2">
            <UserIcon fill={"#E2A95C"} className="h-5 w-5" />
          </div>
          <Typography>
            {getValues("insurance_partner.lastname")}{" "}
            {getValues("insurance_partner.name")}
          </Typography>
        </div>
        <div className="flex items-center space-x-4 border-b p-4">
          <div className="rounded-full bg-surface-gray p-2">
            <UserCircleIcon fill={"#E2A95C"} className="h-5 w-5" />
          </div>
          <Typography>
            {getValues("insurance_partner.registry_number")}
          </Typography>
        </div>
        <div className="flex items-center space-x-4 border-b p-4 min-[501px]:border-r">
          <div className="rounded-full bg-surface-gray p-2">
            <Phone fill={"#E2A95C"} className="h-5 w-5" />
          </div>
          <Typography>{getValues("insurance_partner.mobile")}</Typography>
        </div>
        <div className="flex items-center space-x-4 border-b p-4">
          <div className="rounded-full bg-primary-soft p-2">
            <Email fill="#E2A95C" className="h-5 w-5" />
          </div>
          <Typography>{getValues("insurance_partner.email")}</Typography>
        </div>
        <div className="flex items-center space-x-4 p-4">
          <div className="rounded-full bg-primary-soft p-2">
            <Pin fill="#E2A95C" className="h-5 w-5" />
          </div>
          <Typography>{getValues("insurance_partner.address")}</Typography>
        </div>
      </div>
      <Typography variant="Title" size="md" className="mt-6">
        Даатгалын хураангуй
      </Typography>
      <div className="mt-4 rounded-xl border border-stroke-dark-12 text-primary">
        <div className="grid grid-cols-3 rounded-t-xl border-b border-stroke-dark-12 bg-surface-gray text-base max-[500px]:grid-cols-1">
          <div className="border-stroke-dark-12 py-4 text-center max-[500px]:border-b min-[501px]:border-r">
            {!getValues("covid_name") && (
              <span>
                {getValues("vehicle_info.make")},{" "}
                {getValues("vehicle_info.model")}
              </span>
            )}
          </div>
          <div className="border-stroke-dark-12 py-4 text-center max-[500px]:border-b min-[501px]:border-r">
            <span>{getValues("vehicle_info.plate_number")}</span>
          </div>
          <div className="border-stroke-dark-12 py-4 text-center">
            {!getValues("covid_name") && <span>{getValue()}</span>}
          </div>
        </div>
        <div className="flex justify-between border-b p-4">
          <Typography>Даатгалын үнэлгээ:</Typography>
          <Typography variant="Title" size="md">
            {formatNumber(getValues("market_price"))}
            {currency}
          </Typography>
        </div>
        <div className="flex justify-between border-b p-4">
          <Typography>Үндсэн хураамж:</Typography>
          <Typography variant="Title" size="md">
            {formatNumber(getValues("base_payment"))}
            {currency}
          </Typography>
        </div>
        {getValues("covid_name") && (
          <div className="flex justify-between border-b p-4">
            <Typography>Ковид багц:</Typography>
            <Typography variant="Title" size="md">
              {getValues("covid_name")}
            </Typography>
          </div>
        )}
        {/* <div className="flex justify-between border-b p-4">
          <Typography>
            Нэмэлт хамгаалалтын хураамж:
          </Typography>
          <Typography variant="Title" size="md">
            {formatNumber(getValues('extra_price)}')₮
          </Typography>
        </div> */}
        <div className="flex justify-between p-4">
          <Typography variant="Title" size="md">
            Нийт хураамж:
          </Typography>
          <Typography variant="Title" size="lg">
            {formatNumber(getValues("amount"))}
            {currency}
          </Typography>
        </div>
      </div>
      <div className="mt-10 w-full rounded-2xl border bg-surface-gray p-10 px-14">
        <PdfPolicy fileUrl={fileUrl} pdfLoader={pdfLoader} />
      </div>
      <div className="flex justify-end">
        <Button className="mt-10" type="button" onClick={getQpayInvoice}>
          Төлбөр төлөх
        </Button>
      </div>
      {/* <div className="mt-10 flex justify-end">
        <Button type="button" onClick={getQpayInvoice}>
          Төлбөр төлөх
        </Button>
      </div> */}
      <Modal
        isOpenModal={isOpen}
        title={"QR Код Уншуулах"}
        onClose={() => setIsOpen(false)}
      >
        {/* <Payment {...{ invoice }} /> */}
        {invoice && (
          <Payment
            {...{
              policyId: getValues("policy_id"),
              amount: invoice.amount,
              qr_image: invoice.qr_image,
              sequence: invoice.invoice_number,
            }}
          />
        )}
      </Modal>
    </div>
  );
};

export default ConfirmInfo;
