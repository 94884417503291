import * as React from "react"
import { ClipLoader } from "react-spinners";
import { ScrollMode, Viewer, Worker } from "@react-pdf-viewer/core"
import { printPlugin } from "@react-pdf-viewer/print"
import { pageNavigationPlugin } from "@react-pdf-viewer/page-navigation"
import { scrollModePlugin } from "@react-pdf-viewer/scroll-mode"
import { zoomPlugin } from "@react-pdf-viewer/zoom"
import { fullScreenPlugin } from "@react-pdf-viewer/full-screen"

import "@react-pdf-viewer/core/lib/styles/index.css"
import "@react-pdf-viewer/print/lib/styles/index.css"
import '@react-pdf-viewer/page-navigation/lib/styles/index.css';
import '@react-pdf-viewer/zoom/lib/styles/index.css';
import '@react-pdf-viewer/full-screen/lib/styles/index.css';

import Typography from "../../../../../base-components/Typography"
import IconButton from "../../../../../base-components/IconButton"
import Empty from "../../../../../base-components/Empty";
import { ReactComponent as PrintIcon } from "../../../../../assets/icon/printer.svg";
import { ReactComponent as ChevronLeft } from "../../../../../assets/icon/chevron-left.svg";
import { ReactComponent as ChevronRight } from "../../../../../assets/icon/chevron-right.svg";
import { ReactComponent as ZoomInIcon } from "../../../../../assets/icon/zoom-in.svg";
import { ReactComponent as ZoomOutIcon } from "../../../../../assets/icon/zoom-out.svg";
import { ReactComponent as FullScreenIcon } from "../../../../../assets/icon/arrows-expand.svg";

const Pdf_policy = ({ fileUrl, pdfLoader }) => {
  const printPluginInstance = printPlugin()
  const pageNavigationPluginInstance = pageNavigationPlugin()
  const scrollModePluginInstance = scrollModePlugin();
  const zoomPluginInstance = zoomPlugin();
  const fullScreenPluginInstance = fullScreenPlugin();

  const { Print } = printPluginInstance
  const {
    GoToNextPage,
    GoToPreviousPage,
    CurrentPageLabel,
  } = pageNavigationPluginInstance
  const { CurrentScale, ZoomIn, ZoomOut } = zoomPluginInstance;
  const { EnterFullScreen } = fullScreenPluginInstance;

  return (
    <div className="rounded-2xl h-full space-y-8">
      {
        pdfLoader ?
          <div className="min-h-[200px] bg-white flex justify-center items-center">
            <ClipLoader
              color="#BBC1C9"
              speedMultiplier={1}
              // loading={pdfLoader}
              size={32}
            />
          </div>
          :
          fileUrl === "" ?
            <Empty value="Гэрээний загвар байхгүй байна" className="py-4" /> :
            <>
              <div className="flex justify-between items-center">
                <div className="flex space-x-2">
                  <Typography variant="Title" size="md">Даатгалын гэрээ</Typography>
                  <CurrentPageLabel>
                    {props => (
                      <Typography variant="Title" size="md">{`(${props.currentPage + 1}/${props.numberOfPages})`}</Typography>
                    )}
                  </CurrentPageLabel>
                </div>
                <div>
                  <div className="flex items-center space-x-4 max-[500px]:hidden">
                    <ZoomOut>
                      {(props) => (
                        <IconButton
                          variant="softPrimary"
                          onClick={props.onClick}
                        >
                          <ZoomOutIcon />
                        </IconButton>
                      )}
                    </ZoomOut>
                    <CurrentScale>
                      {(props) => <Typography>{`${Math.round(props.scale * 100)}%`}</Typography>}
                    </CurrentScale>
                    <ZoomIn>
                      {(props) => (
                        <IconButton
                          variant="softPrimary"
                          onClick={props.onClick}
                        >
                          <ZoomInIcon />
                        </IconButton>
                      )}
                    </ZoomIn>
                  </div>
                </div>
                <div className="flex space-x-2">
                  <div className="max-[500px]:hidden">
                    <EnterFullScreen>
                      {(props) => (
                        <IconButton
                          variant="softPrimary"
                          onClick={props.onClick}
                        >
                          <FullScreenIcon />
                        </IconButton>
                      )}
                    </EnterFullScreen>
                  </div>
                  <Print>
                    {props => (
                      <IconButton
                        variant="softPrimary"
                        onClick={props.onClick}
                      >
                        <PrintIcon />
                      </IconButton>
                    )}
                  </Print>
                  <GoToPreviousPage>
                    {props => (
                      <IconButton
                        as="a"
                        variant="outlinePrimary"
                        disabled={props.isDisabled}
                        onClick={props.onClick}
                      >
                        <ChevronLeft fill={props.isDisabled && "#69798C"} />
                      </IconButton>
                    )}
                  </GoToPreviousPage>
                  <GoToNextPage>
                    {props => (
                      <IconButton
                        as="a"
                        variant="outlinePrimary"
                        disabled={props.isDisabled}
                        onClick={props.onClick}
                      >
                        <ChevronRight fill={props.isDisabled && "#69798C"} />
                      </IconButton>
                    )}
                  </GoToNextPage>
                </div>
              </div>
              <Worker workerUrl={"https://unpkg.com/pdfjs-dist@3.6.172/build/pdf.worker.min.js"}>
                <div className="h-[950px] max-[500px]:h-[400px]">
                  <Viewer
                    fileUrl={fileUrl}
                    plugins={[
                      printPluginInstance,
                      pageNavigationPluginInstance,
                      scrollModePluginInstance,
                      zoomPluginInstance,
                      fullScreenPluginInstance,
                    ]}
                    scrollMode={ScrollMode.Horizontal}
                  />
                </div>
              </Worker>
            </>
      }
    </div>
  )
}

export default Pdf_policy
