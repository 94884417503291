import React, { useState } from "react";
import { ComposableMap, Geographies, Geography } from "react-simple-maps";

// components
import CrossIcon from "../../../../../base-components/Icon/Cross";
import CommandSearch from "../../../../../components/ui/commandSearch";
// features
import features from "../../../../../utils/map/features.json";

const MapInfo = ({
  translatedCountryList,
  setTranslatedCountryList,
  selectedCountry,
  setSelectedCountry,
}) => {
  /**
   * Update isSelected state for a country in the list
   */
  const setCountryIsSelected = (code, isSelected) => {
    setTranslatedCountryList((prevList) =>
      prevList.map((item) =>
        item.value === code ? { ...item, isSelected } : item,
      ),
    );
  };

  /**
   * Toggle country selection
   * @param {String} code - Country code
   */
  const handleClick = (code) => {
    if (selectedCountry.includes(code)) {
      setSelectedCountry((countryList) =>
        countryList.filter((country) => country !== code),
      );
      setCountryIsSelected(code, false);
    } else {
      setSelectedCountry((countryList) => [...countryList, code]);
      setCountryIsSelected(code, true);
    }
  };

  /**
   * Translate country code to its name if available
   * @param {String} code - Country ISO code
   * @returns Translated country name
   */
  const translateCode = (code) => {
    const translatedCountry = translatedCountryList.find(
      (tCountry) => tCountry.value === code,
    );
    return translatedCountry ? translatedCountry.label : code;
  };

  /**
   * Handle selection from CommandSearch component
   * @param {MapObject} selectedValue - Selected country
   */
  const handleCommandClick = (selectedValue) => {
    handleClick(selectedValue.value);
  };

  return (
    <div>
      {/* Search bar */}
      <div className="max-w-xs">
        <CommandSearch
          commands={translatedCountryList}
          title="Аялах улсуудаа хайна уу?"
          onCommandClick={handleCommandClick}
        />
      </div>
      {/* Selected countries list */}
      {selectedCountry.length > 0 && (
        <div className="pt-4 max-w-[800px]">
          {selectedCountry.map((code, index) => (
            <div
              key={index}
              className="flex justify-between rounded-full border border-white bg-surface-black px-4 py-2"
              style={{
                flexBasis: "calc(33.333% - 1rem)",
                display: "inline-flex",
                alignItems: "center",
              }}
            >
              <span className="text-white">
                {index + 1 + ". " + translateCode(code)}
              </span>
              <div
                className="ml-4 cursor-pointer"
                onClick={() => handleClick(code)}
              >
                <CrossIcon className="h-5 w-5" fill={"#FFFFFF"} />
              </div>
            </div>
          ))}
        </div>
      )}
      <ComposableMap>
        <Geographies geography={features}>
          {({ geographies }) =>
            geographies.map((geo) => (
              <Geography
                key={geo.rsmKey}
                geography={geo}
                onClick={() => handleClick(geo.id)}
                style={{
                  default: {
                    fill:
                      selectedCountry.includes(geo.id)
                        ? "#E2A95C"
                        : "gray",
                    outline: "none",
                  },
                  hover: {
                    fill: "#F6E5CE",
                    outline: "none",
                  },
                  pressed: {
                    fill: "#F6E5CE",
                    outline: "none",
                  },
                }}
              />
            ))
          }
        </Geographies>
      </ComposableMap>
    </div>
  );
};

export default MapInfo;
