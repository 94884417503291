import React from "react";

interface FormSkeletonProps {
  isLoading: boolean;
  textCount?: number;
}

export const FormSkeleton: React.FC<FormSkeletonProps> = ({ textCount = 3, isLoading = false }) => {
  if (!isLoading) return null;

  return (
    <div className="col-span-6">
      {/* Text skeletons */}
      {Array.from({ length: textCount }).map((_, index) => (
        <div key={index} className="w-full my-2 h-6 bg-gray-100 rounded-full animate-pulse"></div>
      ))}

      <div className="flex justify-between max-[663px]:block">
        {/* Checkbox skeleton */}
        <div className="w-1/2 h-6 bg-gray-100 rounded-full animate-pulse"></div>

        {/* Button skeleton */}
        <div className="w-1/3 h-10 bg-gray-100 rounded-full animate-pulse"></div>
      </div>
    </div>
  );
};
