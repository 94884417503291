import Service from "./BaseService";

// create=true Hereglegch uusgeh gej baigaa gesen ug
const getOtpBySms = (mobile, config) =>
  Service.get(
    `/dynamicInsurance/get-otp-by-sms/?mobile=${mobile}&create=true`,
    {
      ...config,
      nonLoading: true,
    }
  );

const checkOtp = (payload, config) =>
  Service.post(`/dynamicInsurance/check-otp`, payload, {
    ...config,
    nonLoading: true,
  });

const createWebUser = (payload, config) =>
  Service.post(`/dynamicInsurance/sa-web-user`, payload, {
    ...config,
    nonLoading: true,
  });

const RegisterService = {
  getOtpBySms,
  checkOtp,
  createWebUser,
};

export default RegisterService;
