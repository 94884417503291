import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormContext } from "react-hook-form";
import { PulseLoader } from "react-spinners";
import { twMerge } from "tailwind-merge";

import Button from "../../../../base-components/Button";
import MuiInput from "../../../../base-components/MuiInput";
import Typography from "../../../../base-components/Typography";

import RegisterService from "../../../../services/RegisterService";

const Mobile = ({ mobile, setMobile, step, setStep }) => {
  const navigate = useNavigate();
  const {
    register,
    formState: { errors },
    setError,
  } = useFormContext();
  const [loading, setLoading] = useState(false);

  const getOtpBySms = (e) => {
    e.preventDefault();
    setLoading(true);
    RegisterService.getOtpBySms(mobile)
      .then((response) => {
        if (response.status === "success") {
          setStep(step + 1);
        }
        if (response.status === "error")
          setError("mobile", { type: "custom", message: response.message });
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error, "error");
      });
  };

  return (
    <>
      <Typography variant="Headline" size="md">
        Бүртгүүлэх
      </Typography>
      <div className="mt-8">
        <MuiInput
          id="sign-in-mobile"
          label="Утасны дугаар"
          {...register("mobile", {
            required: true,
            maxLength: 8,
            pattern: /^[7-9]{1}?[0-9]{7}$/,
            onChange: (e) => setMobile(e.target.value),
          })}
          className="w-full"
        />
        {errors?.mobile?.type === "required" && (
          <p className="mt-1 pl-2 text-xs italic text-error">
            Утасны дугаараа оруулна уу!
          </p>
        )}
        {errors?.mobile?.type === "maxLength" && (
          <p className="mt-1 pl-2 text-xs italic text-error">
            Утасны дугаар авах утгын урт хэтэрсэн байна!
          </p>
        )}
        {errors?.mobile?.type === "pattern" && (
          <p className="mt-1 pl-2 text-xs italic text-error">
            Утасны дугаар буруу байна. Шалгана уу!
          </p>
        )}
        {errors?.mobile?.type === "custom" && (
          <p className="mt-1 pl-2 text-xs italic text-error">
            {errors?.mobile?.message}
            {errors?.mobile?.message === "Хэрэглэгч бүртгэлтэй байна" && (
              <span>
                . Нууц үгээ мартсан бол{" "}
                <span
                  className="cursor-pointer hover:font-bold hover:underline"
                  onClick={() =>
                    navigate("/recover-password", {
                      state: { prevPath: window.location.pathname },
                    })
                  }
                >
                  энд
                </span>{" "}
                дарж сэргээнэ үү.
              </span>
            )}
          </p>
        )}
      </div>
      <div
        className={twMerge(["mt-2 hidden justify-center", loading && "flex"])}
      >
        <PulseLoader
          color="#BBC1C9"
          speedMultiplier={0.7}
          loading={loading}
          size={12}
        />
      </div>
      <div className="mt-6 flex items-center justify-end">
        <Button onClick={(e) => getOtpBySms(e)} disabled={mobile === ""}>
          Үргэлжлүүлэх
        </Button>
      </div>
      <div className="mt-6 flex items-center">
        <div className="w-full border-t"></div>
        <div className="m-2 w-full min-w-[112px] text-sm text-on-surface-black-64">
          Та зуучлагч уу?
        </div>
        <div className="w-full border-t"></div>
      </div>
      <Button
        variant="outlinePrimary"
        onClick={(e) => {
          navigate("/broker/login");
        }}
        className="mt-6 w-full"
      >
        Зуучлагчаар нэвтрэх
      </Button>
    </>
  );
};

export default Mobile;
