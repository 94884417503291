import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { PulseLoader } from "react-spinners";
import { twMerge } from "tailwind-merge";

import Button from "../../../base-components/Button";
import MuiInput from "../../../base-components/MuiInput";
import Typography from "../../../base-components/Typography";

import AuthService from "../../../services/AuthService";

const Mobile = ({ step, setStep }) => {
  const {
    register,
    formState: { errors },
    setError,
    watch,
    getValues,
  } = useFormContext();
  const [loading, setLoading] = useState(false);

  const getOtpBySms = (e) => {
    e.preventDefault();
    setLoading(true);
    AuthService.getOtpBySms(getValues("mobile"))
      .then((response) => {
        if (response.status === "success") {
          setStep(step + 1);
        }
        if (response.status === "error")
          setError("mobile", { type: "custom", message: response.message });
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error, "error");
      });
  };

  return (
    <>
      <Typography className="text-on-surface-black-64">
        Та бүртгэлтэй утасны дугаараа оруулна уу.
      </Typography>
      <div className="mt-8">
        <MuiInput
          id="sign-in-mobile"
          label="Утасны дугаар"
          {...register("mobile", {
            required: true,
            maxLength: 8,
            pattern: /^[7-9]{1}?[0-9]{7}$/,
          })}
          className="w-full"
        />
        {errors?.mobile?.type === "required" && (
          <p className="text-error text-xs italic pl-2 mt-1">
            Утасны дугаараа оруулна уу!
          </p>
        )}
        {errors?.mobile?.type === "maxLength" && (
          <p className="text-error text-xs italic pl-2 mt-1">
            Утасны дугаар авах утгын урт хэтэрсэн байна!
          </p>
        )}
        {errors?.mobile?.type === "pattern" && (
          <p className="text-error text-xs italic pl-2 mt-1">
            Утасны дугаар буруу байна. Шалгана уу!
          </p>
        )}
        {errors?.mobile?.type === "custom" && (
          <p className="text-error text-xs italic pl-2 mt-1">
            {errors?.mobile?.message}
          </p>
        )}
      </div>
      <div
        className={twMerge(["justify-center mt-2 hidden", loading && "flex"])}
      >
        <PulseLoader
          color="#BBC1C9"
          speedMultiplier={0.7}
          loading={loading}
          size={12}
        />
      </div>
      <div className="mt-6 flex justify-end items-center">
        <Button
          onClick={(e) => getOtpBySms(e)}
          disabled={watch("mobile") === ""}
        >
          Үргэлжлүүлэх
        </Button>
      </div>
    </>
  );
};

export default Mobile;
