import { Fragment, useEffect, useState } from 'react'
import { Transition, Combobox } from '@headlessui/react'
import { BeatLoader } from "react-spinners";
import { twMerge } from 'tailwind-merge';
import PropTypes from "prop-types";
import InfiniteScroll from 'react-infinite-scroll-component';

import { ReactComponent as ChevronDown } from '../../assets/icon/chevron-down.svg';
import { isEmptyObject, isNullOrUndefined } from '../../utils/helper';

import Empty from '../Empty';

function Select(props) {
  const {
    label,
    id,
    value,
    disabled,
    className,
    optionClassName,
    onChange,
    options = [],
    placeholder = "",
    relations,
    relationName,
    getValues,
    service,
    params,
    icon = true,
  } = props;
  const [isShow, setIsShow] = useState(false);
  const [selected, setSelected] = useState();
  const [searchedValue, setSearchedValue] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [items, setItems] = useState([]);
  const [page, setPage] = useState(params?.page || 1)
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    if ((typeof value === "string" || typeof value === "number") && !!value) {
      let utga = { name: value }
      handleOnChange({ ...utga })
    }
    else if (typeof value === "object") {
      if (!isEmptyObject(value))
        handleOnChange(value)
    }
  }, [])

  /* SERVICE */

  useEffect(() => {
    setPage(1);
    if (!isShow) return;
    if (service) {
      if (relations?.length > 0) {
        setSelected({ name: null });
        onChange({ name: null });
        getFetchData(searchedValue, true);
        return;
      }
      getFetchData(searchedValue);
    }
  }, [isShow, searchedValue])

  useEffect(() => {
    if (options.length > 0) {
      setItems([...options]);
      handleOnChange(options[0]);
    }
  }, [options])

  const getFetchData = (search_value, value) => {
    const more = value || hasMore;
    const isSearch = value || isSearching
    if (!more) return;
    if (!isSearch)
      setPage(prevPage => prevPage + 1);
    if (relations && relations.length > 0) {
      relations.forEach((data) => {
        if (typeof params === "object"){
          if (data.relation === "res.country")
            params.filter[data.name] = 146
          else
            params.filter[data.name] = getValues(`${relationName}.${data.name}`)?.id;
        }
      })
    }
    if (typeof params === "object") {
      params.page = page
      params.search_value = search_value || ""
    }
    let config = {
      nonLoading: true,
    }
    service(params, config)
      .then((response) => {
        if (response.status === "Success") {
          if (response.values.length === 0 || typeof params !== "object") {
            setItems(response.values)
            setHasMore(false)
          }
          else {
            if (isSearch)
              setItems(response.values)
            else
              setItems(items.concat(response.values));
          }
          if (response.total_pages === page)
            setHasMore(false);
        }
      })
      .catch((error) => {
        console.log("select service error", error);
      })
  }
  const handleKeyDown = (e) => {
    switch (e.code) {
      case "Space":
        e.preventDefault();
        setIsShow(prev => !prev);
        break;
      case "Escape":
        setIsShow(false);
        break;
      default:
        break;
    }
  }
  const searchValue = (e) => {
    setHasMore(true)
    setSearchedValue(e.target.value);
    setIsSearching(true);
    if (!isShow)
      setIsShow(true);
  }
  const handleOnChange = (value) => {
    setSelected(value);
    onChange(value);
    // setIsShow(false);
  }

  return (
    <Combobox value={selected} disabled={disabled} onChange={handleOnChange} className='relative cursor-pointer'>
      <div className='relative cursor-pointer'>
        <Combobox.Label
          className={twMerge([
            'absolute top-0 left-0 z-10 translate-x-4 translate-y-4 text-sm text-on-surface-black-64 transition-all duration-300 pointer-events-none',
            (!isNullOrUndefined(selected?.name) || searchedValue !== "") && 'translate-y-1 text-xs',
            selected?.name === false && 'translate-y-4 text-sm',
          ])}
        >
          {label}
        </Combobox.Label>
        <Combobox.Button
          onClick={() => setIsShow(prev => !prev)}
          onBlur={() => setIsShow(false)}
          className={twMerge([
            'relative transition duration-200 ease-in-out w-full text-sm text-primary border-stroke-dark-12 border shadow-sm rounded-lg outline-none bg-white',
            isShow && 'border-on-surface-black-64',
          ])}>
          <div className='flex justify-between items-center'>
            <Combobox.Input
              id={id}
              onChange={searchValue}
              onKeyDown={handleKeyDown}
              displayValue={(item) => item.name}
              placeholder={placeholder}
              className={twMerge([
                "grow block truncate outline-none w-full p-4 rounded-lg font-medium tracking-wide",
                "disabled:opacity-30",
                className,
              ])}
              autoComplete='off'
            />
            {
              icon &&
              <ChevronDown
                className={twMerge(["h-5 w-5 text-gray-400 absolute right-4 pointer-events-none", isShow && "rotate-180"])}
                aria-hidden="true"
              />
            }
          </div>
        </Combobox.Button>
        <Transition
          as={Fragment}
          show={isShow}
          enter="transition ease-out duration-200"
          enterFrom="opacity-0 translate-y-1"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-in duration-150"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 translate-y-1"
        >
          <Combobox.Options
            className={
              twMerge([
                'absolute bg-white w-full left-0 mt-2 z-50 rounded-lg p-2 shadow-card animation-all', //border border-on-surface-black-64 
                // className,
              ])}>
            <InfiniteScroll
              dataLength={items.length}
              next={getFetchData}
              hasMore={hasMore}
              loader={
                <div className='p-8 flex justify-center'>
                  <BeatLoader color="#C0CBD8" size={10} />
                </div>
              }
              height={224}
            // endMessage={
            //   <p style={{ textAlign: "center" }}>
            //     <b>Yay! You have seen it all</b>
            //   </p>
            // }
            >
              {
                items.length > 0 &&
                items?.map((option, index) =>
                  <Combobox.Option
                    id={`option-id-${index}`}
                    key={index}
                    value={option}
                    className={({ selected, active }) =>
                      twMerge([
                        'p-2.5 flex items-center justify-between text-on-surface-black-64 rounded-lg',
                        active && "text-primary bg-surface-gray", // HOVER,
                        selected && 'bg-third-soft',
                        optionClassName,
                      ])}
                  >
                    {option.name}
                  </Combobox.Option>
                )
              }
            </InfiniteScroll>
            {
              !hasMore && items.length === 0 &&
              <Empty className="text-sm p-8" />
            }
          </Combobox.Options>
        </Transition>
      </div>
    </Combobox>
  )
}

Select.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string,
  value: PropTypes.any,
  options: PropTypes.array,
  placeholder: PropTypes.string,
  service: PropTypes.func,
  optionClassName: PropTypes.string,
}

export default Select;