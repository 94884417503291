import React, { useMemo } from "react";
import { useTable, usePagination, useSortBy } from "react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortUp, faSortDown } from "@fortawesome/free-solid-svg-icons";
import { utils, writeFile } from "xlsx";
import Pagination from "../Pagination";
import Empty from "../Empty";
import DTableSekeletonLoader from "../../components/Skeleton/DTableSkeleton";
import { IButton } from "../../components/IButton";
import Typography from "../../base-components/Typography";

const DataTable = ({
  columns,
  data,
  loading = false,
  rowsPerPage,
  totalPage,
  currentPage,
  onPageChange,
  onRowsPerPageChange,
  customFooter,
  hasDownload,
  excelPageTitle,
  excelFileName,
  title,
}) => {
  const defaultColumn = useMemo(
    () => ({
      minWidth: 50,
      width: 150,
      maxWidth: 400,
    }),
    [],
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: { pageIndex: currentPage - 1, pageSize: rowsPerPage },
      manualPagination: true,
      pageCount: totalPage,
    },
    useSortBy,
    usePagination,
  );

  const handleDownloadExcel = () => {
    const tableTitle = title || "Агула тайлан";
    const exportData = data.map((row, index) => {
      const rowObj = {};
      columns.forEach((col) => {
        let cellData;
        if (col.id === "index") {
          cellData = index + 1;
        } else {
          cellData = row[col.accessor];
        }
        if (cellData === false) {
          cellData = "";
        }
        rowObj[col.Header] = cellData;
      });
      return rowObj;
    });

    const worksheet = utils.json_to_sheet([]);
    utils.sheet_add_aoa(worksheet, [[tableTitle]], { origin: "A1" });
    utils.sheet_add_aoa(worksheet, [columns.map((col) => col.Header)], {
      origin: "A2",
    });
    utils.sheet_add_json(worksheet, exportData, {
      origin: "A3",
      skipHeader: true,
    });

    const columnWidths = columns.map((col) => {
      const maxWidth = Math.max(
        col.Header.length,
        ...data.map((row) => String(row[col.accessor] || "").length),
      );
      return { wch: maxWidth + 2 };
    });

    worksheet["!cols"] = columnWidths;
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, excelPageTitle || "Agula");
    writeFile(workbook, `${excelFileName}.xlsx` || "agula.xlsx");
  };

  return (
    <div>
      <div className="mb-4 flex items-center justify-between">
        {title && (
          <Typography variant="Title" size="md">
            {title}
          </Typography>
        )}
        {hasDownload && (
          <IButton
            onClick={handleDownloadExcel}
            variant="softPrimary"
            size="sm"
          >
            Excel татах
          </IButton>
        )}
      </div>
      <div className="relative h-[calc(100vh-320px)] overflow-x-auto rounded-lg border border-gray-300">
        <table {...getTableProps()} className="w-full">
          <thead className="bg-gray-100">
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()} role="row">
                {headerGroup.headers.map((column, index) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className={`sticky top-0 z-10 cursor-pointer border-b border-gray-300 bg-gray-100 py-2 text-left font-normal ${
                      index === 0 ? "pl-2" : ""
                    }`}
                    role="columnheader"
                  >
                    <div className="flex items-center justify-between">
                      <span>{column.render("Header")}</span>
                      <span className="ml-2 inline-block w-5 text-center">
                        {column.isSorted &&
                          (column.isSortedDesc ? (
                            <FontAwesomeIcon icon={faSortDown} />
                          ) : (
                            <FontAwesomeIcon icon={faSortUp} />
                          ))}
                      </span>
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody
            {...getTableBodyProps()}
            className="min-h-[50px] overflow-y-auto text-sm"
          >
            {loading ? (
              <DTableSekeletonLoader columns={columns} rows={15} />
            ) : data.length === 0 ? (
              <tr>
                <td colSpan={columns.length} className="p-4">
                  <div className="flex min-h-[400px] items-center justify-center">
                    <Empty className="mt-6" />
                  </div>
                </td>
              </tr>
            ) : (
              page.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => (
                      <td
                        {...cell.getCellProps()}
                        className="min-h-[50px] border-t border-gray-300 py-2 align-middle"
                      >
                        {cell.render("Cell")}
                      </td>
                    ))}
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
      </div>
      <div
        className={`mt-4 flex ${
          !loading ? (customFooter ? "justify-between" : "justify-end") : ""
        }`}
      >
        {customFooter && data.length > 0 && !loading && (
          <div className="flex items-center">{customFooter}</div>
        )}
        {!loading && (
          <Pagination
            current={pageIndex + 1}
            totalPage={totalPage}
            rowsPerPage={rowsPerPage}
            simple={true}
            onClickPrevPage={() => onPageChange(Math.max(pageIndex, 1))}
            onClickNextPage={() =>
              onPageChange(Math.min(pageIndex + 2, totalPage))
            }
            onClickRowsPerPage={onRowsPerPageChange}
          />
        )}
      </div>
    </div>
  );
};

export default DataTable;
