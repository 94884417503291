import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { PulseLoader } from "react-spinners";
import Typography from "../../../../base-components/Typography";
import MuiInput from "../../../../base-components/MuiInput"
import Button from "../../../../base-components/Button";
import { twMerge } from "tailwind-merge";

const Password = ({ loading }) => {
  const { register, formState: { errors } } = useFormContext();

  return (
    <>
      <Typography variant="Headline" size="md" className="mb-4">
        Нууц үг
      </Typography>
      <Typography type="softPrimary">
        Та нэвтрэх шинэ нууц үгээ мартахгүй болон аюулгүй байдлыг нь бодоод үүсгээрэй.
      </Typography>
      <div className="space-y-4 my-8">
        <div>
          <MuiInput
            id="sign-in-pass"
            label="Нууц үг"
            type="password"
            {...register('password', {
              required: true,
              maxLength: 50,
            })}
            className="w-full"
          />
          {
            errors?.password?.type === "required" &&
            <p className="text-error text-xs italic pl-2 mt-1">Нууц үгээ оруулна уу!</p>
          }
        </div>
        <div>
          <MuiInput
            id="sign-in-pass-repeat"
            label="Нууц үг давтах"
            type="password"
            {...register('re_password', {
              required: true,
              maxLength: 50,
            })}
            className="w-full"
          />
          {
            errors?.re_password?.type === "required" &&
            <p className="text-error text-xs italic pl-2 mt-1">Нууц үгээ оруулна уу!</p>
          }
        </div>
        <div className={twMerge(["justify-center hidden", loading && "flex"])}>
          <PulseLoader
            color="#BBC1C9"
            speedMultiplier={0.7}
            loading={loading}
            size={12}
          />
        </div>
      </div>
    </>
  )
}

export default Password