import { forwardRef } from 'react';
import MuiAccordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { ReactComponent as ChevronDown } from "../../assets/icon/chevron-down-1.svg";

const Accordion = forwardRef(({ sx, ...props }, ref) => {
  return (
    <div>
      <MuiAccordion
        ref={ref}
        {...props}
        sx={{
          boxShadow: "none",
          // padding: '8px 0',
          border: '1px solid #D9E0E8',
          borderRadius: '12px !important',
          '& .MuiButtonBase-root': {
            px: 3,
            py: 2,
          },
          '& .Mui-expanded': {
            minHeight: 'unset',
          }

        }}
      >
        {props.children}
      </MuiAccordion>
    </div>
  )
})

Accordion.Header = ({ className, expandIcon, sx, ...props }) => {
  return (
    <AccordionSummary
      {...props}
      sx={{
        '& .MuiAccordionSummary-content': {
          margin: 0,
        },
        '& .MuiAccordionSummary-content.Mui-expanded': {
          margin: 0,
        },
      }}
      expandIcon={<ChevronDown />}
    >
      {props.children}
    </AccordionSummary>
  )
}

Accordion.Details = ({ sx, ...props }) => {
  return (
    <AccordionDetails
      {...props}
      sx={{
        px: 3,
        py: 2,
        borderTop: '1px solid rgb(217, 224, 232)'
      }}
    >
      {props.children}
    </AccordionDetails>
  )
}

export default Accordion;