import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Steps from "../../../base-components/Steps";
import StepOne from "./steps/StepOne";
import StepThree from "./steps/StepThree";
import StepTwo from "./steps/StepTwo";
import Button from "../../../base-components/Button";
import { ReactComponent as Cross } from "../../../assets/icon/cross.svg";
import IconButton from "../../../base-components/IconButton";

const steps = [
  { title: "Нэхэмжлэл", },
  { title: "Нэхэмжлэлийн мэдээлэл", },
  { title: "Баталгаажуулах", },
]

function Invoice() {
  const [currentStep, setCurrentStep] = useState(1);
  const [invoiceInfo, setInvoiceInfo] = useState({});
  const navigate = useNavigate();

  return (
    <div>
      <div className="relative bg-surface-gray p-8 flex justify-between items-center border-bottom border-stroke-dark-12 max-[780px]:grid max-[780px]:justify-center">
        <div className="mx-6 max-[780px]:flex items-center justify-between max-[780px]:mb-8">
          <img src="/logos/horizontal-primary.png" alt="logo" className="w-40" onClick={() => navigate("/broker")} />
          <IconButton
            size="sm"
            variant="outlinePrimary"
            onClick={() => navigate("/broker")}
            className="max-[780px]:flex hidden"
          >
            <Cross fill="#0A213A" />
          </IconButton>
        </div>
        <div className="max-[850px]:overflow-auto scrollbar-hide">
          <Steps
            current={currentStep}
            setCurrent={setCurrentStep}
            items={steps}
          />
        </div>
        <div className="w-[160px] flex justify-end">
          <Button
            size="sm"
            variant="outlinePrimary"
            className="text-error max-[780px]:hidden"
            onClick={() => navigate("/broker")}
            icon={<Cross />}
          >
            Цуцлах
          </Button>
        </div>
      </div>
      <div className="flex justify-center items-center">
        {
          currentStep === 1 &&
          <StepOne {...{ currentStep, setCurrentStep, setInvoiceInfo }} />
        }
        {
          currentStep === 2 &&
          <StepTwo  {...{ currentStep, setCurrentStep, invoiceInfo }} />
        }
        {
          currentStep === 3 &&
          <StepThree {...{ currentStep, setCurrentStep, invoiceInfo }} />
        }
      </div>
    </div>
  )
}

export default Invoice;