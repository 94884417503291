import { forwardRef } from "react";
import { twMerge } from "tailwind-merge";

const Card = forwardRef(({ className, size = "sm", ...props }, ref) => {
  return (
    <div
      ref={ref}
      className={twMerge([
        "border border-stroke-dark-12 shadow-card p-14 cursor-pointer w-72 text-center",
        size === "sm" && 'rounded-lg',
        size === "md" && 'rounded-xl',
        size === "lg" && 'rounded-2xl',
        className,
      ])}
      {...props}
    >
      {props.children}
    </div>
  )
})

export default Card;