import dayjs from "dayjs";

export const generateDate = (
  month = dayjs().month(),
  year = dayjs().year()
) => {
  const firstDateOfMonth = dayjs().year(year).month(month).startOf("month");
  const lastDateOfMonth = dayjs().year(year).month(month).endOf("month");

  const arrayOfDate = [];

  //create prefix date
  for (let i = 0; i < firstDateOfMonth.day(); i++) {
    arrayOfDate.push({
      date: firstDateOfMonth.day(i),
      currentMonth: false
    });

  }

  // generate current date
  for (let i = firstDateOfMonth.date(); i <= lastDateOfMonth.date(); i++) {
    arrayOfDate.push({
      date: firstDateOfMonth.date(i),
      currentMonth: true,
      today: firstDateOfMonth.date(i).toDate().toDateString() === dayjs().toDate().toDateString()
    })
  }

  const remaining = 42 - arrayOfDate.length

  for (let i = lastDateOfMonth.date() + 1; i <= lastDateOfMonth.date() + remaining; i++) {
    arrayOfDate.push({
      date: lastDateOfMonth.date(i),
      currentMonth: false,
    });

  }

  return arrayOfDate;
};

export const getYears = (
  filterDate = [2020, 2040]
) => {
  const firstYear = dayjs().subtract(75, "year"),
    lastYear = dayjs().add(75, "year");
  const years = [];
  for (let i = firstYear.year(); i < lastYear.year(); i++) {
    years.push({
      year: i,
      currentYear: i === dayjs().year(),
    });
  }
  return years.filter((x) => x.year >= filterDate[0] && x.year < filterDate[1]);
}

export const months = [
  "1 сар",
  "2 сар",
  "3 сар",
  "4 сар",
  "5 сар",
  "6 сар",
  "7 сар",
  "8 сар",
  "9 сар",
  "10 сар",
  "11 сар",
  "12 сар",
]