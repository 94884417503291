import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { PulseLoader } from "react-spinners";
import { twMerge } from "tailwind-merge";
import queryString from "query-string";

import { ReactComponent as ArrowLeft } from "../../../assets/icon/arrow-left.svg";
import { ReactComponent as Filter } from "../../../assets/icon/filter.svg";

import Button from "../../../base-components/Button";
import IconButton from "../../../base-components/IconButton";
import Pagination from "../../../base-components/Pagination";
import Typography from "../../../base-components/Typography";
import Empty from "../../../base-components/Empty";
import Dropdown from "../../../components/Dropdown";

import ProductService from "../../../services/ProductService";
import { useStore } from "../../../stores";

const Insure = () => {
  const [loader, setLoader] = useState(false);
  const [products, setProducts] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [pages, setPages] = useState([]);
  const [show, setShow] = useState(false);
  const setPrevPath = useStore.useSetPrevPath();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    setLoader(true);
    let params = {
      page: pageNumber,
      limit: 8,
    };
    let payload = queryString.stringify(params);
    ProductService.getInsuranceProduct(payload)
      .then((response) => {
        if (response.status === "Success") {
          setProducts([...response.values]);
          setTotalPage(response.total_pages);
          let pages = [];
          for (let i = 0; i < response.total_pages; i++) {
            pages.push(i + 1);
          }
          setPages(pages);
        }
      })
      .catch((error) => {
        console.log(error, "ee buu uzegd");
      })
      .finally(() => setLoader(false));
  }, []);

  const toInsure = (data) => {
    navigate(`/insurance-process/${data.id}`, {
      state: {
        has_service: data.has_service,
      },
    });
    setPrevPath(location.pathname);
  };

  return (
    <div>
      <div className="flex justify-between border-b px-16 py-4 max-[841px]:px-10 max-[500px]:px-4">
        <div className="flex items-center text-primary">
          <IconButton size="sm" variant="softPrimary" className="mr-6">
            <ArrowLeft className="h-5 w-5" />
          </IconButton>
          <Typography variant="Headline" size="md">
            Бүтээгдэхүүнүүд
          </Typography>
        </div>
        <div className="flex items-center space-x-4">
          <div className="flex items-center space-x-4 border-r border-stroke-dark-12 pr-4 max-[1340px]:hidden">
            <FilterField />
          </div>
          <IconButton variant="outlinePrimary" onClick={() => setShow(!show)}>
            <Filter className="h-5 w-5 text-primary" />
          </IconButton>
          <Dropdown show={show} className="mt-7 min-[1341px]:hidden">
            <Dropdown.Panel className="py-4">
              <div className="space-y-2">
                <FilterField />
              </div>
            </Dropdown.Panel>
          </Dropdown>
        </div>
      </div>
      <div
        className={twMerge([
          "flex flex-wrap border-b px-14 py-4 text-primary max-xl:px-8",
          loader && "h-56 items-center justify-center",
        ])}
      >
        <PulseLoader
          color="#BBC1C9"
          speedMultiplier={0.7}
          loading={loader}
          size={12}
        />
        {!loader &&
          (products.length > 0 ? (
            products?.map((data) => (
              <div
                key={data.id}
                className="w-1/4 p-2 max-xl:w-1/3 max-md:w-1/2 max-sm:w-80 min-[1920px]:w-1/5"
              >
                <div className="group cursor-pointer rounded-xl border p-6 shadow-card transition-all">
                  <div>
                    <div className="mb-4 h-14 w-14 rounded-full border group-hover:border-secondary">
                      <img
                        src={data.logo || "/images/illustrations.png"}
                        alt="image"
                        className="h-full w-full rounded-full"
                      />
                    </div>
                    <div className="min-h-[60px] animate-show text-sm text-on-surface-black-64 group-hover:hidden group-hover:animate-hide">
                      {data.name}
                    </div>
                    <h2 className="-mb-1 mt-2 animate-slide-down text-[22px] font-semibold group-hover:animate-slide-up">
                      {data.code}
                    </h2>
                  </div>
                  <div className="-mb-1 mt-6 hidden animate-hide items-center space-x-4 group-hover:flex group-hover:animate-show">
                    <Button className="w-1/2" variant="softPrimary">
                      Танилцуулга
                    </Button>
                    <Button className="w-1/2" onClick={() => toInsure(data)}>
                      Даатгуулах
                    </Button>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="flex w-full justify-center py-10">
              <Empty value="Бүтээгдэхүүн байхгүй байна" />
            </div>
          ))}
      </div>
      <div className="mt-4 flex justify-center">
        <Pagination current={pageNumber} totalPage={totalPage}>
          {pages?.map((page, index) => (
            <Pagination.Link
              key={index}
              active={page === pageNumber}
              onClick={() => setPageNumber(page)}
            >
              {page}
            </Pagination.Link>
          ))}
        </Pagination>
      </div>
      {/* <div>
        <RealSelect />
      </div> */}
    </div>
  );
};

const FilterField = () => {
  return (
    <>
      <div className="rounded-xl bg-secondary px-4 py-2.5 text-sm text-white">
        Бүгд
      </div>
      <div className="rounded-xl border border-secondary px-4 py-2.5 text-sm text-primary">
        Эрүүл мэнд
      </div>
      <div className="rounded-xl border border-secondary px-4 py-2.5 text-sm text-primary">
        Эд хөрөнгө
      </div>
      <div className="rounded-xl border border-secondary px-4 py-2.5 text-sm text-primary">
        Хариуцлага
      </div>
      <div className="rounded-xl border border-secondary px-4 py-2.5 text-sm text-primary">
        Аялал
      </div>
    </>
  );
};

export default Insure;
