import { ReactComponent as PlusCircle } from "../../assets/icon/plus-circle.svg";
import { ReactComponent as Home } from "../../assets/icon/home.svg";
import { ReactComponent as UserPlus } from "../../assets/icon/user-plus.svg";
import { ReactComponent as DollarCircle } from "../../assets/icon/dollar-circle.svg";
import { ReactComponent as Box } from "../../assets/icon/box-2.svg";
import { ReactComponent as Invoice } from "../../assets/icon/invoice.svg";
import { ReactComponent as SelectedPlus } from "../../assets/icon/selected-plus-circle.svg";
import { ReactComponent as SelectedHome } from "../../assets/icon/selected-home.svg";
import { ReactComponent as SelectedUser } from "../../assets/icon/selected-user-plus.svg";
import { ReactComponent as SelectedDollarCircle } from "../../assets/icon/selected-dollar-circle.svg";
import { ReactComponent as SelectedBox } from "../../assets/icon/selected-box-2.svg";
import { ReactComponent as SelectedInvoice } from "../../assets/icon/invoice-1.svg";

export function Icon({ id, isSelected }) {
  switch (id) {
    case "plus":
      if (isSelected) return <SelectedPlus className="h-5 w-5" />;
      else return <PlusCircle stroke="#69798C" className="h-5 w-5" />;
    case "home":
      if (isSelected) return <SelectedHome className="h-5 w-5" />;
      else return <Home stroke="#69798C" className="h-5 w-5" />;
    case "invite-friend":
      if (isSelected) return <SelectedUser className="h-5 w-5" />;
      else return <UserPlus stroke="#69798C" className="h-5 w-5" />;
    case "products":
      if (isSelected) return <SelectedBox className="h-5 w-5" />;
      else return <Box stroke="#69798C" className="h-5 w-5" />;
    case "sales":
      if (isSelected) return <SelectedDollarCircle className="h-5 w-5" />;
      else return <DollarCircle stroke="#69798C" className="h-5 w-5" />;
    case "invoice":
      if (isSelected)
        return <SelectedInvoice fill="#E2A95C" className="h-5 w-5" />;
      else return <Invoice stroke="#69798C" className="h-5 w-5" />;
    case "doshReport":
      if (isSelected)
        return <SelectedDollarCircle fill="#E2A95C" className="h-5 w-5" />;
      else return <DollarCircle stroke="#69798C" className="h-5 w-5" />;
    default:
      break;
  }
}
