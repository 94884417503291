import React from "react";

interface TextSkeletonProps {
  isLoading: boolean;
  textCount?: number;
}

export const TextSkeleton: React.FC<TextSkeletonProps> = ({ textCount = 3, isLoading = false }) => {
  if (!isLoading) return null;

  return (
    <div className="col-span-6">
      {/* Text skeletons */}
      {Array.from({ length: textCount }).map((_, index) => (
        <div key={index} className="w-full my-2 h-6 bg-gray-100 rounded-full animate-pulse"></div>
      ))}
    </div>
  );
};
