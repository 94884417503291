import { useEffect, useState, useMemo } from "react";
import dayjs from "dayjs";
import debounce from "lodash/debounce";
import DataTable from "../../base-components/DataTable";
import IconButton from "../../base-components/IconButton";
import DatePicker from "../../components/DatePicker";
import Typography from "../../base-components/Typography";
import {
  Dialog,
  DialogContent,
  DialogDescription,
} from "../../components/ui/dialog";
import { ReactComponent as Filter } from "../../assets/icon/filter.svg";
import { ReactComponent as Info } from "../../assets/icon/info.svg";
import DoshService from "../../services/DoshService";
import { formatNumber, formatDateSplitTime } from "../../utils/helper";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../components/ui/tabs";
import { IButton } from "../../components/IButton";

const DoshReport = () => {
  const currentDate = dayjs();
  const currentMonthLastDay = dayjs(currentDate).endOf("month");
  const firstDayOf2024 = dayjs("2024-01-01").startOf("day");
  const [doshData, setDoshData] = useState([]);
  const [dialogDoshData, setDialogDoshData] = useState([]);
  const [inceptionDate, setInceptionDate] = useState(firstDayOf2024);
  const [expiryDate, setExpiryDate] = useState(currentMonthLastDay);
  const [loading, setLoading] = useState(false);
  const [dialogVisible, setDialogVisible] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [doshTitle, setDoshTitle] = useState("");
  const [doshPaymentValue, setDoshPaymentValue] = useState(false);
  const [excelFileName, setExcelFileName] = useState("");
  const [excelPageName, setExcelPageName] = useState("");
  const [doshReturnData, setDoshReturnData] = useState([]);

  const [pagination, setPagination] = useState({
    page: 1,
    rowsPerPage: 10,
    totalPage: 1,
  });

  const [dialogPagination, setDialogPagination] = useState({
    page: 1,
    rowsPerPage: 10,
    totalPage: 1,
  });

  const { page, rowsPerPage, totalPage } = pagination;
  const { doshPage, doshRowsPerPage } = dialogPagination;
  const { doshReturnPage, doshReturnRowsPerPage } = dialogPagination;

  useEffect(() => {
    const debouncedGetDosh = debounce(() => getDoshList(rowsPerPage), 300);
    debouncedGetDosh();
  }, [inceptionDate, expiryDate, rowsPerPage, page]);

  const getDoshList = (rowsNumber) => {
    setLoading(true);
    const params = {
      limit: rowsNumber || rowsPerPage,
      page,
      filter: {
        start_date: inceptionDate.format("YYYY-MM-DD 00:00:00"),
        end_date: expiryDate.format("YYYY-MM-DD HH:mm:ss"),
      },
      sort_field: "start_date",
      sort_type: "asc",
    };

    DoshService.getDoshList(params)
      .then((response) => {
        if (response.status === "Success") {
          setDoshData(response?.data);
          setPagination((prev) => ({
            ...prev,
            totalPage: response.total_pages,
          }));
          setDoshPaymentValue(response.value);
        } else {
          console.error(response.message || "Failed to fetch doshData data");
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching doshData data", error);
        setLoading(false);
      });
  };

  const getDoshPaymentDetails = (doshId, rowsNumber) => {
    setLoading(true);
    const params = {
      limit: 10,
      page: doshPage || 1,
      filter: {
        start_date: inceptionDate.format("YYYY-MM-DD 00:00:00"),
        end_date: expiryDate.format("YYYY-MM-DD HH:mm:ss"),
      },
      sort_field: "start_date",
      sort_type: "asc",
      dosh_id: doshId,
    };

    DoshService.getDoshDetails(params)
      .then((response) => {
        if (response.status === "Success") {
          setDialogDoshData(response?.data);
          setDoshReturnData(response?.data_return);
          setDialogPagination((prev) => ({
            ...prev,
            totalPage: response.total_pages,
          }));
          setDoshPaymentValue(response.value);
        } else {
          console.error(response.message || "Failed to fetch doshData data");
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching doshData data", error);
        setLoading(false);
      });
  };

  const handleShowDetails = (rowData) => {
    let doshTitle;

    try {
      const startDate = new Date(rowData.original.start_date);
      const year = startDate.getFullYear();
      const month = startDate.toLocaleDateString("mn-MN", { month: "numeric" });
      doshTitle = `Зуучлалын тайлан ${year} оны ${month}-р сар`;
      setExcelFileName(`zuuchlaliin_tailan${year}_${month}`);
      setExcelPageName(`${year} ${month}`);
    } catch (error) {
      doshTitle = "Зуучлалын тайлан";
      setExcelFileName("zuuchlaliin_tailan");
    }

    setDoshTitle(doshTitle);
    setSelectedRowData(rowData.original);
    getDoshPaymentDetails(rowData.original.id, doshRowsPerPage);
    setDialogVisible(true);
  };

  const columns = useMemo(
    () => [
      {
        Header: "№",
        accessor: (_, index) => (page - 1) * 10 + index + 1,
        id: "index",
        Cell: ({ value }) => <div className="pl-3">{value}</div>,
      },
      {
        Header: "Эхлэх огноо",
        accessor: "start_date",
        Cell: ({ value }) => formatDateSplitTime(value),
      },
      {
        Header: "Дуусах огноо",
        accessor: "end_date",
        Cell: ({ value }) => formatDateSplitTime(value),
      },
      {
        Header: "Нийт үнэлгээ",
        accessor: "market_value",
        Cell: ({ value }) => `${formatNumber(value, 0)} ₮`,
      },
      {
        Header: "Нийт хураамж",
        accessor: "amount_total",
        Cell: ({ value }) => `${formatNumber(value, 0)} ₮`,
      },
      {
        Header: "Гэрээний тоо",
        accessor: "dosh_payment_count",
      },
      {
        Header: "Шимтгэл",
        accessor: "dosh_amount",
        Cell: ({ value }) => `${formatNumber(value, 0)} ₮`,
      },
      {
        Header: "Нэмэлт шимтгэл",
        accessor: "additional_dosh_amount",
        Cell: ({ value }) => `${formatNumber(value, 0)} ₮`,
      },
      {
        Header: "Төлөв",
        accessor: "state",
        Cell: ({ row }) => (
          <span
            className={`${
              row.original.state === "confirmed"
                ? "text-green-500"
                : "text-[#B7281E]"
            }`}
          >
            {row.original.state === "confirmed"
              ? "Баталгаажсан"
              : "Баталгаажаагүй"}
          </span>
        ),
      },
      {
        Header: "",
        id: "details",
        Cell: ({ row }) => (
          <div className="flex items-center justify-center">
            <IButton
              onClick={() => handleShowDetails(row)}
              variant="softPrimary"
              size="sm"
            >
              Дэлгэрэнгүй
            </IButton>
          </div>
        ),
      },
    ],
    [page, handleShowDetails],
  );

  const doshCustomFooter = useMemo(
    () => (
      <div className="hidden justify-between md:flex">
        <Typography variant="Body" size="lg">
          Нийт үнэлгээ:{" "}
          <span className="font-bold">
            {formatNumber(doshPaymentValue?.market_value || 0, 0)} ₮
          </span>
        </Typography>
        <Typography variant="Body" size="lg" className="pl-10">
          Нийт хураамж:{" "}
          <span className="font-bold">
            {formatNumber(doshPaymentValue?.amount_total || 0, 0)} ₮
          </span>
        </Typography>
        <Typography variant="Body" size="lg" className="pl-10">
          Нийт Шимтгэл:{" "}
          <span className="font-bold">
            {formatNumber(doshPaymentValue?.dosh_amount || 0, 0)} ₮
          </span>
        </Typography>
        <Typography variant="Body" size="lg" className="pl-10">
          Зуучлал бодсон гэрээ:{" "}
          <span className="font-bold">
            {doshPaymentValue?.dosh_payment_count || 0}
          </span>
        </Typography>
      </div>
    ),
    [doshPaymentValue],
  );

  const doshColumns = useMemo(
    () => [
      {
        Header: "№",
        accessor: (_, index) => index + 1,
        id: "index",
        Cell: ({ value }) => <div className="pl-3">{value}</div>,
      },
      {
        Header: "Гэрээний дугаар",
        accessor: "warranty_number",
      },
      { Header: "Даатгуулагч", accessor: "partner_name" },
      {
        Header: "Даатгалын хэлбэр",
        accessor: "product_type_name",
      },
      {
        Header: "Төлөгдсөн огноо",
        accessor: "paid_datetime",
        Cell: ({ value }) => formatDateSplitTime(value),
      },
      {
        Header: "Үнэлгээ",
        accessor: "market_value",
        Cell: ({ value }) => `${formatNumber(value, 0)} ₮`,
      },
      {
        Header: "Хураамж",
        accessor: "paid_amount",
        Cell: ({ value }) => `${formatNumber(value, 0)} ₮`,
      },
      {
        Header: "Хувь",
        accessor: "default_dosh_percent",
        Cell: ({ value }) => `${Math.round(value * 100) || 0}%`,
      },
      {
        Header: "Олгох шимтгэл",
        accessor: "dosh_amount",
        Cell: ({ value }) => `${formatNumber(value, 0)} ₮`,
      },
    ],
    [],
  );

  const doshReturnColumns = useMemo(
    () => [
      {
        Header: "№",
        accessor: (_, index) => index + 1,
        id: "index",
        Cell: ({ value }) => <div className="pl-3">{value}</div>,
      },
      {
        Header: "Гэрээний дугаар",
        accessor: "warranty_number",
      },
      { Header: "Даатгуулагч", accessor: "partner_name" },
      {
        Header: "Даатгалын хэлбэр",
        accessor: "product_type_name",
      },
      {
        Header: "Төлөгдсөн огноо",
        accessor: "paid_datetime",
        Cell: ({ value }) => formatDateSplitTime(value),
      },
      {
        Header: "Хувь",
        accessor: "dosh_percent",
        Cell: ({ value }) => `${Math.round(value * 100) || 0}%`,
      },
      {
        Header: "Буцаалтын дүн",
        accessor: "dosh_return_amount",
        Cell: ({ value }) => `${formatNumber(value, 0)} ₮`,
      },
      {
        Header: "Нэмэлт буцаалт",
        accessor: "add_dosh_return_amount",
        Cell: ({ value }) => `${formatNumber(value, 0)} ₮`,
      },
      {
        Header: "Нийт буцаалт",
        accessor: "total_return_dosh_amount",
        Cell: ({ value }) => `${formatNumber(value, 0)} ₮`,
      },
    ],
    [],
  );

  return (
    <div className="bg-white">
      <div className="flex flex-col justify-between border-b border-gray-300 px-4 py-4 md:flex-row md:px-16">
        <Typography variant="Headline" size="md">
          Зуучлалын тайлан
        </Typography>
        <div className="mt-4 flex flex-col items-center gap-3 md:mt-0 md:flex-row">
          <DatePicker
            date={inceptionDate}
            onChangeDate={setInceptionDate}
            className="rounded-xl text-base"
            placement="right"
            mode="monthYear"
          />
          <span className="hidden w-[4px] border-l border-r border-black md:block"></span>
          <DatePicker
            date={expiryDate}
            onChangeDate={setExpiryDate}
            className="rounded-xl text-base"
            placement="right"
            mode="monthYear"
            isMonthLastDay={true}
          />
          <div className="border-l border-gray-300 pl-3">
            <IconButton
              variant="outlinePrimary"
              size="lg"
              onClick={debounce(() => getDoshList(rowsPerPage), 300)}
            >
              <Filter className="h-5 w-5 text-primary" />
            </IconButton>
          </div>
        </div>
      </div>
      <div className="px-8 py-4 text-primary max-[841px]:px-8 max-[500px]:px-4">
        <div className="overflow-x-auto px-2 py-4">
          <Dialog
            open={dialogVisible}
            onOpenChange={setDialogVisible}
            className="px-8 py-4 text-primary max-[841px]:px-8 max-[500px]:px-4"
          >
            <DataTable
              columns={columns}
              data={doshData}
              loading={loading}
              rowsPerPage={rowsPerPage}
              totalPage={totalPage}
              currentPage={page}
              onPageChange={(newPage) =>
                setPagination((prev) => ({
                  ...prev,
                  page: Math.min(Math.max(newPage, 1), totalPage),
                }))
              }
              onRowsPerPageChange={(rowsNumber) => {
                setPagination((prev) => ({
                  ...prev,
                  rowsPerPage: rowsNumber,
                  page: 1,
                }));
                debounce(() => getDoshList(rowsNumber), 300)();
              }}
            />
            <DialogContent>
              <DialogDescription className="overflow-x-auto px-2 py-4">
                <Tabs defaultValue="Dosh" className="">
                  <TabsList>
                    <TabsTrigger value="Dosh">Зуучлалын тайлан</TabsTrigger>
                    <TabsTrigger value="doshReturn">
                      Зуучлалын тайлан буцаалт
                    </TabsTrigger>
                  </TabsList>
                  <TabsContent value="Dosh">
                    {selectedRowData && (
                      <DataTable
                        columns={doshColumns}
                        data={dialogDoshData}
                        customFooter={doshCustomFooter}
                        loading={loading}
                        rowsPerPage={doshRowsPerPage}
                        title={doshTitle}
                        totalPage={1}
                        hasDownload={true}
                        currentPage={doshPage}
                        excelPageTitle={excelPageName}
                        excelFileName={excelFileName}
                        onPageChange={(newPage) =>
                          setDialogPagination((prev) => ({
                            ...prev,
                            page: Math.min(Math.max(newPage, 1), 1),
                          }))
                        }
                        onRowsPerPageChange={(rowsNumber) => {
                          setDialogPagination((prev) => ({
                            ...prev,
                            rowsPerPage: rowsNumber,
                            page: 1,
                          }));
                          getDoshPaymentDetails(selectedRowData.id, rowsNumber);
                        }}
                      />
                    )}
                  </TabsContent>
                  <TabsContent value="doshReturn">
                    {selectedRowData && (
                      <DataTable
                        columns={doshReturnColumns}
                        data={doshReturnData}
                        loading={loading}
                        rowsPerPage={doshReturnRowsPerPage}
                        title={`${doshTitle} буцаалт`}
                        totalPage={1}
                        hasDownload={true}
                        currentPage={doshReturnPage}
                        onPageChange={(newPage) =>
                          setDialogPagination((prev) => ({
                            ...prev,
                            page: Math.min(Math.max(newPage, 1), 1),
                          }))
                        }
                        onRowsPerPageChange={(rowsNumber) => {
                          setDialogPagination((prev) => ({
                            ...prev,
                            rowsPerPage: rowsNumber,
                            page: 1,
                          }));
                          getDoshPaymentDetails(selectedRowData.id, rowsNumber);
                        }}
                      />
                    )}
                  </TabsContent>
                </Tabs>
              </DialogDescription>
            </DialogContent>
          </Dialog>
        </div>
      </div>
    </div>
  );
};

export default DoshReport;
