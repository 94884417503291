import * as React from "react";
import { Command, CommandInput, CommandItem, CommandList } from "./command";

const CommandSearch = ({ title, commands, onCommandClick }) => {
  const [open, setOpen] = React.useState(false);
  const [inputValue, setInputValue] = React.useState("");
  const [selectedIndex, setSelectedIndex] = React.useState(-1);

  /**
   * Input утга өөрчлөлтийг сонсох function
   * @param value input value
   */
  const handleValueChange = (value) => {
    setInputValue(value);
    setOpen(!!value);
    setSelectedIndex(-1); // Reset selection when input changes
  };

  /**
   * Эцэг component луу дарагдсан commanditem утгыг илгээх
   * @param value сонгогдсон утга
   */
  const handleCommandClick = (selectedValue) => {
    const updatedCommands = commands.map((command) =>
      command.value === selectedValue.value
        ? { ...command, isSelected: true }
        : { ...command, isSelected: false },
    );
    onCommandClick(selectedValue);
    clearInputValue();
  };

  /**
   * input-ийн утгыг цэвэрлэж commandItem хаах
   */
  const clearInputValue = () => {
    setInputValue("");
    setOpen(false);
    setSelectedIndex(-1);
  };

  /**
   * input утгаар value хайх function
   */
  const filteredCommands = Array.isArray(commands)
    ? commands.filter((command) =>
        command.label.toLowerCase().includes(inputValue.toLowerCase()),
      )
    : [];

  /**
   * Keyboard товч дарахад хайлтын утгыг сонгох function
   * @param event keydown event
   */
  const handleKeyDown = (event) => {
    if (event.key === "Enter" && selectedIndex >= 0) {
      handleCommandClick(filteredCommands[selectedIndex]);
    } else if (event.key === "ArrowDown") {
      setSelectedIndex((prevIndex) =>
        prevIndex < filteredCommands.length - 1 ? prevIndex + 1 : prevIndex
      );
    } else if (event.key === "ArrowUp") {
      setSelectedIndex((prevIndex) =>
        prevIndex > 0 ? prevIndex - 1 : prevIndex
      );
    }
  };

  return (
    <div className="relative">
      <Command className="rounded-full border shadow-md">
        <CommandInput
          placeholder={title ? title : "Хайх"}
          onValueChange={handleValueChange}
          value={inputValue}
          onKeyDown={handleKeyDown}
        />
        <CommandList className="absolute mt-11 w-full rounded-lg border bg-white shadow-md">
          {open &&
            filteredCommands.length > 0 &&
            filteredCommands.map((command, index) => (
              <CommandItem
                key={command.value}
                value={command.value}
                onClick={() => handleCommandClick(command)}
                className={`${
                  index === selectedIndex ? "bg-gray-200 text-black" : "bg-gray-0 text-black"
                } cursor-pointer rounded-none px-4 py-2`}
              >
                {command.label}
              </CommandItem>
            ))}
        </CommandList>
      </Command>
    </div>
  );
};

export default CommandSearch;
