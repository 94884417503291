import * as React from "react";

const CrossIcon = (props) => {
    const { size, fill } = props;

    return (
        <div style={{ width: size, height: size }}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="100%"
                height="100%"
                fill={fill ? fill : "#0A213A"}
                viewBox="0 0 19 18"
                {...props}
            >
                <path
                    fillRule="evenodd"
                    d="M4.646 4.146a.5.5 0 0 1 .708 0L9.5 8.293l4.146-4.147a.5.5 0 0 1 .708.708L10.207 9l4.147 4.146a.5.5 0 0 1-.708.708L9.5 9.707l-4.146 4.147a.5.5 0 0 1-.708-.708L8.793 9 4.646 4.854a.5.5 0 0 1 0-.708Z"
                    clipRule="evenodd"
                />
            </svg>
        </div>
    );
};

export default CrossIcon;
